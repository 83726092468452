export default {
  'th': {
    'header-text1': 'ยินดีต้อนรับ',
    'content-text1': 'ยินดีต้อนรับสู่ปั๊มเชลล์',
    'content-text2': 'สาขา',
    'modal-text1': `เครื่องพนักงานจะไม่สามารถ`,
    'modal-text2': `เปลี่ยนเป็นเมนูหลักของลูกค้าได้`,
    'button-text1': 'เริ่มต้นใช้งาน',
    'button-text2': 'ปิด',
  },
  'en': {
    'header-text1': 'ยินดีต้อนรับ',
    'content-text1': 'ยินดีต้อนรับสู่ปั๊มเชลล์',
    'content-text2': 'สาขา',
    'modal-text1': `เครื่องพนักงานจะไม่สามารถ`,
    'modal-text2': `เปลี่ยนเป็นเมนูหลักของลูกค้าได้`,
    'button-text1': 'เริ่มต้นใช้งาน',
    'button-text2': 'ปิด',
  },
};
