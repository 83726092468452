export default {
  'th': {
    'header-text1': 'รายละเอียดการจอง',
    'content-text1': 'ชื่อ',
    'content-text2': 'เบอร์โทรติดต่อ',
    'content-text3': 'ยี่ห้อรถ',
    'content-text4': 'รุ่นรถ',
    'content-text5': 'ทะเบียนรถ',
    'content-text6': 'วันที่ ',
    'content-text7': 'เวลา ',
    'content-text8': 'ปั๊มเซลล์สาขา ',
    'content-text9': 'สาขา',
    'button-text1': 'ยืนยัน',
    'button-text2': 'แก้ไขข้อมูล',
  },
  'en': {
    'header-text1': 'รายละเอียดการจอง',
    'content-text1': 'ชื่อ',
    'content-text2': 'เบอร์โทรติดต่อ',
    'content-text3': 'ยี่ห้อรถ',
    'content-text4': 'รุ่นรถ',
    'content-text5': 'ทะเบียนรถ',
    'content-text6': 'วันที่ ',
    'content-text7': 'เวลา ',
    'content-text8': 'ปั๊มเซลล์สาขา ',
    'content-text9': 'สาขา',
    'button-text1': 'ยืนยัน',
    'button-text2': 'แก้ไขข้อมูล',
  },
};
