import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import content from './Language';
import { setLanguage } from '../../actions';
import commonConstant from '../../common/commonConstant';
import { Loading } from '../../components';
import { history } from '../../helpers';

import { getCookieToken } from '../../common/commonFunction';

import './CustomerConsent.scss';

const liff = window.liff;

export class LineConsent extends Component {
    UNSAFE_componentWillMount() {
        if (!this.props.language) {
            return this.props.dispatch(setLanguage('th'));
        }
    }

    render() {
        const { language, line, user } = this.props;

        if (!language || !user || !line) {
            return <Loading />;
        }

        return (
            <div className="consent">
                <h1>
                    {content[language]['header-text2']}<br />
                    {content[language]['header-text2-1']}
                </h1>
                <div className="consent-box-line">
                    <div className="details">
                        <p>{content[language]['line-content-text1']}</p>
                        <p>{content[language]['line-content-text2']}</p>
                        <p>{content[language]['line-content-text3']}</p>
                        <p>{content[language]['line-content-text4']}</p>
                        <p>{content[language]['line-content-text5']}</p>
                        <p>{content[language]['line-content-text6']}</p>
                        <p>{content[language]['line-content-text7']}</p>
                        <p>{content[language]['line-content-text8']}</p>
                        <p>{content[language]['line-content-text9']}</p>
                        <p>{content[language]['line-content-text10']}</p>
                        <p>{content[language]['line-content-text11']}</p>
                    </div>
                </div>
                <input
                    type="button"
                    className="back"
                    onClick={() => history.goBack()}
                    value={content[language]['button-text2']}
                />
            </div>
        )
    }
}

LineConsent.propTypes = {
    'dispatch': PropTypes.func.isRequired,
    'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, user }) => ({
    'language': language.data,
    'line': line.data,
    'user': user.data,
});

export default connect(mapStateToProps)(LineConsent);
