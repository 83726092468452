import commonConstant from '../../common/commonConstant';

export const promotion = (state = {}, action) => {
  switch (action.type) {
      case commonConstant.FETCH_PROMOTION_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FETCH_PROMOTION_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
