import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import CustomInput from './CustomInput'
import "react-datepicker/dist/react-datepicker.css";
import './DatePicker.scss';

const now = new Date();
const endYear = new Date(now)
endYear.setDate(now.getDate() + 7);

export default class Datepicker extends Component {
  renderHeader = ({ date, decreaseMonth, increaseMonth }) => {
    const { language } = this.props;
    const months = [
      language === 'en' ? "January" : "มกราคม",
      language === 'en' ? "February" : "กุมภาพันธ์",
      language === 'en' ? "March" : "มีนาคม",
      language === 'en' ? "April" : "เมษายน",
      language === 'en' ? "May" : "พฤษภาคม",
      language === 'en' ? "June" : "มิถุนายน",
      language === 'en' ? "July" : "กรกฎาคม",
      language === 'en' ? "August" : "สิงหาคม",
      language === 'en' ? "September" : "กันยายน",
      language === 'en' ? "October" : "ตุลาคม",
      language === 'en' ? "November" : "พฤศจิกายน",
      language === 'en' ? "December" : "ธันวาคม"
    ];
    return (
      <div className="date-picker-header">
        <div className="wrap-icon"  onClick={decreaseMonth}>
          <i className="fas fa-chevron-left"></i>
        </div>
        <div>{months[date.getMonth()]} {parseInt(date.getFullYear()) + 543}</div>
        <div className="wrap-icon" onClick={increaseMonth}>
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>
    )
  }

  renderFormatWeekDay = (nameOfDay) => {
    const { language } = this.props;
    switch (nameOfDay) {
      case 'Sunday':
        return language === 'en' ? nameOfDay.substr(0, 2) : 'อา';
      case 'Monday':
        return language === 'en' ? nameOfDay.substr(0, 2) : 'จ';
      case 'Tuesday':
        return language === 'en' ? nameOfDay.substr(0, 2) : 'อ';
      case 'Wednesday':
        return language === 'en' ? nameOfDay.substr(0, 2) : 'พ';
      case 'Thursday':
        return language === 'en' ? nameOfDay.substr(0, 2) : 'พฤ';
      case 'Friday':
        return language === 'en' ? nameOfDay.substr(0, 2) : 'ศ';
      case 'Saturday':
        return language === 'en' ? nameOfDay.substr(0, 2) : 'ส';
      default:
        return nameOfDay.substr(0, 2);
    }
  }

  setStartDate = () => {
    const { isStaff } = this.props
    const startYear = new Date(now);
    startYear.setDate(now.getDate() + 1);
    return startYear
  }


  render() {
    const { selected, handleEvent, placeholderText, language, error, disabled, excludeDates } = this.props;
    return (
      <DatePicker
        selected={selected}
        onChange={handleEvent}
        dateFormat="yyyy-MM-dd"
        placeholderText={placeholderText}
        formatWeekDay={nameOfDay => this.renderFormatWeekDay(nameOfDay)}
        minDate={new Date(this.setStartDate())}
        maxDate={new Date(endYear)}
        customInput={<CustomInput language={language} error={error} dateDisabled={disabled} />}
        renderCustomHeader={this.renderHeader}
        disabled={disabled}
        excludeDates={excludeDates}
      />
    );
  }
}
