export default {
  'th': {
    'error-text1': 'ถูกยกเลิก',
    'error-text2': 'ได้รับบริการ',
    'content-text1': 'คุณไม่สามารถดูรายละเอียดได้',
    'content-text2': 'คุณไม่สามารถยกเลิกการจองได้',
    'content-text3': 'คุณไม่สามารถเปลี่ยนแปลงรายละเอียดได้',
    'content-text4': 'เนื่องจากคุณ',
    'content-text5': 'เนื่องจากรายการนี้',
    'content-text6': 'แล้ว',
    'content-text7': 'ไม่สามารถจองคิวได้',
    'content-text8': 'เนื่องจาก คุณทำการยกเลิกเกินจำนวนครั้งที่กำหนดและคุณจะทำการจองได้อีกครั้งในวันถัดไป',
    'content-text9': 'ไม่สามารถแก้ไขการจองได้',
    'content-text10': 'เนื่องจาก คุณทำการแก้ไขเกินจำนวนครั้งที่กำหนด',
    'content-text11': 'สาขา',
    'button-text1': 'จองบริการอีกครั้ง',
    'button-text2': 'จองบริการ',
    'button-text3': 'กลับสู่หน้าแชท',
    'button-text4': 'ยกเลิกการจอง',
  },
  'en': {
    'error-text1': 'ถูกยกเลิก',
    'error-text2': 'ได้รับบริการ',
    'content-text1': 'คุณไม่สามารถดูรายละเอียดได้',
    'content-text2': 'คุณไม่สามารถยกเลิกการจองได้',
    'content-text3': 'คุณไม่สามารถเปลี่ยนแปลงรายละเอียดได้',
    'content-text4': 'เนื่องจากคุณ',
    'content-text5': 'เนื่องจากรายการนี้',
    'content-text6': 'แล้ว',
    'content-text7': 'ไม่สามารถจองคิวได้',
    'content-text8': 'เนื่องจาก คุณทำการยกเลิกเกินจำนวนครั้งที่กำหนดและคุณจะทำการจองได้อีกครั้งในวันถัดไป',
    'content-text9': 'ไม่สามารถแก้ไขการจองได้',
    'content-text10': 'เนื่องจาก คุณทำการแก้ไขเกินจำนวนครั้งที่กำหนด',
    'content-text11': 'สาขา',
    'button-text1': 'จองบริการอีกครั้ง',
    'button-text2': 'จองบริการ',
    'button-text3': 'กลับสู่หน้าแชท',
    'button-text4': 'ยกเลิกการจอง',
  },
};
