import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DatePicker } from '../../../components';
import { ShellSelect } from '../../../helpers';
import content from './Language';
import '../CarwashBooking.scss';

class FormDate extends Component {
  watchBtnSubmit = () => {
    const {
      serviceDate,
      serviceTime,
      service,
    } = this.props.formValues;
    if (serviceDate.value !== '' && serviceTime.value !== '') {
      return {
        'disabled': false,
        'btnSubmit': service === 'Carwash' ? 'yellow' : 'blue',
      };
    } else {
      return { 'disabled': true, 'btnSubmit': 'disable' };
    }
  }

  render() {
    const { language, nextStep, functions  } = this.props;
    const { serviceTime, serviceDate, edit, walkIn, staff } = this.props.formValues;
    const { renderServiceTimes, handleEvent, handleCancel, excludeDates } = functions;
    const btnSubmit = this.watchBtnSubmit();
    return (
      <form className="forms">
        <div className="middle">
          <div className="form-container" >
            <p className="content-1">{content[language]['date-header-1']}</p>
            {!walkIn ? <p className="content-2">{content[language]['date-content-text1']}</p> : <br />}
            <DatePicker
              selected={serviceDate.value}
              handleEvent={event => handleEvent(event, 'serviceDate')}
              placeholderText={content[language]['date-content-text2']}
              language={language}
              error={serviceDate.error}
              disabled={walkIn}
              isStaff={staff === null ? false : true}
              excludeDates={excludeDates()}
            />
            {serviceDate.error ? <p className="error-text">{serviceDate.error}</p> : ''}
            <p className="content-3">{content[language]['date-header-2']}</p>
            <div className="box-input">
              <ShellSelect
                value={serviceTime.value}
                onChange={event => handleEvent(event, 'serviceTime')}
                disabled={serviceDate.value === '' || walkIn}
                customCarret={<img src={`/lineoa/img/clock.png`} alt="" />}
              >
                {renderServiceTimes()}
              </ShellSelect>
            </div>
          </div>
        </div>
        {edit ? '' :
          <div className="btn">
            <input
              type="button"
              className={btnSubmit.btnSubmit}
              onClick={() => nextStep()}
              value={content[language]['button-text1']}
              disabled={btnSubmit.disabled}
            />
            <input
              type="button"
              className="cancel"
              onClick={() => handleCancel()}
              value={content[language]['button-text3']}
            />
          </div>}
      </form>

    );
  }
}

FormDate.propTypes = {
  'language': PropTypes.string,
};

const mapStateToProps = ({ language }) => ({
  'language': language.data,
});

export default connect(mapStateToProps)(FormDate);
