export default {
  'th': {
    'header': 'ขออภัยอย่างสูง',
    'desc': 'ระบบไม่สามารถให้บริการได้ชั่วคราว กรุณาปิดหน้าจอ และลองทำรายการอีกครั้ง',
    'buttonReorder': 'ทำรายการอีกครั้ง',
  },
  'en': {
    'header': 'ขออภัยอย่างสูง',
    'desc': 'ระบบไม่สามารถให้บริการได้ชั่วคราว กรุณาปิดหน้าจอ และลองทำรายการอีกครั้ง',
    'buttonReorder': 'ทำรายการอีกครั้ง',
  },
};
