export default {
  'th': {
    'header-lube': 'จองเข้ารับบริการตรวจเช็กสภาพรถ และเปลี่ยนถ่ายน้ำมันเครื่อง',
    'header-carwash': 'จองเข้ารับบริการล้างรถ',
    'middle-text1': 'ใช้บริการได้ที่ ปั้มเชลล์ ',
    'middle-text2': ' เท่านัั้น***',
    'middle-text3': 'กรุณากรอกข้อมูลสำหรับ การจองเข้ารับบริการตรวจเช็ก สภาพรถและเปลี่ยนน้ำมันเครื่อง',
    'middle-text4': 'สาขา',
    'content-text1': 'ชื่อ-นามสกุล',
    'content-text2': 'เบอร์มือถือสำหรับการติดต่อ',
    'content-text3': 'วันที่ ที่ต้องการเข้ารับบริการ',
    'content-text4': '(สามารถทำการจองล่วงหน้าได้ไม่เกิน7วัน นับตั้งแต่วันที่จอง)',
    'content-text5': 'เวลาที่ต้องการเข้ารับบริการโดยประมาณ',
    'content-text6': '(ทางสถานีจะติดต่อกลับอีกครั้งเพื่อแจ้งยืนยันเวลารับบริการ)',
    'content-text7': 'กรุณากรอกข้อมูลรถที่จะเข้ารับบริการ',
    'content-text8': 'ทะเบียนรถ',
    'content-text9': 'ยี่ห้อรถ',
    'content-text10': 'โปรดระบุ',
    'content-text11': 'รุ่นรถ',
    'content-text12': 'โค้ดส่วนลด',
    'content-text13': 'คุณได้จองเข้ารับบริการเรียบร้อยแล้ว',
    'content-text14': 'ได้เปลี่ยนแปลงข้อมูลการจองให้ลูกค้าเรียบร้อยแล้ว',
    'content-text15': 'คุณได้เปลี่ยนแปลงข้อมูลการจองเรียบร้อย',
    'content-text16': 'สาขา',
    'error-text1': 'สามารถทำการจองล่วงหน้าได้ไม่เกิน7วัน',
    'error-text2': 'คิวเต็มแล้ว',
    'button-text1': 'ถัดไป',
    'button-text2': 'ยืนยัน',
    'button-text3': 'ยกเลิก',
  },
  'en': {
    'header-lube': 'จองเข้ารับบริการตรวจเช็กสภาพรถ และเปลี่ยนถ่ายน้ำมันเครื่อง',
    'header-carwash': 'จองเข้ารับบริการล้างรถ',
    'middle-text1': 'ใช้บริการได้ที่ ปั้มเชลล์ ',
    'middle-text2': ' เท่านัั้น***',
    'middle-text3': 'กรุณากรอกข้อมูลสำหรับ การจองเข้ารับบริการตรวจเช็ก สภาพรถและเปลี่ยนน้ำมันเครื่อง',
    'middle-text4': 'สาขา',
    'content-text1': 'ชื่อ-นามสกุล',
    'content-text2': 'เบอร์มือถือสำหรับการติดต่อ',
    'content-text3': 'วันที่ ที่ต้องการเข้ารับบริการ',
    'content-text4': '(สามารถทำการจองล่วงหน้าได้ไม่เกิน7วัน นับตั้งแต่วันที่จอง)',
    'content-text5': 'เวลาที่ต้องการเข้ารับบริการโดยประมาณ',
    'content-text6': '(ทางสถานีจะติดต่อกลับอีกครั้งเพื่อแจ้งยืนยันเวลารับบริการ)',
    'content-text7': 'กรุณากรอกข้อมูลรถที่จะเข้ารับบริการ',
    'content-text8': 'ทะเบียนรถ',
    'content-text9': 'ยี่ห้อรถ',
    'content-text10': 'โปรดระบุ',
    'content-text11': 'รุ่นรถ',
    'content-text12': 'โค้ดส่วนลด',
    'content-text13': 'คุณได้จองเข้ารับบริการเรียบร้อยแล้ว',
    'content-text14': 'ได้เปลี่ยนแปลงข้อมูลการจองให้ลูกค้าเรียบร้อยแล้ว',
    'content-text15': 'คุณได้เปลี่ยนแปลงข้อมูลการจองเรียบร้อย',
    'content-text16': 'สาขา',
    'error-text1': 'สามารถทำการจองล่วงหน้าได้ไม่เกิน7วัน',
    'error-text2': 'คิวเต็มแล้ว',
    'button-text1': 'ถัดไป',
    'button-text2': 'ยืนยัน',
    'button-text3': 'ยกเลิก',
  },
};
