import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';

export const setQueueEdit = (req) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/queue-edits?access_token=${commonConstant.cookieToken}`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify(req),
      });
      const data = await res.json();
      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.SET_QUEUE_EDIT_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      return dispatch({
        'type': commonConstant.SET_QUEUE_EDIT_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.SET_QUEUE_EDIT_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
