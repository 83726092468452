import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import commonConstant from '../../common/commonConstant';

import { setLanguage, fetchTransaction, setStatusOrder, fetchStaff } from '../../actions';
import { Loading } from '../../components';

import './StaffLubeScanQRCode.scss';
import content from './Language';
import { history } from '../../helpers';

class StaffLubeScanQRCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'service': null,
      'name': null,
      'phone': null,
      'serviceDate': null,
      'serviceTime': null,
      'branchName': null,
      'licensePlate': null,
      'brand': null,
      'model': null,
      'loading': false,
      'status': null,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    if (this.props.line) {
      const { userId } = this.props.line;
      await this.props.dispatch(fetchStaff(userId));
      const { status, data } = this.props.staff;
      if (status === 200) {
        const { orderId } = this.props.match.params;
        await this.props.dispatch(fetchTransaction(orderId));
        const { name, phone, serviceDate, serviceTime, branchName, licensePlate, service, status, brand, model } = this.props.transaction;
        this.setState({
          'name': name,
          'phone': [
            `0${phone.substring(3)}`.slice(0, 3),
            '-',
            `0${phone.substring(3)}`.slice(3),
          ].join(''),
          'serviceDate': moment(serviceDate).format('dd DD MMMM YYYY'),
          'serviceTime': serviceTime,
          'branchName': branchName,
          'licensePlate': licensePlate,
          'brand': brand,
          'model': model,
          'service': service,
          'status': status === 'Booking' && data.position.name === service,
        });
      }
    }
  }

  submitEdit = () => history.push(commonConstant.pathShellLubeBooking, { 'orderId': this.props.transaction.orderId, 'staff': 1 });

  submitYes = async () => {
    this.setState({ 'loading': true });
    const { transaction, line } = this.props;
    await this.props.dispatch(setStatusOrder({
      'name': 'InService',
      'orderId': transaction.orderId,
      'queueId': transaction.queueId,
      'staffLineId': line.userId,
    }));
    const { status } = this.props.statusOrder;
    switch (status) {
      case 200:
        this.setState({ 'loading': false });
        return history.replace(commonConstant.pathShellStaffLubeProcessing);
      default:
        alert('ขออภัยระบบขัดข้อง');
        this.setState({ 'loading': false });
    }
  }

  renderLoading = () => {
    const { loading } = this.state;
    if (loading) {return <Loading />;}
  }

  render() {
    const { language, transaction, line } = this.props;
    const { service } = this.state;

    if (!language || !transaction || !line || !service) {
      return <Loading />;
    }

    const {
      name,
      phone,
      serviceTime,
      serviceDate,
      branchName,
      licensePlate,
      brand,
      model,
      status,
    } = this.state;

    return (
      <main className="staff-lube-scan-qrcode">
        {this.renderLoading()}
        <div className={service === 'Lube' ? 'header' : 'header blue'}>
          <div className="logo">
            <img src={`/lineoa/img/staff-logo.svg`} alt="logo" />
          </div>
          <p className>{content[language]['content-text9']} {transaction.branchName}</p>
        </div>
        <h1>{content[language]['header-text1']}</h1>
        <div className="middle">
          <div className="box-1">
            <div className="detail">
              <div className="name">
                <p>{content[language]['content-text1']}</p>
                <p>{content[language]['content-text2']}</p>
                <p>{content[language]['content-text3']}</p>
                <p>{content[language]['content-text4']}</p>
                <p>{content[language]['content-text5']}</p>
              </div>
              <div className="value">
                <p>{name}</p>
                <a className="phone-link" href={`tel:${phone}`}>{phone}</a>
                <p>{brand}</p>
                <p>{model}</p>
                <p>{licensePlate}</p>
              </div>
            </div>
          </div>
          <div className="box-2">
            <div className="detail">
              <div className="name">
                <p>{content[language]['content-text6']}</p>
                <p>{content[language]['content-text7']}</p>
                <p>{content[language]['content-text8']}</p>
              </div>
              <div className="value">
                <p>{serviceDate}</p>
                <p>{serviceTime}</p>
                <p>{branchName}</p>
              </div>
            </div>
          </div>
        </div>
        {status ? (
          <footer>
            <div className="btn">
              <button className={service === 'Lube' ? 'yellow' : 'blue'} onClick={() => this.submitYes()}>
                {content[language]['button-text1']}
              </button>
              <button className="white" onClick={() => this.submitEdit()}>
                {content[language]['button-text2']}
              </button>
            </div>
          </footer>
        ) : null
        }
      </main>
    );
  }
}

StaffLubeScanQRCode.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, transaction, staff, line, statusOrder }) => ({
  'language': language.data,
  'transaction': transaction.data,
  'staff': staff,
  'line': line.data,
  'statusOrder': statusOrder,
});

export default connect(mapStateToProps)(StaffLubeScanQRCode);
