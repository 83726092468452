import commonConstant from '../../common/commonConstant';

export const user = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.FETCH_USER_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FETCH_USER_FAILURE:
      return { ...state, ...action };
    case commonConstant.SET_USER_SUCCESS:
      return { ...state };
    case commonConstant.SET_USER_FAILURE:
      return { ...state };
    case commonConstant.UPDATE_USER_SUCCESS:
      return { ...state };
    case commonConstant.UPDATE_USER_FAILURE:
      return { ...state };
    case commonConstant.SEND_BOOKING_MESSAGE_SUCCESS:
      return { ...state };
    case commonConstant.SEND_BOOKING_MESSAGE_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export const updateCancelNum = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.UPDATE_CANCEL_NUM_SUCCESS:
      return { ...state, ...action };
    case commonConstant.UPDATE_CANCEL_NUM_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};