import React, { Component } from 'react'
import FormDate from './FormDate';
import FormCarDetails from './FormCarDetails';
import FormUserDetails from './FormUserDetails';
import '../LubeBooking.scss';

export class Forms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'step': 1,
            'completedStep': 0,
            'doneStep': {
                '1': false,
                '2': false,
                '3': false,
            },
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.errorStep !== this.props.errorStep) {
            this.setState({ 'step': 1, 'completedStep': 0 });
        }
    }

    nextStep = () => {
        let { step, completedStep, doneStep } = this.state;
        const temp = doneStep;
        if (!doneStep.step) {
            completedStep += 1;
            temp[`${step}`] = true;
        }
        step += 1;
        this.setState({ step, completedStep, doneStep: temp });
    }

    renderForm = () => {
        const { step } = this.state;
        const { formValues, functions } = this.props;
        switch (step) {
            case 1:
                return <FormDate formValues={formValues} nextStep={this.nextStep} functions={functions} />;
            case 2:
                return <FormCarDetails formValues={formValues} nextStep={this.nextStep} functions={functions} />;
            case 3:
                return <FormUserDetails formValues={formValues} functions={functions} />;
        }
    }

    handleClickStep = (clickedStep) => {
        const { completedStep } = this.state;
        if (completedStep === 2) {
            this.setState({ 'step': clickedStep });
        } else if (completedStep === 1 && clickedStep <= 2) {
            this.setState({ 'step': clickedStep });
        }
    }

    render() {
        const { step } = this.state;

        return (
            <>
                <div className="container">
                    <ul className="progressbar">
                        <li className={step >= 1 ? "active" : null}>
                            <div className="circle" onClick={() => this.handleClickStep(1)}>
                                <img step={1} className="step1" src={process.env.REACT_APP_HOMEPAGE + `img/step1.png`} alt="step1" />
                            </div>
                        </li>
                        <li className={step >= 2 ? "active" : null}>
                            <div className="circle" onClick={() => this.handleClickStep(2)}>
                                <img step={2} className="step2" src={process.env.REACT_APP_HOMEPAGE + `img/step2.png`} alt="step2" />
                            </div>
                        </li>
                        <li className={step == 3 ? "active" : null}>
                            <div className="circle" onClick={() => this.handleClickStep(3)}>
                                <img step={3} className="step3" src={process.env.REACT_APP_HOMEPAGE + `img/step3.png`} alt="step3" />
                            </div>
                        </li>
                    </ul>
                </div>
                {this.renderForm()}
            </>
        )
    }
}

export default Forms;
