import commonConstant from './commonConstant';
import { createCookie, getCookie, deleteCookie } from '../helpers';
import moment from 'moment';
import 'moment/locale/th'

// cookie
const createCookieLanguage = value => value ? createCookie(commonConstant.cookieLanguage, value) : createCookie(commonConstant.cookieLanguage, commonConstant.defaultLanguage);
const getCookieLanguage = () => getCookie(commonConstant.cookieLanguage) ? getCookie(commonConstant.cookieLanguage) : commonConstant.defaultLanguage;

// cookie token login
// const createCookieToken = token => createCookie(commonConstant.cookieToken, token, 3);
const getCookieToken = () => getCookie(commonConstant.cookieToken) ? getCookie(commonConstant.cookieToken) : null;
// const deleteCookieToken = () => deleteCookie(commonConstant.cookieToken);

// change timezone
const changeTimeZone = (date) => {
  const newDate = new Date(date);
  const offset = new Date().getTimezoneOffset();
  newDate.setHours(newDate.getHours() - offset / 60);
  return newDate;
}
const changeFormatDate1 = (date) => moment(date).format("dd DD MMMM YYYY");
const changeFormatDate2 = (date) => moment(date).format("วัน DD MMMM YYYY")
const changeFormatDate3 = (date) => `${moment(date).format("dd DD MMMM")} ${Number(moment(date).format("YYYY")) + 543}`
// get date without time
const getDateWithoutTime = (date) => {
  const dt = new Date(date);
  const day = ("0" + dt.getDate()).slice(-2);
  const month = ("0" + (dt.getMonth() + 1)).slice(-2);
  return dt.getFullYear() + "-" + month + "-" + day;
};

export {
  // cookie
  createCookieLanguage,
  getCookieLanguage,
  // createCookieToken,
  getCookieToken,
  // deleteCookieToken,
  // timezone
  changeTimeZone,
  changeFormatDate1,
  changeFormatDate2,
  changeFormatDate3,
  getDateWithoutTime
};
