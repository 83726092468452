export default {
  // path
  'pathShellLineOA': '/lineoa/liff',
  'pathShellLubeDetail': '/lineoa/lube/detail',
  'pathShellLubeBooking': '/lineoa/lube/booking',
  'pathShellLubeCancel': '/lineoa/lube/cancel',
  'pathShellLubeNot': '/lineoa/lube/not',
  'pathShellCustomerScan': '/lineoa/customer/scan',
  'pathShellCustomerConsent': '/lineoa/customer/consent',
  'pathShellLineConsent': '/lineoa/customer/line-consent',
  'pathShellPrivacyConsent': '/lineoa/customer/privacy-consent',
  'pathShellStaffLubeQueues': '/lineoa/staff/lube/queues',
  'pathShellStaffLubeQueueDetail': '/lineoa/staff/lube/queue/detail',
  'pathShellStaffLubeSuccess': '/lineoa/staff/lube/success',
  'pathShellStaffLubeProcessing': '/lineoa/staff/lube/processing',
  'pathShellStaffLubeProcessingDetail': '/lineoa/staff/lube/processing/detail',
  'pathShellStaffLubeProcessingCompleted': '/lineoa/staff/lube/processing/completed',
  'pathShellStaffLubeCallCustomer': '/lineoa/staff/lube/processing/call-customer',
  'pathShellStaffLubeScan': '/lineoa/staff/lube/scan',
  'pathShellWalkInDetail': '/lineoa/staff/lube/walk-in/detail',
  'pathShellWalkInConfirm': '/lineoa/customer/walk-in',
  'pathShellWalkInCheck': '/lineoa/staff/walk-in-check',

  'pathShellCarwashBooking': '/lineoa/carwash/booking',
  'pathShellCarwashDetail': '/lineoa/carwash/detail',
  'pathShellCarwashCancel': '/lineoa/carwash/cancel',
  'pathShellCarwashNot': '/lineoa/carwash/not',

  'pathServerError': '/lineoa/server-error',
  // image
  // action
  //  - authen
  'LOGIN_SUCCESS': 'LOGIN_SUCCESS',
  'LOGIN_FAILURE': 'LOGIN_FAILURE',
  'REFRESH_TOKEN_SUCCESS': 'REFRESH_TOKEN_SUCCESS',
  'REFRESH_TOKEN_FAILURE': 'REFRESH_TOKEN_FAILURE',
  // - language
  'SET_LANGUAGE_SUCCESS': 'SET_LANGUAGE_SUCCESS',
  'SET_LANGUAGE_FAILURE': 'SET_LANGUAGE_FAILURE',
  // - line
  'SET_PROFILE_SUCCESS': 'SET_PROFILE_SUCCESS',
  'SET_PROFILE_FAILURE': 'SET_PROFILE_FAILURE',
  // - users
  'FETCH_USER_SUCCESS': 'FETCH_USER_SUCCESS',
  'FETCH_USER_FAILURE': 'FETCH_USER_FAILURE',
  'SET_USER_SUCCESS': 'SET_USER_SUCCESS',
  'SET_USER_FAILURE': 'SET_USER_FAILURE',
  'UPDATE_USER_SUCCESS': 'UPDATE_USER_SUCCESS',
  'UPDATE_USER_FAILURE': 'UPDATE_USER_FAILURE',
  'ACCEPT_CONSENT_SUCCESS': 'ACCEPT_CONSENT_SUCCESS',
  'ACCEPT_CONSENT_FAILURE': 'ACCEPT_CONSENT_FAILURE',
  'SEND_BOOKING_MESSAGE_SUCCESS': 'SEND_BOOKING_MESSAGE_SUCCESS',
  'SEND_BOOKING_MESSAGE_FAILURE': 'SEND_BOOKING_MESSAGE_FAILURE',
  'UPDATE_CANCEL_NUM_SUCCESS': 'UPDATE_CANCEL_NUM_SUCCESS',
  'UPDATE_CANCEL_NUM_FAILURE': 'UPDATE_CANCEL_NUM_FAILURE',
  // - queues
  'FETCH_QUEUES_SUCCESS': 'FETCH_QUEUES_SUCCESS',
  'FETCH_QUEUES_FAILURE': 'FETCH_QUEUES_FAILURE',
  // - orders
  'SET_ORDER_SUCCESS': 'SET_ORDER_SUCCESS',
  'SET_ORDER_FAILURE': 'SET_ORDER_FAILURE',
  'FETCH_ORDER_SUCCESS': 'FETCH_ORDER_SUCCESS',
  'FETCH_ORDER_FAILURE': 'FETCH_ORDER_FAILURE',
  'FETCH_ORDER_WEEKLY_SUCCESS': 'FETCH_ORDER_WEEKLY_SUCCESS',
  'FETCH_ORDER_WEEKLY_FAILURE': 'FETCH_ORDER_WEEKLY_FAILURE',
  'FETCH_CANCEL_ORDER_WEEKLY_SUCCESS': 'FETCH_CANCEL_ORDER_WEEKLY_SUCCESS',
  'FETCH_CANCEL_ORDER_WEEKLY_FAILURE': 'FETCH_CANCEL_ORDER_WEEKLY_FAILURE',
  'FETCH_ORDER_TODAY_SUCCESS': 'FETCH_ORDER_TODAY_SUCCESS',
  'FETCH_ORDER_TODAY_FAILURE': 'FETCH_ORDER_TODAY_FAILURE',
  'FETCH_ORDER_YESTERDAY_SUCCESS': 'FETCH_ORDER_YESTERDAY_SUCCESS',
  'FETCH_ORDER_YESTERDAY_FAILURE': 'FETCH_ORDER_YESTERDAY_FAILURE',
  'SET_ORDER_STAFF_SUCCESS': 'SET_ORDER_STAFF_SUCCESS',
  'SET_ORDER_STAFF_FAILURE': 'SET_ORDER_STAFF_FAILURE',
  'CREATE_WALKIN_QRCODE_SUCCESS': 'CREATE_WALKIN_QRCODE_SUCCESS',
  'CREATE_WALKIN_QRCODE_FAILURE': 'CREATE_WALKIN_QRCODE_FAILURE',
  'CONFIRM_WALKIN_ORDER_SUCCESS': 'CONFIRM_WALKIN_ORDER_SUCCESS',
  'CONFIRM_WALKIN_ORDER_FAILURE': 'CONFIRM_WALKIN_ORDER_FAILURE',
  'SET_VIEWED_ORDER_SUCCESS': 'SET_VIEWED_ORDER_SUCCESS',
  'SET_VIEWED_ORDER_FAILURE': 'SET_VIEWED_ORDER_FAILURE',
  'UPDATE_EDIT_NUM_SUCCESS': 'UPDATE_EDIT_NUM_SUCCESS',
  'UPDATE_EDIT_NUM_FAILURE': 'UPDATE_EDIT_NUM_FAILURE',
  //- AheadCancelOrder
  'FETCH_AHEAD_CANCEL_ORDER_SUCCESS': 'FETCH_AHEAD_CANCEL_ORDER_SUCCESS',
  'FETCH_AHEAD_CANCEL_ORDER_FAILURE': 'FETCH_AHEAD_CANCEL_ORDER_FAILURE',
  // - PrevCancelOrder
  'FETCH_PREV_CANCEL_ORDER_SUCCESS': 'FETCH_PREV_CANCEL_ORDER_SUCCESS',
  'FETCH_PREV_CANCEL_ORDER_FAILURE': 'FETCH_PREV_CANCEL_ORDER_FAILURE',
  // - transactions
  'FETCH_TRANSACTION_SUCCESS': 'FETCH_TRANSACTION_SUCCESS',
  'FETCH_TRANSACTION_FAILURE': 'FETCH_TRANSACTION_FAILURE',
  // - recentTransaction
  'FETCH_RECENT_TRANSACTION_SUCCESS': 'FETCH_RECENT_TRANSACTION_SUCCESS',
  'FETCH_RECENT_TRANSACTION_FAILURE': 'FETCH_RECENT_TRANSACTION_FAILURE',
  // - promotions
  'FETCH_PROMOTION_SUCCESS': 'FETCH_PROMOTION_SUCCESS',
  'FETCH_PROMOTION_FAILURE': 'FETCH_PROMOTION_FAILURE',
  // - statusOrders
  'SET_STATUS_ORDER_SUCCESS': 'SET_STATUS_ORDER_SUCCESS',
  'SET_STATUS_ORDER_FAILURE': 'SET_STATUS_ORDER_FAILURE',
  'FETCH_STATUS_ORDERS_CALL_CUSTOMER_SUCCESS': 'FETCH_STATUS_ORDERS_CALL_CUSTOMER_SUCCESS',
  'FETCH_STATUS_ORDERS_CALL_CUSTOMER_FAILURE': 'FETCH_STATUS_ORDERS_CALL_CUSTOMER_FAILURE',
  // - queueEdits
  'SET_QUEUE_EDIT_SUCCESS': 'SET_QUEUE_EDIT_SUCCESS',
  'SET_QUEUE_EDIT_FAILURE': 'SET_QUEUE_EDIT_FAILURE',
  // - stations
  'FETCH_STATION_SUCCESS': 'FETCH_STATION_SUCCESS',
  'FETCH_STATION_FAILURE': 'FETCH_STATION_FAILURE',
  // - staffs
  'FETCH_STAFF_SUCCESS': 'FETCH_STAFF_SUCCESS',
  'FETCH_STAFF_FAILURE': 'FETCH_STAFF_FAILURE',
  // env 
  'liffId': process.env.REACT_APP_LIFF_ID,
  // 'liffId': '1653760498-KO9OwgLd',
  'envDomainShellApi': process.env.REACT_APP_DOMAIN_SHELL_API,
  // 'envDomainShellApi': 'https://dev-shell-api.almondclient.com',
  'directLinkLineOA': process.env.REACT_APP_DIRECT_LINK_LINEOA,
  'stationId': process.env.REACT_APP_STATION_ID,
  // 'directLinkLineOA': 'https://lin.ee/yPaMgwX',
  // token
  'cookieToken': process.env.REACT_APP_COOKIE_TOKEN,
  // 'cookieToken': 'baNMNvBLNyvjJnHfV5eGPXG2bTyEEAXHCwsOudt8UeeC9M3lALi7BPC71Tlc3yGj',
  // language
  'thai': 'ไทย',
  'eng': 'EN',
  // cookie name
  // class name
  // error
};
