export default {
  'th': {
    'header-text1': 'แจ้งลูกค้าเข้ารับรถเรียบร้อยแล้ว',
    'content-text1': 'ชื่อ',
    'content-text2': 'เบอร์ติดต่อ',
    'content-text3': 'ยี่ห้อรถ',
    'content-text4': 'รุ่นรถ',
    'content-text5': 'ทะเบียนรถ',
    'content-text6': 'วันที่ ',
    'content-text7': 'เวลา ',
    'content-text8': 'สถานีบริการ ',
    'content-text9': 'นามสกุล',
    'content-text10': 'แพ็กเกจ',
    'content-text11': 'สาขา',
    'content-text12': 'รายละเอียด',
    'button-text1': 'แจ้งลูกค้าเข้ารับรถ',
    'button-text2': 'รับบริการเสร็จสิ้น',
  },
  'en': {
    'header-text1': 'แจ้งลูกค้าเข้ารับรถเรียบร้อยแล้ว',
    'content-text1': 'ชื่อ',
    'content-text2': 'เบอร์ติดต่อ',
    'content-text3': 'ยี่ห้อรถ',
    'content-text4': 'รุ่นรถ',
    'content-text5': 'ทะเบียนรถ',
    'content-text6': 'วันที่ ',
    'content-text7': 'เวลา ',
    'content-text8': 'สถานีบริการ ',
    'content-text9': 'นามสกุล',
    'content-text10': 'แพ็กเกจ',
    'content-text11': 'สาขา',
    'content-text12': 'รายละเอียด',
    'button-text1': 'แจ้งลูกค้าเข้ารับรถ',
    'button-text2': 'รับบริการเสร็จสิ้น',
  },
};
