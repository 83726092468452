import commonConstant from '../../common/commonConstant';

export const aheadCancelOrder = (state = {}, action) => {
    switch (action.type) {
        case commonConstant.FETCH_AHEAD_CANCEL_ORDER_SUCCESS:
            return { ...state, ...action };
        case commonConstant.FETCH_AHEAD_CANCEL_ORDER_FAILURE:
            return { ...state, ...action };
        default:
            return state;
    }
};
