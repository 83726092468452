import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';

export const setLanguage = language => (
  async dispatch => {
    try {
      if (language) {
        // createCookieLanguage(language);
        return dispatch({
          'type': commonConstant.SET_LANGUAGE_SUCCESS,
          'data': language,
          'status': 'set language success',
          'dateTime': new Date(),
        });
      }
      // createCookieLanguage();
      return dispatch({
        'type': commonConstant.SET_LANGUAGE_FAILURE,
        'data': commonConstant.defaultLanguage,
        'status': 'language is undefined',
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.SET_LANGUAGE_FAILURE,
        'data': commonConstant.defaultLanguage,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
