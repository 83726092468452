import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import './App.scss';

import commonConstant from './common/commonConstant';
import { history, ErrorBoundary } from './helpers';

import {
  LineOA,
  LubeBooking,
  LubeDetail,
  LubeCancel,
  LubeNot,
  Customer,
  CustomerConsent,
  LineConsent,
  StaffLubeQueues,
  StaffLubeQueueDetail,
  StaffLubeSuccess,
  StaffLubeProcessing,
  StaffLubeProcessingDetail,
  StaffLubeProcessingCompleted,
  StaffLubeCallCustomer,
  StaffLubeScanQRCode,
  PrivacyConsent,
  WalkInConfirm,
  WalkInDetail,
  WalkInCheck,
  CarwashBooking,
  CarwashDetail,
  CarwashCancel,
  CarwashNot,
  ServerError
} from './pages';

export default () => (
  <ErrorBoundary>
    <Router history={history}>
      <div className="App">
        <Switch>
          <Route sensitive strict exact path={commonConstant.pathShellLineOA} component={LineOA} />
          <Route sensitive strict exact path={commonConstant.pathShellLubeBooking} component={LubeBooking} />
          <Route sensitive strict exact path={`${commonConstant.pathShellLubeDetail}/:orderId`} component={LubeDetail} />
          <Route sensitive strict exact path={`${commonConstant.pathShellLubeCancel}/:orderId`} component={LubeCancel} />
          <Route sensitive strict exact path={commonConstant.pathShellLubeNot} component={LubeNot} />
          <Route sensitive strict exact path={commonConstant.pathShellCustomerScan} component={Customer} />
          <Route sensitive strict exact path={commonConstant.pathShellCustomerConsent} component={CustomerConsent} />
          <Route sensitive strict exact path={commonConstant.pathShellLineConsent} component={LineConsent} />
          <Route sensitive strict exact path={commonConstant.pathShellPrivacyConsent} component={PrivacyConsent} />
          <Route sensitive strict exact path={commonConstant.pathShellStaffLubeQueues} component={StaffLubeQueues} />
          <Route sensitive strict exact path={`${commonConstant.pathShellStaffLubeQueueDetail}/:orderId`} component={StaffLubeQueueDetail} />
          <Route sensitive strict exact path={commonConstant.pathShellStaffLubeSuccess} component={StaffLubeSuccess} />
          <Route sensitive strict exact path={commonConstant.pathShellStaffLubeProcessing} component={StaffLubeProcessing} />
          <Route sensitive strict exact path={`${commonConstant.pathShellStaffLubeProcessingDetail}/:orderId`} component={StaffLubeProcessingDetail} />
          <Route sensitive strict exact path={`${commonConstant.pathShellStaffLubeProcessingCompleted}/:orderId`} component={StaffLubeProcessingCompleted} />
          <Route sensitive strict exact path={`${commonConstant.pathShellStaffLubeCallCustomer}/:orderId`} component={StaffLubeCallCustomer} />
          <Route sensitive strict exact path={`${commonConstant.pathShellStaffLubeScan}/:orderId`} component={StaffLubeScanQRCode} />
          <Route sensitive strict exact path={commonConstant.pathShellWalkInConfirm} component={WalkInConfirm} />
          <Route sensitive strict exact path={`${commonConstant.pathShellWalkInDetail}/:orderId`} component={WalkInDetail} />
          <Route sensitive strict exact path={`${commonConstant.pathShellWalkInCheck}/:orderId`} component={WalkInCheck} />

          <Route sensitive strict exact path={commonConstant.pathShellCarwashBooking} component={CarwashBooking} />
          <Route sensitive strict exact path={`${commonConstant.pathShellCarwashDetail}/:orderId`} component={CarwashDetail} />
          <Route sensitive strict exact path={`${commonConstant.pathShellCarwashCancel}/:orderId`} component={CarwashCancel} />
          <Route sensitive strict exact path={commonConstant.pathShellCarwashNot} component={CarwashNot} />
          <Route sensitive strict exact path={`${commonConstant.pathServerError}`} component={ServerError} />
        </Switch>
      </div>
    </Router>
  </ErrorBoundary>
);
