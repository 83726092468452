export default {
  'th': {
    'header-text1': 'รายละเอียดลูกค้า',
    'content-text1': 'แจ้งลูกค้าเข้ารับรถเรียบร้อยแล้ว',
    'content-text2': 'แจ้งลูกค้าเข้ารับรถ',
    'content-text3': 'ชื่อ',
    'content-text4': 'เบอร์ติดต่อ',
    'content-text5': 'ยี่ห้อรถ',
    'content-text6': 'รุ่นรถ',
    'content-text7': 'ทะเบียนรถ',
    'content-text8': 'วันที่',
    'content-text9': 'เวลา',
    'content-text10': 'สถานีบริการ',
    'content-text11': 'สาขา',
    'content-text12': 'นามสกุล',
    'content-text13': 'แพ็กเกจ',
    'button-text1': 'รับบริการเสร็จสิ้น',
    'button-text2': 'กลับหน้าเช็กรถ',
  },
  'en': {
    'header-text1': 'รายละเอียดลูกค้า',
    'content-text1': 'แจ้งลูกค้าเข้ารับรถเรียบร้อยแล้ว',
    'content-text2': 'แจ้งลูกค้าเข้ารับรถ',
    'content-text3': 'ชื่อ',
    'content-text4': 'เบอร์ติดต่อ',
    'content-text5': 'ยี่ห้อรถ',
    'content-text6': 'รุ่นรถ',
    'content-text7': 'ทะเบียนรถ',
    'content-text8': 'วันที่',
    'content-text9': 'เวลา',
    'content-text10': 'สถานีบริการ',
    'content-text11': 'สาขา',
    'content-text12': 'นามสกุล',
    'content-text13': 'แพ็กเกจ',
    'button-text1': 'รับบริการเสร็จสิ้น',
    'button-text2': 'กลับหน้าเช็กรถ',
  },
};
