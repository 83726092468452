export default {
  'th': {
    'content-text1': 'สาขา',
    'content-text2': 'เช็กคิวการจอง',
    'content-text3': 'คิวที่ถูกยกเลิก',
    'content-text4': 'ล่วงหน้า',
    'content-text5': 'ย้อนหลัง',
    'content-text6': 'บริการถ่ายน้ำมันเครื่อง',
    'content-text7': 'บริการล้างรถ',
  },
  'en': {
    'content-text1': 'สาขา',
    'content-text2': 'เช็กคิวการจอง',
    'content-text3': 'คิวที่ถูกยกเลิก',
    'content-text4': 'ล่วงหน้า',
    'content-text5': 'ย้อนหลัง',
    'content-text6': 'บริการถ่ายน้ำมันเครื่อง',
    'content-text7': 'บริการล้างรถ',
  },
};
