export default {
  'th': {
    'header-text1': 'คุณได้ทำการจองบริการตรวจเช็กสภาพรถและเปลี่ยนถ่ายน้ำมันเครื่องกับเชลล์เรียบร้อยแล้ว',
    'header-carwash-text1': 'คุณได้ทำการเข้ารับบริการล้างรถกับเชลล์เรียบร้อยแล้ว',
    'content-text1': 'กรุณากดปุ่ม',
    'content-text2': 'ยืนยัน',
    'content-text3': 'เพื่อรับการแจ้งเตือนบริการ',
    'button-text1': 'ยืนยัน',
    'modal-text1': 'เครื่องพนักงานจะไม่สามารถ',
    'modal-text2': 'เข้าใช้งานเป็นลูกค้าได้',
    'button-text2': 'ปิด',
  },
  'en': {
    'header-text1': 'คุณได้ทำการจองบริการตรวจเช็กสภาพรถและเปลี่ยนถ่ายน้ำมันเครื่องกับเชลล์เรียบร้อยแล้ว',
    'header-carwash-text1': 'คุณได้ทำการเข้ารับบริการล้างรถกับเชลล์เรียบร้อยแล้ว',
    'content-text1': 'กรุณากดปุ่ม',
    'content-text2': 'ยืนยัน',
    'content-text3': 'เพื่อรับการแจ้งเตือนบริการ',
    'button-text1': 'ยืนยัน',
    'modal-text1': 'เครื่องพนักงานจะไม่สามารถ',
    'modal-text2': 'เข้าใช้งานเป็นลูกค้าได้',
    'button-text2': 'ปิด',
  },
};
