import commonConstant from '../../common/commonConstant';

export const order = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.SET_ORDER_SUCCESS:
      return { ...state, ...action };
    case commonConstant.SET_ORDER_FAILURE:
      return { ...state, ...action };
    case commonConstant.FETCH_ORDER_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FETCH_ORDER_FAILURE:
      return { ...state, ...action };
    case commonConstant.CONFIRM_WALKIN_ORDER_SUCCESS:
      return { ...state, ...action };
    case commonConstant.CONFIRM_WALKIN_ORDER_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const orderWeekly = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.FETCH_ORDER_WEEKLY_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FETCH_ORDER_WEEKLY_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const cancelOrderWeekly = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.FETCH_CANCEL_ORDER_WEEKLY_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FETCH_CANCEL_ORDER_WEEKLY_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const orderToday = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.FETCH_ORDER_TODAY_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FETCH_ORDER_TODAY_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const orderYesterday = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.FETCH_ORDER_YESTERDAY_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FETCH_ORDER_YESTERDAY_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};


export const orderStaff = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.SET_ORDER_STAFF_SUCCESS:
      return { ...state, ...action };
    case commonConstant.SET_ORDER_STAFF_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const setViewedOrder = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.SET_VIEWED_ORDER_SUCCESS:
      return { ...state, ...action };
    case commonConstant.SET_VIEWED_ORDER_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const updateEditNum = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.UPDATE_EDIT_NUM_SUCCESS:
      return { ...state, ...action };
    case commonConstant.UPDATE_EDIT_NUM_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};