import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import commonConstant from "../../common/commonConstant";

import { setLanguage, setProfile } from "../../actions";

import { Loading } from "../../components";
import { history } from "../../helpers";

const liff = window.liff;

class Delivery extends Component {
  getProfile = async () => {
    const getProfile = await liff.getProfile();
    await this.props.dispatch(setProfile(getProfile));
    const urlParams = new URLSearchParams(this.props.location.search);
    switch (urlParams.get("page")) {
      case "lube-booking":
        return history.replace(commonConstant.pathShellLubeBooking, {
          service: urlParams.get("service")
        });
      case "lube-detail":
        return history.replace(
          `${commonConstant.pathShellLubeDetail}/${urlParams.get("orderId")}`
        );
      case "lube-cancel":
        return history.replace(
          `${commonConstant.pathShellLubeCancel}/${urlParams.get("orderId")}`
        );
      case "lube-edit":
        return history.replace(commonConstant.pathShellLubeBooking, {
          orderId: urlParams.get("orderId")
        });
      case "customer-scan":
        return history.replace(commonConstant.pathShellCustomerScan, {
          stationId: urlParams.get("stationId")
        });
      case "staff-weekly":
        return history.replace(commonConstant.pathShellStaffLubeQueues);
      case "staff-today":
        return history.replace(commonConstant.pathShellStaffLubeProcessing);
      case "staff-lube-detail":
        return history.replace(
          `${commonConstant.pathShellStaffLubeQueueDetail}/${urlParams.get(
            "orderId"
          )}`,
          { pushMsg: true }
        );
      case "staff-lube-scan":
        return history.replace(
          `${commonConstant.pathShellStaffLubeScan}/${urlParams.get("orderId")}`
        );
      case "customer-walk-in":
        return history.replace(commonConstant.pathShellWalkInConfirm, {
          orderId: urlParams.get("orderId"),
          stationId: urlParams.get("stationId")
        });
      case "customer-consent":
        return history.replace(commonConstant.pathShellCustomerConsent, {
          service: urlParams.get("service")
        });
      case "walk-in-check":
        return history.replace(
          `${commonConstant.pathShellWalkInCheck}/${urlParams.get("orderId")}`
        );
      case "carwash-booking":
        return history.replace(`${commonConstant.pathShellCarwashBooking}`, {
          service: "Carwash"
        });
      case "carwash-detail":
        return history.replace(
          `${commonConstant.pathShellCarwashDetail}/${urlParams.get("orderId")}`
        );
      case "carwash-edit":
        return history.replace(commonConstant.pathShellCarwashBooking, {
          orderId: urlParams.get("orderId")
        });
      case "carwash-cancel":
        return history.replace(
          `${commonConstant.pathShellCarwashCancel}/${urlParams.get("orderId")}`
        );
      default:
        return console.log("no page");
    }
  };

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage("th"));
    }
  }

  componentDidMount() {
    liff.init(
      { liffId: commonConstant.liffId },
      () => {
        if (liff.isLoggedIn()) {
          this.getProfile();
        } else {
          liff.login();
        }
      },
      err => console.error(err.code, err.message)
    );
  }

  render() {
    return <Loading />;
  }
}

Delivery.propTypes = {
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.string
};

const mapStateToProps = ({ language }) => ({ language: language.data });

export default connect(mapStateToProps)(Delivery);
