import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import content from './Language';
import {
    setLanguage,
    acceptConsent,
    fetchUser
} from '../../actions';
import commonConstant from '../../common/commonConstant';
import { Loading } from '../../components';
import { history } from '../../helpers';


import './CustomerConsent.scss';

const liff = window.liff;

export class CustomerConsent extends Component {

    UNSAFE_componentWillMount() {
        if (!this.props.language) {
            return this.props.dispatch(setLanguage('th'));
        }
    }

    async componentDidMount() {
        const { userId } = this.props.line;
        await this.props.dispatch(fetchUser(userId));
    }

    handleSubmit = async () => {
        const { service } = this.props.location.state;
        const { userId } = this.props.line;
        const { stationId } = this.props.user;
        await this.props.dispatch(acceptConsent(userId, stationId));
        if (service === 'Lube') {
            history.replace(commonConstant.pathShellLubeBooking, { service: 'Lube' });
        } else {
            history.replace(commonConstant.pathShellCarwashBooking ,{ service: 'Carwash' });
        }
       
    }

    render() {
        const { language, line, user } = this.props;

        if (!language || !line || !user) {
            return <Loading />;
        }
        const consent = user.consent;

        const { service } = this.props.location.state;

        if (!consent) {
            if (service === 'Lube') {
                history.replace(commonConstant.pathShellLubeBooking, { service: 'Lube' });
            } else {
                history.replace(commonConstant.pathShellCarwashBooking ,{ service: 'Carwash' });
            }
        }

        return (
            <div className="customerConsent">
                <div className="consent-container">
                    <h1>{content[language]['header-text1']}</h1>
                    <h1>{content[language]['header-text1-1']}</h1>
                    <div className="links" >
                        <div className="content-link">
                            <i class="fa fa-check" aria-hidden="true"></i>
                            <div className="link-description">
                                <p>{content[language]['content-text1']}</p>
                                <input
                                    type="button"
                                    className="btn-link"
                                    onClick={() => history.push(`${commonConstant.pathShellLineConsent}`)}
                                    value={content[language]['content-text3']}
                                />
                            </div>

                        </div>
                        <div className="content-link">
                            <i class="fa fa-check" aria-hidden="true"></i>
                            <div className="link-description">
                                <p>{content[language]['content-text2']}</p>
                                <input
                                    type="button"
                                    className="btn-link"
                                    onClick={() => history.push(`${commonConstant.pathShellPrivacyConsent}`)}
                                    value={content[language]['content-text3']}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn">
                    <input
                        type="button"
                        className="accept"
                        onClick={() => this.handleSubmit()}
                        value={content[language]['button-text1']}
                    />
                    <input
                        type="button"
                        className="cancel"
                        onClick={() => liff.closeWindow()}
                        value={content[language]['button-text2']}
                    />
                </div>
            </div>

        )
    }
}

CustomerConsent.propTypes = {
    'dispatch': PropTypes.func.isRequired,
    'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, user, station }) => ({
    'language': language.data,
    'line': line.data,
    'user': user.data,
    'station': station.data,
});

export default connect(mapStateToProps)(CustomerConsent);
