export default {
    'th': {
        'header-text1': 'จองคิวตรวจเช็กสภาพรถ',
        'header-text1-1': 'และเปลี่ยนถ่ายน้ำมันเครื่องให้ลูกค้าเรียบร้อยแล้ว',
        'header-carwash-text1': 'จองคิวบริการ Shell Car Wash',
        'header-carwash-text1-1': 'ให้ลูกค้าเรียบร้อยแล้ว',
        'header-text2': 'กรุณาแสดง QR code ให้ลูกค้าสแกน ',
        'content-text1': 'เพื่อให้ลูกค้าเข้าสู่กระบวนการต่อไป',
        'content-text2': 'ลูกค้ายังไม่เข้าสู่กระบวนการ',
        'content-text3': 'กรุณาให้ลูกค้าสแกน QRcode',
        'content-text4': 'เพื่อให้ลูกค้าเข้าสู่ระบบ',
        'content-text5': 'สาขา',
        'button-text1': 'ลูกค้ากด ยืนยัน แล้ว',
        'button-text2': 'กลับสู่หน้าแชท',
    },
    'en': {
        'header-text1': 'จองคิวตรวจเช็กสภาพรถ',
        'header-text1-1': 'และเปลี่ยนถ่ายน้ำมันเครื่องให้ลูกค้าเรียบร้อยแล้ว',
        'header-carwash-text1': 'จองคิวบริการ Shell Car Wash',
        'header-carwash-text1-1': 'ให้ลูกค้าเรียบร้อยแล้ว',
        'header-text2': 'กรุณาแสดง QR code ให้ลูกค้าสแกน ',
        'content-text1': 'เพื่อให้ลูกค้าเข้าสู่กระบวนการต่อไป',
        'content-text2': 'ลูกค้ายังไม่เข้าสู่กระบวนการ',
        'content-text3': 'กรุณาให้ลูกค้าสแกน QRcode',
        'content-text4': 'เพื่อให้ลูกค้าเข้าสู่ระบบ',
        'content-text5': 'สาขา',
        'button-text1': 'ลูกค้ากด ยืนยัน แล้ว',
        'button-text2': 'กลับสู่หน้าแชท',
    },
};
