export default {
  'th': {
    'modal-text1': 'ข้อมูลการบริการ',
    'modal-text2': 'ประเภทเครื่องยนต์ของลูกค้า',
    'modal-text3': 'ผลิตภัณฑ์และบริการที่ลูกค้าได้รับ',
    'modal-text4': 'ค่าใช้จ่ายทั้งหมด',
    'modal-text5': 'ยกเลิก',
    'modal-text6': 'ยืนยัน',
    'content-text1': 'เบนซิน',
    'content-text2': 'ดีเซล',
    'content-text3': 'ผลิตภัณฑ์และบริการที่ลูกค้าได้รับ',
    'content-text4': 'เปลี่ยนถ่ายน้ำมันเครื่อง',
    'content-text5': 'โปรดเลือก...',
    'content-text6': 'เชลล์ เฮลิกส์ เพาเวอร์',
    'content-text7': 'เชลล์ เฮลิกส์ อัลตร้า',
    'content-text8': 'เชลล์ เฮลิกส์ HX8',
    'content-text9': 'เชลล์ เฮลิกส์ HX7',
    'content-text10': 'เชลล์ เฮลิกส์ HX5',
    'content-text11': 'เชลล์ เฮลิกส์ HX3',
    'content-text12': 'บริการฟลัชชิ่ง',
    'content-text13': 'เปลี่ยนถ่ายน้ำมันเกียร์/เฟืองท้าย',
    'content-text14': 'เกียร์ CVT',
    'content-text15': 'เกียร์ อัตโนมัติ',
    'content-text16': 'เกียร์ ธรรมดา',
    'content-text17': 'เปลี่ยนถ่ายน้ำมันเบรค',
    'content-text18': 'DOT 3',
    'content-text19': 'DOT 4',
    'content-text20': 'เปลี่ยนถ่ายน้ำยาหล่อเย็น/หม้อน้ำ',
    'content-text21': 'อื่นๆ',
    'content-text22': 'โปรดระบุ',
    'content-text23': 'ค่าใช้จ่ายทั้งหมด',
    'content-text24': 'บาท',
    'content-text25': 'เสร็จสิ้น',
    'content-text26': 'สาขา',
  },
  'en': {
    'modal-text1': 'ข้อมูลการบริการ',
    'modal-text2': 'ประเภทเครื่องยนต์ของลูกค้า',
    'modal-text3': 'ผลิตภัณฑ์และบริการที่ลูกค้าได้รับ',
    'modal-text4': 'ค่าใช้จ่ายทั้งหมด',
    'modal-text5': 'ยกเลิก',
    'modal-text6': 'ยืนยัน',
    'content-text1': 'เบนซิน',
    'content-text2': 'ดีเซล',
    'content-text3': 'ผลิตภัณฑ์และบริการที่ลูกค้าได้รับ',
    'content-text4': 'เปลี่ยนถ่ายน้ำมันเครื่อง',
    'content-text5': 'โปรดเลือก...',
    'content-text6': 'เชลล์ เฮลิกส์ เพาเวอร์',
    'content-text7': 'เชลล์ เฮลิกส์ อัลตร้า',
    'content-text8': 'เชลล์ เฮลิกส์ HX8',
    'content-text9': 'เชลล์ เฮลิกส์ HX7',
    'content-text10': 'เชลล์ เฮลิกส์ HX5',
    'content-text11': 'เชลล์ เฮลิกส์ HX3',
    'content-text12': 'บริการฟลัชชิ่ง',
    'content-text13': 'เปลี่ยนถ่ายน้ำมันเกียร์/เฟืองท้าย',
    'content-text14': 'เกียร์ CVT',
    'content-text15': 'เกียร์ อัตโนมัติ',
    'content-text16': 'เกียร์ ธรรมดา',
    'content-text17': 'เปลี่ยนถ่ายน้ำมันเบรค',
    'content-text18': 'DOT 3',
    'content-text19': 'DOT 4',
    'content-text20': 'เปลี่ยนถ่ายน้ำยาหล่อเย็น/หม้อน้ำ',
    'content-text21': 'อื่นๆ',
    'content-text22': 'โปรดระบุ',
    'content-text23': 'ค่าใช้จ่ายทั้งหมด',
    'content-text24': 'บาท',
    'content-text25': 'เสร็จสิ้น',
    'content-text26': 'สาขา',
  },
};
