import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';
import { changeFormatDate3 } from '../../common/commonFunction';

import {
  setLanguage,
  fetchOrder,
  setStatusOrder,
  fetchTransaction,
} from '../../actions';
import { Loading } from '../../components';

import './LubeDetail.scss';
import content from './Language';
import { history } from '../../helpers';

const liff = window.liff;

class LubeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'loading': false,
      'name': null,
      'phone': null,
      'serviceDate': null,
      'serviceTime': null,
      'branchName': null,
      'licensePlate': null,
      'brand': null,
      'model': null,
      'src': null,
      'service': null,
      'orderId': null,
      'headerConntent': null,
      'staff': 0,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    const { orderId } = this.props.match.params;
    await this.props.dispatch(fetchTransaction(orderId));
    const transaction = this.props.transaction;
    const { status, service } = transaction;
    if (status !== 'Booking') {
      return history.replace(commonConstant.pathShellLubeNot, { service, status, 'type': 'detail', orderId });
    }
    const { staff } = this.props;
    await this.props.dispatch(fetchOrder(orderId));
    const order = this.props.order[0];
    this.setState({
      'orderId': order.orderId,
      'name': order.userDetail.name,
      'phone': [
        `0${order.userDetail.phone.substring(3)}`.slice(0, 3),
        '-',
        `0${order.userDetail.phone.substring(3)}`.slice(3),
      ].join(''),
      'service': order.queueDetail.service,
      'serviceDate': changeFormatDate3(order.queueDetail.serviceDate),
      'realServiceDate': order.queueDetail.serviceDate,
      'serviceTime': order.queueDetail.serviceTime,
      'branchName': order.stationDetail.branchName,
      'licensePlate': order.licensePlate,
      'brand': order.brand,
      'model': order.model,
      'src': `${commonConstant.envDomainShellApi}/api/orders/image/${order.orderId}.jpg`,
      'staff': staff,
    });
    if (this.props.location.state) {
      const { notification } = this.props.location.state;
      this.setState({ 'headerConntent': notification });
    }
  }

  renderButton = (staff, orderId, src, language,realServiceDate) => {
    if (staff) {
      const isToday = realServiceDate.split('T')[0] === new Date().toISOString().split('T')[0];
      return (
        <React.Fragment>
          {
            !isToday && <p className="error-text">รายการจองนี้ไม่ตรงกับวันและเวลา ที่เข้ารับบริการ</p>
          }
          <div className="check-in" onClick={() => this.checkIn()}>
            {content[language]['button-text2']}
          </div>
        </React.Fragment>
      );
    }
    else {return (
      <React.Fragment>
        <div className="cancel" onClick={() => history.push(`${commonConstant.pathShellLubeCancel}/${orderId}`)}>
          <a>{content[language]['link-text1']}</a>
        </div>
        <div className="qr">
          <img id="qrcode" src={src} />
          <p>{content[language]['footer-text1']}</p>
        </div>
      </React.Fragment>
    );}
  }

  checkIn = async () => {
    const { staffId, 'lineId': staffLineId } = this.props.staff;
    const { orderId, stationId, queueId } = this.props.transaction;
    await this.props.dispatch(setStatusOrder({
      'name': 'InService',
      orderId,
      stationId,
      staffId,
      queueId,
      'staffLineId': staffLineId,
    }));
    const { status } = await this.props.statusOrder;
    switch (status) {
      case 200:
        history.replace(commonConstant.pathShellStaffLubeProcessing);
        break;
      default:
        this.setState({ 'loading': false });
    }
  }

  render() {
    const { language, order, staff } = this.props;
    const {
      name,
      phone,
      serviceDate,
      serviceTime,
      branchName,
      licensePlate,
      brand,
      model,
      src,
      service,
      orderId,
      headerConntent,
      realServiceDate,
    } = this.state;


    if (!language || !order || !service) {
      return <Loading />;
    }

    return (
      <section className="lube-detail">
        {headerConntent ?
          <div className="header">
            <img src={`${process.env.REACT_APP_HOMEPAGE}img/success.png`} alt="success" />
            <h2>{headerConntent}</h2>
          </div>
          : ''}
        <div className="box-1">
          <div className="detail">
            <div className="name">
              <p>{content[language]['content-text1']}</p>
              <p>{content[language]['content-text2']}</p>
              <p>{content[language]['content-text3']}</p>
              <p>{content[language]['content-text4']}</p>
              <p>{content[language]['content-text5']}</p>
            </div>
            <div className="value">
              <p>{name}</p>
              <a className="phone-link" href={`tel:${phone}`}>{phone}</a>
              <p>{brand}</p>
              <p>{model}</p>
              <p>{licensePlate}</p>
            </div>
          </div>
        </div>
        <div className="box-2">
          <div className="detail">
            <div className="name">
              <p>{content[language]['content-text6']}</p>
              <p>{content[language]['content-text7']}</p>
              <p>{content[language]['content-text8']}</p>
            </div>
            <div className="value">
              <p>{serviceDate}</p>
              <p>{serviceTime}</p>
              <p>{branchName}</p>
            </div>
          </div>
        </div>
        {this.renderButton(staff, orderId, src, language,realServiceDate)}
        <button className="btn" onClick={() => liff.closeWindow()}>
          {content[language]['button-text1']}
        </button>
      </section>
    );
  }
}

LubeDetail.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, order, staff, transaction, statusOrder }) => ({
  'language': language.data,
  'order': order.data,
  'staff': staff.data,
  'transaction': transaction.data,
  'statusOrder': statusOrder,
});

export default connect(mapStateToProps)(LubeDetail);
