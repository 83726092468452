import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import content from './Language';
import { setLanguage, } from '../../actions';
import commonConstant from '../../common/commonConstant';
import { getCookieToken } from '../../common/commonFunction';
import { Loading } from '../../components';
import { history } from '../../helpers';


import './CustomerConsent.scss';

const liff = window.liff;


export class PrivacyConsent extends Component {
    UNSAFE_componentWillMount() {
        if (!this.props.language) {
            return this.props.dispatch(setLanguage('th'));
        }
    }

    render() {
        const { language, line, user } = this.props;

        if (!language || !user || !line) {
            return <Loading />;
        }

        return (
            <div className="consent">
                <h1>{content[language]['header-text3']}<br />
                    {content[language]['header-text3-1']}</h1>
                <div className="consent-box-privacy">
                    <div className="details">
                        <p>{content[language]['privacy-content-text1']}</p>
                        <p>{content[language]['privacy-content-text2']}</p>
                        <p>{content[language]['privacy-content-text3']}</p>
                    </div>
                </div>
                <input
                    type="button"
                    className="back"
                    onClick={() => history.goBack()}
                    value={content[language]['button-text2']}
                />
            </div>
        )
    }
}


PrivacyConsent.propTypes = {
    'dispatch': PropTypes.func.isRequired,
    'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, user }) => ({
    'language': language.data,
    'line': line.data,
    'user': user.data,
});

export default connect(mapStateToProps)(PrivacyConsent);
