import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import content from './Language';
import '../CarwashBooking.scss';

class FormUserDetails extends Component {
  watchBtnSubmit = () => {
    const { firstName, phone, service, lastName } = this.props.formValues;
    if (firstName.value.trim() !== '' && lastName.value.trim() !== '' && phone.value.trim() !== '' && phone.value.length === 10) {
      return {
        'disabled': false,
        'btnSubmit': service === 'Carwash' ? 'yellow' : 'blue',
      };
    } else {
      return { 'disabled': true, 'btnSubmit': 'disable' };
    }
  }
  render() {
    const { language, functions } = this.props;
    const { firstName, phone, code, lastName, edit } = this.props.formValues;
    const { handleEvent, handleSubmit, handleCancel } = functions;
    const btnSubmit = this.watchBtnSubmit();

    return (
      <form className="forms">
        <div className="middle">
          <p className="content-4">{content[language]['user-header-1']}</p>
          <div className="box-input">
            {!firstName.value ? '' : <label>{content[language]['user-content-text1']}</label>}
            <input
              type="text"
              value={firstName.value}
              onChange={event => handleEvent(event, 'firstName')}
              maxLength="100"
              placeholder={content[language]['user-content-text2']}
            />
          </div>
          <div className="box-input">
            {!lastName.value ? '' : <label>{content[language]['user-content-text6']}</label>}
            <input
              type="text"
              value={lastName.value}
              onChange={event => handleEvent(event, 'lastName')}
              maxLength="100"
              placeholder={content[language]['user-content-text7']}
            />
          </div>
          <div className="box-input">
            {!phone.value ? '' : <label>{content[language]['user-content-text3']}</label>}
            <input
              type="tel"
              value={phone.value}
              onChange={event => handleEvent(event, 'phone')}
              maxLength="10"
              placeholder={content[language]['user-content-text3']}
            />
          </div>
        </div>
        {
          !edit ? <div className="btn">
            <input
              type="button"
              className={btnSubmit.btnSubmit}
              onClick={() => handleSubmit()}
              value={content[language]['button-text2']}
              disabled={btnSubmit.disabled}
            />
            <input
              type="button"
              className="cancel"
              onClick={() => handleCancel()}
              value={content[language]['button-text3']}
            />
          </div> : null
        }
      </form>
    );
  }
}

FormUserDetails.propTypes = {
  'language': PropTypes.string,
};

const mapStateToProps = ({ language }) => ({
  'language': language.data,
});

export default connect(mapStateToProps)(FormUserDetails);
