import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';


import {
  setLanguage,
  fetchUser,
  setUser,
  updateUser,
  confirmWalkInOrder,
  fetchStation,
  fetchTransaction,
  fetchStaff,
} from '../../actions';
import { Loading } from '../../components';

import './WalkInConfirm.scss';
import content from './Language';

const liff = window.liff;

class WalkInConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'orderId': null,
      'modal': false,
      'service': 'Lube'
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    if (this.props.location.state) {
      liff.getFriendship().then((data) => {
        if (!data.friendFlag) {
          liff.openWindow({
            'url': commonConstant.directLinkLineOA,
            'external': false,
          });
        }
      });
      const { stationId, orderId } = this.props.location.state;
      await this.props.dispatch(fetchTransaction(orderId));
      const { userId } = this.props.line;
      await this.props.dispatch(fetchStaff(userId));
      if (this.props.staff) { return this.setState({ 'modal': true });}
      await this.props.dispatch(fetchUser(userId));
      await this.props.dispatch(setUser(userId, stationId));
      if (this.props.user.status !== 200) {await this.props.dispatch(updateUser(userId, stationId));}
      const { status, service } = this.props.transaction;
      if (status !== 'Walk-in') {
        return history.replace(commonConstant.pathShellLubeNot, { service, status, 'type': 'Walk-in', orderId });
      }
      await this.props.dispatch(fetchUser(userId));
      await this.props.dispatch(fetchStation(stationId));
      this.setState({ orderId, 'service': service });
    }
  }

    handleSubmit = async () => {
      const { userId } = this.props.user;
      const { orderId } = this.state;

      await this.props.dispatch(confirmWalkInOrder(orderId, { userId }));
      window.location = commonConstant.directLinkLineOA;
    }

    renderModal = () => {
      const { modal } = this.state;
      const { language } = this.props;
      if (modal) {
        return (
          <div className="walk-in">
            <div className="shell-modal">
              <div className="modal-content">
                <p>{content[language]['modal-text1']}</p>
                <p>{content[language]['modal-text2']}</p>
                <button className="close" onClick={() => liff.closeWindow()}>
                  {content[language]['button-text2']}
                </button>
              </div>
            </div>
          </div>
         
        );
      }
    }

    render() {
      const { modal, service } = this.state;
      const { language, line, user, station } = this.props;

      if (modal) {
        return this.renderModal();
      }

      if (!language || !line || !user || !station || !service) {
        return <Loading />;
      }

      return (
        <section className="walk-in">
          <div className="header">
            <img src={`${process.env.REACT_APP_HOMEPAGE}img/welcome.png`} alt="logo" />
            <h1>{service === 'Carwash' ? content[language]['header-carwash-text1'] : content[language]['header-text1']}</h1>
          </div>
          <div className="content">
            <p>{content[language]['content-text1']} <b>"{content[language]['content-text2']}"</b> {content[language]['content-text3']}</p>
          </div>
          <button className="btn" onClick={() => this.handleSubmit()}>
            {content[language]['button-text1']}
          </button>
        </section>
      );
    }
}

WalkInConfirm.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, user, station, transaction, staff }) => ({
  'language': language.data,
  'line': line.data,
  'user': user.data,
  'station': station.data,
  'transaction': transaction.data,
  'staff': staff.data,
});

export default connect(mapStateToProps)(WalkInConfirm);
