export default {
  'th': {
    'date-header-1': 'วันที่ต้องการเข้ารับบริการ',
    'date-header-2': 'เวลาต้องการเข้ารับบริการ',
    'date-content-text1': 'สามารถทำการจองล่วงหน้าได้ในวันถัดไป แต่ไม่เกิน 7 วัน',
    'date-content-text2': 'เลือกวันที่',
    'car-header-1': 'ข้อมูลรถที่จะเข้ารับบริการ',
    'car-content-text1': 'ทะเบียนรถ',
    'car-content-text2': 'ระบุทะเบียนรถ',
    'car-content-text3': 'ยี่ห้อรถ',
    'car-content-text4': 'เลือกยี่ห้อรถ',
    'car-content-text5': 'โปรดระบุยี่ห้อรถ',
    'car-content-text6': 'รุ่นรถ',
    'car-content-text7': 'ระบุรุ่นรถ',
    'car-content-text8': 'ระบุยี่ห้อรถ',
    'user-header-1': 'ข้อมูลการติดต่อ',
    'user-content-text1': 'ชื่อ',
    'user-content-text2': 'ระบุชื่อ',
    'user-content-text3': 'เบอร์มือถือสำหรับการติดต่อ',
    'user-content-text4': 'โค้ดส่วนลด',
    'user-content-text5': 'โค้ดส่วนลด(ถ้ามี)',
    'user-content-text6': 'นามสกุล',
    'user-content-text7': 'ระบุนามสกุล',
    'button-text1': 'ถัดไป',
    'button-text2': 'ยืนยัน',
    'button-text3': 'ยกเลิก',
    'package-header-1': 'เลือกแพ็กเกจที่จะเข้ารับบริการ',
    'package-info-1':'ดูราคา และโปรโมชั่่น',
    'card-header-1' : 'BASIC',
    'card-header-2' : 'BASIC PLUS',
    'card-header-3' : 'WASH WAX',
    'card-header-4' : 'SHINE & PROTECT',
    'card-header-5' : 'FULL DETAILING',
    'card-header-6' : 'CRYSTAL COATING',
    'card-description-1' : 'ล้างสี ดูดฝุ่น เช็ดคอนโซล',
    'card-description-2' : 'ล้างสี ดูดฝุ่น สเปรย์แว๊กซ์ \nเช็ดภายใน',
    'card-description-3' : 'ล้างสี ดูดฝุ่น ลูบดินน้ำมัน เคลือบสี',
    'card-description-4' : 'ล้างสี ดูดฝุ่น ฟื้นฟูสี',
    'card-description-5' : 'ขัดลบรอยเต็มระบบ และทำความสะอาดภายใน',
    'card-description-6' : 'เคลือบแก้วเต็มระบบ',
    'car-content-error-text1': 'กรุณากรอกข้อมูลให้ถูกต้อง'
  },
  'en': {
    'date-header-1': 'วันที่ต้องการเข้ารับบริการ',
    'date-header-2': 'เวลาต้องการเข้ารับบริการ',
    'date-content-text1': 'สามารถทำการจองล่วงหน้าได้ในวันถัดไป แต่ไม่เกิน 7 วัน',
    'date-content-text2': 'เลือกวันที่',
    'car-header-1': 'ข้อมูลรถที่จะเข้ารับบริการ',
    'car-content-text1': 'ทะเบียนรถ',
    'car-content-text2': 'ระบุทะเบียนรถ',
    'car-content-text3': 'ยี่ห้อรถ',
    'car-content-text4': 'เลือกยี่ห้อรถ',
    'car-content-text5': 'โปรดระบุยี่ห้อรถ',
    'car-content-text6': 'รุ่นรถ',
    'car-content-text7': 'ระบุรุ่นรถ',
    'car-content-text8': 'ระบุยี่ห้อรถ',
    'user-header-1': 'ข้อมูลการติดต่อ',
    'user-content-text1': 'ชื่อ - นามสกุล',
    'user-content-text2': 'ระบุชื่อ - นามสกุล',
    'user-content-text3': 'เบอร์มือถือสำหรับการติดต่อ',
    'user-content-text4': 'โค้ดส่วนลด',
    'user-content-text5': 'โค้ดส่วนลด(ถ้ามี)',
    'button-text1': 'ถัดไป',
    'button-text2': 'ยืนยัน',
    'button-text3': 'ยกเลิก',
    'package-header-1': 'เลือกแพ็กเกจที่จะเข้ารับบริการ',
    'package-info-1':'ดูราคา และโปรโมชั่่น',
    'card-header-1' : 'BASIC',
    'card-header-2' : 'BASIC PLUS',
    'card-header-3' : 'WASH WAX',
    'card-header-4' : 'SHINE & PROTECT',
    'card-header-5' : 'FULL DETAILING',
    'card-header-6' : 'CRYSTAL COATING',
    'card-description-1' : 'ล้างสี ดูดฝุ่น เช็ดคอนโซล',
    'card-description-2' : 'ล้างสี ดูดฝุ่น สเปรย์แว๊กซ์ \nเช็ดภายใน',
    'card-description-3' : 'ล้างสี ดูดฝุ่น ลูบดินน้ำมัน เคลือบสี',
    'card-description-4' : 'ล้างสี ดูดฝุ่น ฟื้นฟูสี',
    'card-description-5' : 'ขัดลบรอยเต็มระบบ และทำความสะอาดภายใน',
    'card-description-6' : 'เคลือบแก้วเต็มระบบ',
    'car-content-error-text1': 'กรุณากรอกข้อมูลให้ถูกต้อง'
  },
};
