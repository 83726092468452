import React, { Component } from 'react';

import './Card.scss';

export default class Card extends Component {

  render() {
    const { header, description, disabled, select, icon, onclick, id } = this.props;
    return (
      <div className={select ? "container-card-select" : disabled ? "container-card-disabled" : "container-card"} onClick={ !disabled && onclick}>
        <div className="wrap-icon-card">
        <img src={icon} className="icon-card" />
        </div>
        
        <p className={disabled ? "text-card-header disabled-text": "text-card-header blue"}>{header}</p>
        <p className={disabled ? "text-card-description disabled-text": "text-card-description grey"}>{description}</p>
      </div>
    );
  }
}