import React, { Component } from 'react';
import './CustomInput.scss';
import moment from 'moment';

export default class CustomInput extends Component {
  render() {
    const { value, onClick, placeholder, language, error, dateDisabled } = this.props;
    const arr = value ? value.split('-') : null;
    return (
      <div className={error ? "custom-input-error" : (dateDisabled ? "custom-input-disabled" : "custom-input")} onClick={onClick} tabIndex="0">
        {value ? `${moment(value).format('dd DD MMM')} ${language === 'en' ? arr[0] : parseInt(arr[0]) + 543}` : placeholder}
        <img className="calendar" src={process.env.REACT_APP_HOMEPAGE + `img/calendar.png`} alt="" />
      </div >
    );
  }
}
