import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';

export const fetchUser = lineId => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/users/${lineId}?access_token=${commonConstant.cookieToken}`, {
        'method': 'GET',
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.FETCH_USER_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      // history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.FETCH_USER_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.FETCH_USER_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const setUser = (lineId, stationId) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/users?access_token=${commonConstant.cookieToken}`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          lineId,
          stationId,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.SET_USER_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      // history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.SET_USER_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.SET_USER_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const updateUser = (lineId, stationId) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/users/${lineId}?access_token=${commonConstant.cookieToken}`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'PATCH',
        'body': JSON.stringify({
          stationId,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.UPDATE_USER_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      // history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.UPDATE_USER_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.UPDATE_USER_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const acceptConsent = (lineId, stationId) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/users/consent/${lineId}/?access_token=${commonConstant.cookieToken}`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'PATCH',
        'body': JSON.stringify({
          stationId,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.ACCEPT_CONSENT_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      // history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.ACCEPT_CONSENT_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.ACCEPT_CONSENT_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const sendBookingMsg = (lineId, service) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/users/sendMsg/${lineId}?access_token=${commonConstant.cookieToken}`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          service,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.SEND_BOOKING_MESSAGE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      // history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.SEND_BOOKING_MESSAGE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError)
      return dispatch({
        'type': commonConstant.SEND_BOOKING_MESSAGE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const updateCancelNum = (userId) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/users/cancel-num?access_token=${commonConstant.cookieToken}`, {
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        'method': 'POST',
        'body': JSON.stringify(userId),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.UPDATE_CANCEL_NUM_SUCCESS,
          'data': data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      return dispatch({
        'type': commonConstant.UPDATE_CANCEL_NUM_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'message': data.error.message,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.UPDATE_CANCEL_NUM_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
