import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';
import './WalkInDetail.scss';
import content from './Language';

import {
    setLanguage,
    fetchOrder,
    fetchTransaction,
} from '../../actions';

import { Loading } from '../../components';
import { history } from '../../helpers';

const liff = window.liff;

export class WalkInDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'loading': false,
            'src': null,
            'service': null,
            'orderId': null,
            'modal': false
        };
    }

    UNSAFE_componentWillMount() {
        if (!this.props.language) {
            return this.props.dispatch(setLanguage('th'));
        }
    }

    async componentDidMount() {
        const { orderId } = this.props.match.params;
        const { service } = this.props.location.state;
        await this.props.dispatch(fetchOrder(orderId));
        await this.props.dispatch(fetchTransaction(orderId));
        this.setState({
            'orderId': orderId,
            'service': service,
            'src': `${commonConstant.envDomainShellApi}/api/orders/image/walk-in-${orderId}.jpg`,
        });
    }

    renderModal = () => {
        const { modal } = this.state;
        const { language } = this.props;
        if (modal) {
            return (
                <div className="modal">
                    <div className="modal-box">
                        <img src={process.env.REACT_APP_HOMEPAGE + `img/danger.svg`} alt="danger" />
                        <p className="modal-header">{content[language]['content-text2']}</p>
                        <p className="content">{content[language]['content-text3']}</p>
                        <p className="content">{content[language]['content-text4']}</p>
                        <button className="modal-button" onClick={() => this.setState({ 'modal': false })}>ยืนยัน</button>
                    </div>
                </div>
            );
        }
    }

    checkUserScan = async () => {
        const { orderId } = this.state;
        await this.props.dispatch(fetchTransaction(orderId));
        const { transaction } = this.props;
        const { status } = transaction;
        if (status === 'Walk-in') {
            this.setState({ 'modal': true });
        }
        else {
            history.replace(commonConstant.pathShellStaffLubeProcessing);
        }
    }

    render() {
        const { language, order, transaction } = this.props;
        const { service, src } = this.state;

        if (!language || !order || !service) {
            return <Loading />;
        }
        return (
            <div className="walkIn-detail">
                {this.renderModal()}
                <div className="header">
                    <div className="logo">
                        <img src={`/lineoa/img/staff-logo.svg`} alt="logo" />
                    </div>
                    <p className>{content[language]['content-text5']} {transaction.branchName}</p>
                </div>
                <div className="content">
                    <div className="check">
                        <img src={process.env.REACT_APP_HOMEPAGE + `img/check.svg`} alt="check" />
                        <h2>{service === 'Carwash' ? content[language]['header-carwash-text1'] : content[language]['header-text1']}</h2>
                        <h2>{service === 'Carwash' ? content[language]['header-carwash-text1-1']: content[language]['header-text1-1']}</h2>
                    </div>
                    <div className="qr">
                        <h1>{content[language]['header-text2']}</h1>
                        <p>{content[language]['content-text1']}</p>
                        <img src={src} alt="QRCode" />
                    </div>
                </div>
                <button className="submit" onClick={() => this.checkUserScan()}>{content[language]['button-text1']}</button>
                <button className="close" onClick={() => liff.closeWindow()}>{content[language]['button-text2']}</button>
            </div>
        )
    }
}

WalkInDetail.propTypes = {
    'dispatch': PropTypes.func.isRequired,
    'language': PropTypes.string,
};

const mapStateToProps = ({ language, order, transaction }) => ({
    'language': language.data,
    'order': order.data,
    'transaction': transaction.data
});

export default connect(mapStateToProps)(WalkInDetail);
