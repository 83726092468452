export default {
  'th': {
    'header-text1': 'รายละเอียดลูกค้า',
    'content-text1': 'ชื่อ - นามสกุล',
    'content-text2': 'เบอร์โทรติดต่อ',
    'content-text3': 'วันที่',
    'content-text4': 'เวลา',
    'content-text5': 'สถานีบริการ',
    'content-text6': 'ทะเบียนรถ',
    'content-text7': 'ยี่ห้อรถ',
    'content-text8': 'รุ่นรถ',
    'content-text9': 'เวลารับรถ',
    'content-text10': 'คิวถูกยกเลิกโดยพนักงาน',
    'content-text11': 'คิวถูกยกเลิกโดยลูกค้า',
    'content-text12': 'คิวถูกยกเลิกโดยระบบ',
    'content-text13': 'เนื่องจาก',
    'content-text14': 'ยืนยัน ให้ลูกค้าเข้าสู่ขั้นตอนการดำเนินการ',
    'content-text15': 'ข้อมูลการบริการ',
    'content-text16': 'ประเภทเครื่องยนต์ของลูกค้า',
    'content-text17': 'ผลิตภัณฑ์และบริการที่ลูกค้าได้รับ',
    'content-text18': 'สาขา',
    'content-text19': 'ชื่อ',
    'content-text20': 'นามสกุล',
    'content-text21': 'แพ็กเก็จ',
    'button-text1': 'เริ่มเข้ารับบริการ',
    'button-text2': 'แก้ไขข้อมูล',
    'button-text3': 'ยกเลิกการจอง',
    'button-text4': 'ยกเลิก',
    'button-text5': 'ยืนยัน',
    'button-text6': 'ปิด',
    'button-text7': 'กลับ',
    'modal-text1': 'เหตุผลในการยกเลิกการจอง',
    'modal-text2': 'ลูกค้ามาช้ากว่าเวลาที่จองไว้เกิน 30 นาที',
    'modal-text3': 'ลูกค้าแจ้งยกเลิกกับเจ้าหน้าที่',
    'modal-text4': 'อื่นๆ',
    'modal-text5': ' (กรุณาระบุ)',
    'noti-text1': 'ยกเลิกการจองเรียบร้อยแล้ว',
    'noti-text2': 'คุณได้ทำการยกเลิกการนัดคิวเรียบร้อยแล้ว',
  },
  'en': {
    'header-text1': 'รายละเอียดลูกค้า',
    'content-text1': 'ชื่อ - นามสกุล',
    'content-text2': 'เบอร์โทรติดต่อ',
    'content-text3': 'วันที่',
    'content-text4': 'เวลา',
    'content-text5': 'สถานีบริการ',
    'content-text6': 'ทะเบียนรถ',
    'content-text7': 'ยี่ห้อรถ',
    'content-text8': 'รุ่นรถ',
    'content-text9': 'เวลารับรถ',
    'content-text10': 'คิวถูกยกเลิกโดยพนักงาน',
    'content-text11': 'คิวถูกยกเลิกโดยลูกค้า',
    'content-text12': 'คิวถูกยกโดยเลิกระบบ',
    'content-text13': 'เนื่องจาก',
    'content-text14': 'ยืนยัน ให้ลูกค้าเข้าสู่ขั้นตอนการดำเนินการ',
    'content-text15': 'ข้อมูลการบริการ',
    'content-text16': 'ประเภทเครื่องยนต์ของลูกค้า',
    'content-text17': 'ผลิตภัณฑ์และบริการที่ลูกค้าได้รับ',
    'content-text18': 'สาขา',
    'content-text19': 'ชื่อ',
    'content-text20': 'นามสกุล',
    'content-text21': 'แพ็กเก็จ',
    'button-text1': 'เริ่มเข้ารับบริการ',
    'button-text2': 'แก้ไขข้อมูล',
    'button-text3': 'ยกเลิกการจอง',
    'button-text4': 'ยกเลิก',
    'button-text5': 'ยืนยัน',
    'button-text6': 'ปิด',
    'button-text7': 'กลับ',
    'modal-text1': 'เหตุผลในการยกเลิกการจอง',
    'modal-text2': 'ลูกค้ามาช้ากว่าเวลาที่จองไว้เกิน 30 นาที',
    'modal-text3': 'ลูกค้าแจ้งยกเลิกกับเจ้าหน้าที่',
    'modal-text4': 'อื่นๆ',
    'modal-text5': ' (กรุณาระบุ)',
    'noti-text1': 'ยกเลิกการจองเรียบร้อยแล้ว',
    'noti-text2': 'คุณได้ทำการยกเลิกการนัดคิวเรียบร้อยแล้ว',
  },
};
