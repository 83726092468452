import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';

import {
  setLanguage,
  fetchTransaction,
  fetchPromotion,
  setStatusOrder,
  updateCancelNum,
  fetchUser,
  fetchStaff,
} from '../../actions';
import { Loading } from '../../components';

import './LubeCancel.scss';
import content from './Language';
import { history } from '../../helpers';

const liff = window.liff;

class LubeCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'loading': false,
      'step': 1,
      'radios': '',
      'etcNote': '',
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    const { orderId } = this.props.match.params;
    const { line } = this.props;
    await this.props.dispatch(fetchUser(line.userId));
    await this.props.dispatch(fetchStaff(line.userId));
    await this.props.dispatch(fetchTransaction(orderId));
    const { status, service, stationId } = this.props.transaction;
    await this.props.dispatch(fetchPromotion(stationId, line.userId));
    this.setState({ 'service': service });
    if (status !== 'Booking')
    {return history.replace(commonConstant.pathShellLubeNot, {
      service,
      status,
      'type': 'cancel',
      orderId,
    });}
  }

  handleRadio = (event) =>
    this.setState({ 'radios': event.target.value, 'etcNote': '' });

  submitInput = async () => {
    this.setState({ 'loading': true });
    const { language, user, transaction, staff } = this.props;
    const { service, queueId, orderId, userId } = transaction;
    if (user.cancelNum === 0)
    {return history.replace(commonConstant.pathShellLubeNot, {
      service,
      'status': 'cancel-over',
      'type': 'cancel-over',
      orderId,
    });}
    const { radios, etcNote } = this.state;
    const note =
      radios === content[language]['radio-text3']
        ? `${radios} (${etcNote})`
        : radios === content[language]['radio-text4']
          ? `${radios} (${etcNote})`
          : radios;
    const data = {
      'name': 'Cancel',
      orderId,
      queueId,
      note,
    };
    await this.props.dispatch(setStatusOrder(data));
    if (!staff) {await this.props.dispatch(updateCancelNum({ userId }));}
    const { status } = this.props.statusOrder;
    switch (status) {
      case 200:
        this.setState({ 'loading': false, 'step': 3 });
        break;
      default:
        alert('ขออภัยระบบขัดข้อง');
        this.setState({ 'loading': false });
    }
  };

  watchBtnSubmit = () => {
    const { radios, etcNote, service } = this.state;
    const { language } = this.props;
    return (radios === content[language]['radio-text3'] && !etcNote) || !radios
      ? { 'disabled': true, 'btnSubmit': 'disable' }
      : { 'disabled': false, 'btnSubmit': service === 'Lube' ? 'yellow' : 'blue' };
  };

  renderThirdPage = () => {
    const { language, transaction } = this.props;
    const { service } = this.state;
    return (
      <section className="lube-cancel-step-3">
        <div className={service === 'Lube' ? 'header' : 'header blue'}>
          <div className="logo">
            <img
              src={`${process.env.REACT_APP_HOMEPAGE}img/Logo.png`}
              alt="logo"
            />
          </div>
          <p className>
            {content[language]['content-text3']} {transaction.branchName}
          </p>
        </div>
        <div className="content">
          <h1>
            {content[language]['step3-text1']}
            <span>{content[language]['step3-text2']}</span>
          </h1>
          <h2>
            {content[language]['step3-text3']}
            <br />
            {content[language]['step3-text4']}
          </h2>
        </div>
        <div className="btn">
          <input
            type="button"
            className="yellow"
            onClick={() => liff.closeWindow()}
            value="กลับสู่หน้าแชท"
          />
        </div>
      </section>
    );
  };

  renderSecondaryPage = () => {
    const { language, transaction } = this.props;
    const { service } = transaction;
    const { radios, etcNote } = this.state;
    const btnSubmit = this.watchBtnSubmit();
    return (
      <section className="lube-cancel-step-2">
        <div className={service === 'Lube' ? 'header' : 'header blue'}>
          <div className="logo">
            <img
              src={`${process.env.REACT_APP_HOMEPAGE}img/Logo.png`}
              alt="logo"
            />
          </div>
          <p className>
            {content[language]['content-text3']} {transaction.branchName}
          </p>
        </div>
        <div className="content">
          <p>{content[language]['step2-text1']}</p>
          <p>
            กรุณา<span>{content[language]['step2-text2']}</span>ในคร้ังนี้
          </p>
          <p>{content[language]['step2-text3']}</p>
        </div>
        <form>
          <label className="radio-form">
            <input
              type="radio"
              onChange={(event) => this.handleRadio(event)}
              checked={radios === content[language]['radio-text1']}
              value={content[language]['radio-text1']}
            />
            {content[language]['radio-text1']}
          </label>
          <br />
          <label className="radio-form">
            <input
              type="radio"
              onChange={(event) => this.handleRadio(event)}
              checked={radios === content[language]['radio-text2']}
              value={content[language]['radio-text2']}
            />
            {content[language]['radio-text2']}
          </label>
          <br />
          <label className="radio-form">
            <input
              type="radio"
              onChange={(event) => this.handleRadio(event)}
              checked={radios === content[language]['radio-text3']}
              value={content[language]['radio-text3']}
            />
            {content[language]['radio-text3']}
          </label>
          {radios === content[language]['radio-text3'] ? (
            <div className="box-input">
              <input
                type="text"
                value={etcNote}
                onChange={(event) =>
                  this.setState({ 'etcNote': event.target.value })
                }
                placeholder={content[language]['content-text1']}
              />
            </div>
          ) : null}
          <br />
          <label className="radio-form">
            <input
              type="radio"
              onChange={(event) => this.handleRadio(event)}
              checked={radios === content[language]['radio-text4']}
              value={content[language]['radio-text4']}
            />
            {content[language]['radio-text4']}
          </label>
          {radios === content[language]['radio-text4'] ? (
            <div className="box-input">
              <input
                type="text"
                value={etcNote}
                onChange={(event) =>
                  this.setState({ 'etcNote': event.target.value })
                }
                placeholder={content[language]['content-text2']}
              />
            </div>
          ) : null}
        </form>
        <div className="btn">
          <input
            type="button"
            className={btnSubmit.btnSubmit}
            onClick={() => this.submitInput()}
            value={content[language]['button-text1']}
            disabled={btnSubmit.disabled}
          />
        </div>
      </section>
    );
  };

  renderPromotion = (filterPromotion) => {
    if (filterPromotion && filterPromotion.cancelMessage === true) {
      const { promotionId, isImage, 'content': contentText, link } = filterPromotion;
      const src = `${commonConstant.envDomainShellApi}/api/promotions/image/${promotionId}.jpg`;
      return (
        <div className="promotion">
          {!isImage ? (
            <div className="promotion-text">
              <p>{contentText}</p>
              {
                link && <a href={link} className="link">{link}</a>
              }
            </div>
          ) : (
            <a href={link || '#'}><img className="promotion-image" src={src} alt="promotion" /></a>
          )}
        </div>
      );
    } 
  }

  renderFirstPage = () => {
    const { language, transaction, promotion } = this.props;
    const filterPromotion = promotion.data.find(ele => ele.cancelMessage === true && ele.service === "Lube")
    const { service } = transaction;
    return (
      <section className="lube-cancel-step-1">
        <div className={service === 'Lube' ? 'header' : 'header blue'}>
          <div className="logo">
            <img
              src={`${process.env.REACT_APP_HOMEPAGE}img/Logo.png`}
              alt="logo"
            />
          </div>
          <p className>
            {content[language]['content-text3']} {transaction.branchName}
          </p>
        </div>
        {filterPromotion && this.renderPromotion(filterPromotion)}
        <div className="content">
          <label>
            {content[language]['step1-text1']}
            <br />
            <span>{content[language]['step1-text2']}</span>
            <br />
            {content[language]['step1-text3']}
          </label>
        </div>
        <footer>
          <div className="center">
            <div className="btn">
              <button
                className={service === 'Lube' ? 'yellow' : 'blue'}
                onClick={() => liff.closeWindow()}
              >
                {content[language]['button-text2']}
              </button>
            </div>
            <div className="btn">
              <button
                className="white"
                onClick={() => this.setState({ 'step': 2 })}
              >
                {content[language]['button-text3']}
              </button>
            </div>
          </div>
        </footer>
      </section>
    );
  };

  renderStepPage = () => {
    const { step } = this.state;
    switch (step) {
      case 3:
        return this.renderThirdPage();
      case 2:
        return this.renderSecondaryPage();
      default:
        return this.renderFirstPage();
    }
  };

  render() {
    const { language, transaction, line, promotion } = this.props;

    console.log({ language, transaction, promotion, line });

    if (!language || !transaction || promotion.status !== 200 || !line) {
      return <Loading />;
    }

    return <main className="lube-cancel">{this.renderStepPage()}</main>;
  }
}

LubeCancel.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({
  language,
  transaction,
  promotion,
  statusOrder,
  line,
  user,
  staff,
}) => ({
  'language': language.data,
  'transaction': transaction.data,
  'promotion': promotion,
  'statusOrder': statusOrder,
  'line': line.data,
  'user': user.data,
  'staff': staff.data,
});

export default connect(mapStateToProps)(LubeCancel);
