import { combineReducers } from 'redux';
import { language } from './Language';
import { line } from './Line';
import { user } from './Users';
import { queues } from './Queues';
import { order, orderWeekly, cancelOrderWeekly, orderToday, orderYesterday, orderStaff } from './Orders';
import { aheadCancelOrder } from './AheadCancelOrder';
import { prevCancelOrder } from './PrevCancelOrder';
import { transaction } from './Transactions';
import { recentTransaction } from './RecentTransaction';
import { promotion } from './Promotions';
import { statusOrder, statusOrderCallCustomer } from './StatusOrders';
import { queueEdit } from './QueueEdits';
import { station } from './Stations';
import { staff } from './Staffs';

export default combineReducers({
  // language
  language,
  // line
  line,
  // users
  user,
  // queues
  queues,
  // orders
  order,
  orderWeekly,
  cancelOrderWeekly,
  orderToday,
  orderYesterday,
  orderStaff,
  //aheadCancelOrder
  aheadCancelOrder,
  //prevCancelOrder
  prevCancelOrder,
  // transactions
  transaction,
  // recentTransaction
  recentTransaction,
  // promotions
  promotion,
  // statusOrder
  statusOrder,
  statusOrderCallCustomer,
  // queueEdit
  queueEdit,
  // stations
  station,
  // staffs
  staff,
});
