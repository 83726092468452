import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';

import {
  setLanguage,
  fetchTransaction,
  setOrderStaff
} from '../../actions';
import { Loading } from '../../components';

import './StaffLubeProcessingCompleted.scss';
import content from './Language';
import { history } from '../../helpers';

class StaffLubeProcessingCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'service': null,
      'completed': null,
      'vehicleType': 'เบนซิน',
      'productType': this.defaultData([]),
      'totalSpend': this.defaultData(),
      'textETC': this.defaultData(),
      'selected1': this.defaultData(),
      'selected2': this.defaultData(),
      'selected3': this.defaultData(),
      'modal': false,
      'loading': false
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    const { orderId } = this.props.match.params;
    await this.props.dispatch(fetchTransaction(orderId));
    if (this.props.transaction) {
      const { service, status } = this.props.transaction;
      this.setState({ 'service': service, completed: status === 'InService' ? true : false });
    }
  }

  defaultData = (val = '', error = null) => ({ 'value': val, 'error': error });

  handleEvent = async (event, key) => {
    const { language } = this.props;
    let newValue = this.state[key];
    let val = null;
    switch (key) {
      case 'productType':
        val = this.state.productType.value;
        const tmp = event.target.value;
        if (val.indexOf(tmp) > -1) {
          val.pop(tmp)
          if (tmp.includes(content[language]['content-text4']))
            this.setState({ 'selected1': '' });
          else if (tmp.includes(content[language]['content-text13']))
            this.setState({ 'selected1': '' });
          else if (tmp.includes(content[language]['content-text17']))
            this.setState({ 'selected1': '' });
        } else val.push(tmp);
        newValue = { 'value': val, 'error': null };
        break;
      case 'totalSpend':
        val = this.totalSpendHendle(event.target.value);
        newValue = { 'value': val, 'error': null };
        break;
      default:
        val = event.target.value
        newValue = { 'value': val, 'error': null };
    }
    await this.setState({ [key]: newValue });
  };

  totalSpendHendle = (val) => {
    const formatNumber = (n) => {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    let input_val = val;
    if (input_val === "") {
      return;
    }
    if (input_val.indexOf(".") >= 0) {
      let decimal_pos = input_val.indexOf(".");
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos);
      left_side = formatNumber(left_side);
      right_side = formatNumber(right_side);
      right_side = right_side.substring(0, 2);
      input_val = left_side + "." + right_side;
    } else {
      input_val = formatNumber(input_val);
    }
    return input_val;
  }

  watchBtnSubmit = () => {
    const { language } = this.props;
    const {
      service,
      vehicleType,
      productType,
      totalSpend,
      textETC,
      selected1,
      selected2,
      selected3,
    } = this.state;
    if (
      vehicleType.value !== "" &&
      totalSpend.value !== "" &&
      productType.value.length > 0
    ) {
      if (
        (productType.value.includes(content[language]['content-text4']) &&
          selected1.value === "") ||
        (productType.value.includes(content[language]['content-text13']) &&
          selected2.value === "") ||
        (productType.value.includes(content[language]['content-text17']) &&
          selected3.value === "") ||
        (productType.value.includes(content[language]['content-text21']) && textETC === "")
      ) {
        return { disabled: true, btnSubmit: 'disable' }
      } else {
        return {
          disabled: false,
          btnSubmit: service === 'Lube' ? 'yellow' : 'blue'
        }
      }
    } else {
      return { disabled: true, btnSubmit: 'disable' }
    }
  }

  submitInput = async () => {
    const { orderId } = this.props.match.params;
    const {
      service,
      vehicleType,
      totalSpend,
    } = this.state;
    const data = {
      vehicleType: vehicleType,
      totalSpend: totalSpend.value.split(",").join(""),
      productType: this.getArrProductType(),
    };
    this.setState({ 'loading': true });
    await this.props.dispatch(setOrderStaff(orderId, data));
    const { status } = this.props.orderStaff;
    switch (status) {
      case 200:
        history.replace(commonConstant.pathShellStaffLubeProcessing, { notification: 'ลูกค้ารับบริการสร็จสิ้นแล้ว' });
        break;
      default:
        this.setState({ 'loading': false });
    }
  }

  getArrProductType = () => {
    const { language } = this.props;
    const {
      productType,
      selected1,
      selected2,
      selected3,
      textETC,
    } = this.state;
    return productType.value.map(element => ({
      name: element,
      note:
        element === content[language]['content-text4']
          ? selected1.value
          : element === content[language]['content-text13']
            ? selected2.value
            : element === content[language]['content-text17']
              ? selected3.value
              : element === content[language]['content-text21']
                ? textETC.value
                : null
    }));
  }

  renderLoading = () => {
    const { loading } = this.state;
    if (loading) return <Loading />
  }

  renderModal = () => {
    const { language } = this.props;
    const {
      service,
      modal,
      vehicleType,
      totalSpend
    } = this.state;
    if (modal) {
      const arr = this.getArrProductType();
      return (
        <div className="shell-modal">
          <div className="modal-content">
            <p>{content[language]['modal-text1']}</p>
            <div className="content">
              <div className="box">
                <label className="header">{content[language]['modal-text2']}</label>
                <label className="body">{vehicleType}</label>
              </div>
              <div className="box">
                <div className="header">{content[language]['modal-text3']}</div>
                <div className="body">
                  <ul>
                    {
                      arr.map((v, i) => (
                        <li key={i}>
                          <label>{v.name}</label>
                          <p>{v.note ? v.note : ""}</p>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div className="box">
                <label className="header">{content[language]['modal-text4']}</label>
                <label className="body">{totalSpend.value}</label>
              </div>
            </div>
            <div className="footer">
              <button className="cancel" onClick={() => this.setState({ 'modal': false })}>
                {content[language]['modal-text5']}
              </button>
              <button className={service === 'Lube' ? 'yellow' : 'blue'} onClick={() => this.submitInput()}>{content[language]['modal-text6']}</button>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const { language, line, transaction } = this.props;
    const {
      service,
      vehicleType,
      productType,
      totalSpend,
      textETC
    } = this.state;

    if (!language || !line || !transaction || !service) {
      return <Loading />;
    }

    const btnSubmit = this.watchBtnSubmit();

    return (
      <main className="staff-lube-processing-completed">
        {this.renderLoading()}
        {this.renderModal()}
        <div className={service === 'Lube' ? 'header' : 'header blue'}>
          <div className="logo">
            <img src={`/lineoa/img/staff-logo.svg`} alt="logo" />
          </div>
          <p className>{content[language]['content-text26']} {transaction.branchName}</p>
        </div>
        <div className="content">
          <p>{content[language]['modal-text2']}</p>
          <div className="row">
            <button
              className={vehicleType === content[language]['content-text1'] ? service === 'Lube' ? 'select yellow' : 'select blue' : ''}
              onClick={() => this.setState({ 'vehicleType': content[language]['content-text1'] })}
            >
              {content[language]['content-text1']}
            </button>
            <button
              className={vehicleType === content[language]['content-text2'] ? service === 'Lube' ? 'select yellow' : 'select blue' : ''}
              onClick={() => this.setState({ 'vehicleType': content[language]['content-text2'] })}
            >
              {content[language]['content-text2']}
            </button>
          </div>
        </div>
        <div className="grey-backgroud">
          <p>{content[language]['content-text3']}</p>
          <label className="container-checkbox"
          >{content[language]['content-text4']}
            <input
              type="checkbox"
              value={content[language]['content-text4']}
              onChange={event => this.handleEvent(event, 'productType')}
            />
            <span className={service === 'Lube' ? 'checkmark yellow' : 'checkmark blue'}></span>
          </label>
          {
            productType.value.includes(content[language]['content-text4']) ? (
              <div
                className="select-box"
              >
                <select onChange={event => this.handleEvent(event, 'selected1')}>
                  <option value="" hidden>{content[language]['content-text5']}</option>
                  <option value={content[language]['content-text6']}>{content[language]['content-text6']}</option>
                  <option value={content[language]['content-text7']}>{content[language]['content-text7']}</option>
                  <option value={content[language]['content-text8']}>{content[language]['content-text8']}</option>
                  <option value={content[language]['content-text9']}>{content[language]['content-text9']}</option>
                  <option value={content[language]['content-text10']}>{content[language]['content-text10']}</option>
                  <option value={content[language]['content-text11']}>{content[language]['content-text11']}</option>
                </select>
              </div>
            ) : null
          }
          <label className="container-checkbox"
          >{content[language]['content-text12']}
            <input type="checkbox" value={content[language]['content-text12']} onChange={event => this.handleEvent(event, 'productType')} />
            <span className={service === 'Lube' ? 'checkmark yellow' : 'checkmark blue'}></span>
          </label>
          <label className="container-checkbox"
          >{content[language]['content-text13']}
            <input
              type="checkbox"
              value={content[language]['content-text13']}
              onChange={event => this.handleEvent(event, 'productType')}
            />
            <span className={service === 'Lube' ? 'checkmark yellow' : 'checkmark blue'}></span>
          </label>
          {
            productType.value.includes(content[language]['content-text13']) ? (
              <div
                className="select-box"
                id="checked3"
              >
                <select onChange={event => this.handleEvent(event, 'selected2')}>
                  <option value="" hidden>{content[language]['content-text5']}</option>
                  <option value={content[language]['content-text14']}>{content[language]['content-text14']}</option>
                  <option value={content[language]['content-text15']}>{content[language]['content-text15']}</option>
                  <option value={content[language]['content-text16']}>{content[language]['content-text16']}</option>
                </select>
              </div>
            ) : null
          }
          <label className="container-checkbox"
          >{content[language]['content-text17']}
            <input
              type="checkbox"
              value={content[language]['content-text17']}
              onChange={event => this.handleEvent(event, 'productType')}
            />
            <span className={service === 'Lube' ? 'checkmark yellow' : 'checkmark blue'}></span>
          </label>
          {
            productType.value.includes(content[language]['content-text17']) ? (
              <div
                className="select-box"
                id="checked4"
              >
                <select onChange={event => this.handleEvent(event, 'selected3')}>
                  <option value="">{content[language]['content-text5']}</option>
                  <option value={content[language]['content-text18']}>{content[language]['content-text18']}</option>
                  <option value={content[language]['content-text19']}>{content[language]['content-text19']}</option>
                </select>
              </div>
            ) : null
          }
          <label className="container-checkbox"
          >{content[language]['content-text20']}
            <input
              type="checkbox"
              value={content[language]['content-text20']}
              onChange={event => this.handleEvent(event, 'productType')}
            />
            <span className={service === 'Lube' ? 'checkmark yellow' : 'checkmark blue'}></span>
          </label>
          <label className="container-checkbox"
          >{content[language]['content-text21']}
            <input type="checkbox" value={content[language]['content-text21']} onChange={event => this.handleEvent(event, 'productType')} />
            <span className={service === 'Lube' ? 'checkmark yellow' : 'checkmark blue'}></span>
          </label>
          {
            productType.value.includes(content[language]['content-text21']) ? (
              <div className="box-input">
                <label>{content[language]['content-text22']}</label>
                <input type="text" maxLength="100" value={textETC.value} onChange={event => this.handleEvent(event, 'textETC')} />
              </div>
            ) : null
          }
        </div>
        <div className="price-box">
          {totalSpend.value ? <p className="price">{content[language]['content-text23']}</p> : ""}
          <div className="input-container">
            <input
              type="tel"
              value={totalSpend.value}
              onChange={event => this.handleEvent(event, 'totalSpend')}
              maxLength="10"
              placeholder="ค่าใช้จ่ายทั้งหมด (บาท)"
            />
          </div>
        </div>
        <div className="btn">
          <button className={btnSubmit.btnSubmit} onClick={() => this.setState({ 'modal': true })} disabled={btnSubmit.disabled}>
            {content[language]['content-text25']}
          </button>
        </div>
      </main>
    );
  }
}

StaffLubeProcessingCompleted.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, transaction, orderStaff }) => ({
  'language': language.data,
  'line': line.data,
  'transaction': transaction.data,
  'orderStaff': orderStaff
});

export default connect(mapStateToProps)(StaffLubeProcessingCompleted);
