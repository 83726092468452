import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from '../../../components';
import { ShellSelect } from '../../../helpers';
import content from './Language';
import '../CarwashBooking.scss';

class FormSelectPackage extends Component {
  watchBtnSubmit = () => {
    const {
      serviceDate,
      serviceTime,
      service,
      servicePackage
    } = this.props.formValues;
    if (servicePackage.value !== '') {
      return {
        'disabled': false,
        'btnSubmit': 'yellow',
      };
    } else {
      return { 'disabled': true, 'btnSubmit': 'disable' };
    }
  }

  render() {
    const { language, nextStep, functions } = this.props;
    const { serviceTime, serviceDate, edit, walkIn, staff, servicePackage } = this.props.formValues;
    const { renderServiceTimes, handleEvent, handleCancel, onSelectPackage, isDisabledSelectPackage , onSwitchModalInfo} = functions;
    const btnSubmit = this.watchBtnSubmit();
    return (
      <form className="forms">
        <div className="middle">
        <div className="form-container" >
            <p className="content-1">{content[language]['package-header-1']}</p>
            <div className="description-info" onClick={() => onSwitchModalInfo()}>
            <div className="wrap-icon-info">
            <img src={'/lineoa/img/info-icon.svg'} className="icon-info"/>
            </div>
            <p className="info-1">{content[language]['package-info-1']}</p>
            </div>
          
            </div>
            <div className="card-container">
            <div className="wrap-card">
            <Card 
            icon="/lineoa/img/carwash-basic-icon.svg"
            header={content[language]['card-header-1']}
            description={content[language]['card-description-1']}
            disabled={isDisabledSelectPackage('Basic')}
            select={servicePackage.value === 'Basic'}
            onclick={() => onSelectPackage('Basic')}
            />
            <Card 
            icon="/lineoa/img/carwash-basic-plus-icon.svg"
            header={content[language]['card-header-2']}
            description={content[language]['card-description-2']}
            disabled={isDisabledSelectPackage('Basic-Plus')}
            select={servicePackage.value === 'Basic-Plus'}
            onclick={() => onSelectPackage('Basic-Plus')}
            />
            </div>
            <div className="wrap-card">
            <Card 
            icon="/lineoa/img/carwash-wash-wax-icon.svg"
            header={content[language]['card-header-3']}
            description={content[language]['card-description-3']}
            disabled={isDisabledSelectPackage('Wash-Wax')}
            select={servicePackage.value === 'Wash-Wax'}
            onclick={() => onSelectPackage('Wash-Wax')}
            />
            <Card 
            icon="/lineoa/img/carwash-shine-protect-icon.svg"
            header={content[language]['card-header-4']}
            description={content[language]['card-description-4']}
            disabled={isDisabledSelectPackage('Shine-Protect')}
            select={servicePackage.value === 'Shine-Protect'}
            onclick={() => onSelectPackage('Shine-Protect')}
            />
            </div>
            <div className="wrap-card">
            <Card 
            icon="/lineoa/img/carwash-full-detail-icon.svg"
            header={content[language]['card-header-5']}
            description={content[language]['card-description-5']}
            disabled={isDisabledSelectPackage('Full-Detailing')}
            select={servicePackage.value === 'Full-Detailing'}
            onclick={() => onSelectPackage('Full-Detailing')}
            />
            <Card 
            icon="/lineoa/img/carwash-crystal-icon.svg"
            header={content[language]['card-header-6']}
            description={content[language]['card-description-6']}
            disabled={isDisabledSelectPackage('Crystal-Coating')}
            select={servicePackage.value === 'Crystal-Coating'}
            onclick={() => onSelectPackage('Crystal-Coating')}
            />
            </div>
            </div>
        </div>
        {edit ? '' :
          <div className="btn">
            <input
              type="button"
              className={btnSubmit.btnSubmit}
              onClick={() => nextStep()}
              value={content[language]['button-text1']}
              disabled={btnSubmit.disabled}
            />
            <input
              type="button"
              className="cancel"
              onClick={() => handleCancel()}
              value={content[language]['button-text3']}
            />
          </div>}
      </form>

    );
  }
}

FormSelectPackage.propTypes = {
  'language': PropTypes.string,
};

const mapStateToProps = ({ language }) => ({
  'language': language.data,
});

export default connect(mapStateToProps)(FormSelectPackage);
