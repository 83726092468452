import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';

import {
  setLanguage,
  fetchUser,
  setUser,
  updateUser,
  fetchStation,
  fetchStaff
} from '../../actions';
import { Loading } from '../../components';

import './Customer.scss';
import content from './Language';

const liff = window.liff;

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'modal': false,
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
      liff.getFriendship().then(async (data) => {
        if (!data.friendFlag) {
          liff.openWindow({
            url: commonConstant.directLinkLineOA,
            external: false
          });
        } 
      });
    // const { stationId } = this.props.location.state;
    // const stationId = `0ec739e0-4644-4d52-8c21-40d3e46173f2`
    const stationId = commonConstant.stationId
    const { userId } = this.props.line;
    await this.props.dispatch(fetchStaff(userId));
    await this.props.dispatch(fetchStation(stationId));
    if (this.props.staff) {
      this.setState({ modal: true });
    } else {
      await this.props.dispatch(setUser(userId, stationId));
      await this.props.dispatch(fetchUser(userId))
    }
  }

  handleSubmit = async () => {
    // const { stationId } = this.props.location.state;
    // const stationId = `0ec739e0-4644-4d52-8c21-40d3e46173f2`
    const stationId = commonConstant.stationId
    const { userId } = this.props.line;
   await this.props.dispatch(updateUser(userId, stationId));
    liff.openWindow({
      url: commonConstant.directLinkLineOA,
      external: false
    });
  }

  renderModal = () => {
    const { modal } = this.state;
    const { language } = this.props;
    if (modal) {
      return (
        <div className="shell-modal">
          <div className="modal-content">
            <p>{content[language]['modal-text1']}</p>
            <p>{content[language]['modal-text2']}</p>
            <button className="close" onClick={() => liff.closeWindow()}>
              {content[language]['button-text2']}
            </button>
          </div>
        </div>
      )
    }
  }

  render() {
    const { language, line, user, station } = this.props;
    const { modal } = this.state;

    if ((!language || !line || !user.data || !station) && !modal) {
      return <Loading />;
    }

    const { branchName } = station;

    if (modal) {
      return this.renderModal();
    } else {
      return (
        <section className="customer">
          <div className="content">
            <div className="header">
              <img src={process.env.REACT_APP_HOMEPAGE + `img/welcome.png`} alt="success" />
              <h1>{content[language]['content-text1']}</h1>
              <p>{content[language]['content-text2']}{branchName}</p>
            </div>
            <img src={process.env.REACT_APP_HOMEPAGE + `img/welcome-detail.png`} alt="" />
          </div>
          <div className="btn">
            <button className="yellow" onClick={() => this.handleSubmit()}>
              {content[language]['button-text1']}
            </button>
          </div>
        </section>
      );
    }
  }
}

Customer.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, user, station, staff }) => ({
  'language': language.data,
  'line': line.data,
  'user': user,
  'station': station.data,
  'staff': staff.data,
});

export default connect(mapStateToProps)(Customer);
