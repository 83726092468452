import React, { createRef } from 'react';
import { CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';

import './ShellSelect.scss';

export const ShellSelect = ({ children, block, ...rest }) => (
  <div className={`shellselect-container ${block ? 'block' : ''}`}>
    <CustomSelect type="select" {...rest}>
      {children}
    </CustomSelect>
  </div>
);

ShellSelect.propTypes = {
  'children': PropTypes.node,
  'block': PropTypes.bool,
};
export class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'defaultSelectText': props.value ? props.value : '',
      'showOptionList': false,
      'optionsList': [],
    };
    this.container = createRef();
  }

  componentDidMount() {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener('mousedown', this.handleClickOutside);
    this.getDefaultSelectText(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value || prevProps.children !== this.props.children) {
      this.getDefaultSelectText(this.props);
    }
  }

  componentWillUnmount() {
    // Remove the event listner on component unmounting
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // This method handles the click that happens outside the
  // select text and list area
  handleClickOutside = e => {
    if (this.container && !this.container.current.contains(e.target)) {
      this.setState({
        'showOptionList': false,
      });
    }
  };

  // This method handles the display of option list
  handleListDisplay = () => {
    this.setState(prevState => ({
      'showOptionList': !prevState.showOptionList,
    }));
  };

  // This method handles the setting of name in select text area
  // and list display on selection
  handleOptionClick = (onChange, name, children, value, id) => {
    onChange({ 'target': { name, value, id } });
    this.setState({
      'defaultSelectText': children,
      // showOptionList: false,
    });
  };

  getDefaultSelectText = (props) => {
    if ((props.value === '' || props.value) && Array.isArray(props.children)) {
      const defaultOption = props.children?.flat().find(e => typeof e === 'object' && e !== null ? e.props.value === props.value : false);
      this.setState({
        'defaultSelectText': defaultOption ? defaultOption.props.children : '',
      });
    }
  }

  render() {
    const { children, placeholder, onChange, name, id, value, disabled = false, allKeyword, showSelect = false, customCarret } = this.props;
    const { showOptionList, defaultSelectText } = this.state;
    const isAll = allKeyword && ((value === allKeyword) || (children.length === 2));
    return (
      <div ref={this.container} onClick={disabled ? null : this.handleListDisplay}>
        <div className={`custom-select-container form-control ${disabled ? 'disabled' : ''} ${showOptionList ? 'active' : ''}`}
        >
          <div
            className={showOptionList ? `selected-text active ${value === '' && 'placeholder'}` : `selected-text ${value === '' && 'placeholder'}`}
          >
            {defaultSelectText ? defaultSelectText : placeholder}
          </div>
          <div className="select-options-container">
            {showOptionList && !disabled && (
              <ul className={`select-options ${showOptionList ? 'active' : ''}`}>
                {Array.isArray(children) ? children.map((option, idx) => {
                  if (!option) {
                    return null;
                  }
                  if (Array.isArray(option)) {
                    return option.map((e, index) => {
                      if (e) {
                        return (<li
                          key={`${idx}-${index}`}
                          className={`custom-select-option ${e.props.disabled && 'disabled'}`}
                          name={name}
                          id={id}
                          value={e.props.value}
                          onClick={e.props.disabled ? null : () => this.handleOptionClick(onChange, name, e.props.children, e.props.value, id)}
                        >
                          {showSelect && <CustomInput id={`${id}-checkbox${idx}-${index}`} type="checkbox" className="custom-select-option" checked={isAll || (value === e.props.value)} readOnly />}
                          <span className="custom-select-option">{e.props.children}</span>
                        </li>
                        );
                      }
                      return null;
                    });
                  } else {
                    return (
                      <li
                        key={idx}
                        className={`custom-select-option ${option.props.disabled && 'disabled'}`}
                        name={name}
                        id={id}
                        value={option.props.value}
                        onClick={option.props.disabled ? null : () => this.handleOptionClick(onChange, name, option.props.children, option.props.value, id)}
                      >
                        {showSelect && <CustomInput id={`${id}-checkbox${idx}`} className="custom-select-option" type="checkbox" checked={isAll || (value === option.props.value)} readOnly />}
                        <span className="custom-select-option">{option.props.children}</span>
                      </li>
                    );
                  }
                }) : <li
                  className={`custom-select-option ${children.props.disabled && 'disabled'}`}
                  name={name}
                  id={id}
                  value={children.props.value}
                  onClick={() => this.handleOptionClick(onChange, name, children.props.children, children.props.value, id)}
                >
                  {showSelect && <CustomInput id={`${id}-checkbox`} className="custom-select-option" type="checkbox" checked={isAll || (value === children.props.value)} readOnly />}
                  <span className="custom-select-option">{children.props.children}</span>
                </li>}
              </ul>
            )}
          </div>
        </div>
        <span className="shellselect-carret">
          {
            customCarret ? customCarret : <i className="fas fa-angle-down" />
          }
        </span>
      </div>
    );
  }
}

CustomSelect.propTypes = {
  'value': PropTypes.string,
  'children': PropTypes.node,
  'placeholder': PropTypes.string,
  'onChange': PropTypes.func,
  'name': PropTypes.string,
  'id': PropTypes.string,
  'disabled': PropTypes.bool,
  'allKeyword': PropTypes.string,
  'showSelect': PropTypes.bool,
  'customCarret': PropTypes.element,
};
