import commonConstant from '../../common/commonConstant';

export const recentTransaction = (state = {}, action) => {
    switch (action.type) {
        case commonConstant.FETCH_RECENT_TRANSACTION_SUCCESS:
            return { ...state, ...action };
        case commonConstant.FETCH_RECENT_TRANSACTION_FAILURE:
            return { ...state, ...action };
        default:
            return state;
    }
};
