export default {
    'th': {
        'header-text1': 'ข้อกำหนด เงื่อนไขการใช้งาน',
        'header-text1-1': 'และการใช้ข้อมูลบุคคล',
        'content-text1': 'ยอมรับข้อกำหนดและเงื่อนไขการใช้งาน Shell Line Connect เพื่อผลประโยชน์ของผู้ใช้งาน',
        'content-text2': 'ยินยอมและยอมรับการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลเพื่อผลประโยชน์ของผู้ใช้งาน',
        'content-text3': 'กรุณาคลิกอ่านรายละเอียด',
        'header-text2': 'ข้อกำหนดและเงื่อนไขการใช้งาน',
        'header-text2-1': 'Shell Line Connect',
        'line-content-text1': 'ข้อกำหนดและเงื่อนไขการใช้งาน Shell Line Connect',
        'line-content-text2': 'ผู้ขอใช้งานตกลงที่จะใช้งาน Shell Line Connect ของบริษัทฯ ตามรายละเอียดการใช้งานที่บริษัทฯ กำหนด ทั้งนี้ ผู้ขอใช้งานตกลงผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้งาน Shell Line Connect ดังต่อไปนี้',
        'line-content-text3': '1. คำนิยาม',
        'line-content-text4': '1.1 “บริษัทฯ” หมายความถึง บริษัท เชลล์แห่งประเทศไทย จำกัด และให้หมายความรวมถึงบุคคลที่ บริษัท เชลล์แห่งประเทศไทย จำกัด มอบหมายด้วย',
        'line-content-text5': '1.2 “ผู้ขอใช้งาน” หมายความถึง ผู้ขอใช้งาน Shell Line Connect ภายใต้ข้อกำหนดและเงื่อนไขการใช้งานนี้',
        'line-content-text6': '1.3 “บัญชี LINE” หมายความถึง บัญชีหรือข้อมูลการเป็นผู้ขอใช้บริการระบบปฏิบัติการ LINE Application ของผู้ขอใช้งาน ซึ่งผู้ขอใช้งานได้สร้างหรือจัดทำขึ้นโดยการลงทะเบียนสมัครใช้บริการบนระบบปฏิบัติการ LINE Application ตามฐานข้อมูลของผู้ให้บริการ LINE Application',
        'line-content-text7': '1.4 “บัญชี Shell Line Connect” หมายความถึง บัญชีหรือข้อมูลสำหรับการใช้งาน Shell Line Connect ของผู้ขอใช้งาน ซึ่งผู้ขอใช้งานได้สร้างหรือจัดทำขึ้นโดยการลงทะเบียนสมัครใช้งาน Shell Line Connect บนอุปกรณ์เพื่อการทำรายการของผู้ขอใช้งานซึ่งประกอบด้วย ชื่อ, นามสกุล, วัน เดือน ปี เกิด, อีเมล, ที่อยู่ และหมายเลขโทรศัพท์',
        'line-content-text8': '1.5 “ผู้ให้บริการ LINE Application” หมายความถึง บริษัท ไลน์ คอมพานี (ประเทศไทย) จำกัด และให้หมายความรวมถึงบุคคลที่บริษัท ไลน์ คอมพานี (ประเทศไทย) จำกัด มอบหมายด้วย',
        'line-content-text9': '1.6 “Shell Line Connect” หมายความถึง ช่องทางเพื่อใช้เชื่อมต่อระหว่างบริษัทฯ กับลูกค้า Shell โดยผ่านทางข้อความในระบบปฏิบัติการ LINE เพื่อการขอและการรับข้อมูล และ/หรือ รับการแจ้งเตือนสิทธิประโยชน์ของผู้ขอใช้งาน และ/หรือ การแจ้งข้อมูลข่าวสาร และ/หรือ บริการอื่นใด ตามที่บริษัทฯกำหนดและทยอยเปิดใช้งาน โดยผู้ขอใช้งานจะสามารถใช้งาน Shell Line Connect ได้เฉพาะการใช้ผ่านอุปกรณ์เพื่อการทำรายการในเครือข่ายระบบที่บริษัทฯ กำหนด ผ่านระบบเครือข่ายอินเตอร์เน็ต ทั้งนี้ บริษัทฯ มีสิทธิกำหนด และ/หรือเพิ่มเติม และ/หรือ แก้ไขเปลี่ยนแปลงช่องทาง และ/หรือ เครือข่ายที่ผู้ขอใช้งานจะสามารถใช้งานได้ตามที่บริษัทฯ เห็นสมควร',
        'line-content-text10': '1.7 “อุปกรณ์เพื่อการทำรายการ” หมายความถึง โทรศัพท์มือถือ และ/หรือ อุปกรณ์อิเล็กทรอนิกส์ใด ๆ ของผู้ขอใช้งาน ที่มีการดาวน์โหลดและติดตั้งระบบปฏิบัติการ LINE Application สำหรับใช้บัญชี LINE ที่ผู้ขอใช้งานสมัครใช้งาน Shell Line Connect และ/หรือ ใช้ในการทำรายการการใช้งาน Shell Line Connect',
        'line-content-text11': '1.8 “หมายเลขโทรศัพท์” หมายความถึง หมายเลขโทรศัพท์มือถือที่ ผู้ขอใช้งานแจ้งไว้ในการสมัครเป็นสมาชิก Shell Line Connect ตามข้อกำหนดของบริษัทฯ',
        'header-text3': 'การเก็บรวบรวมใช้และเปิดเผย',
        'header-text3-1': 'ข้อมูลส่วนบุคคล',
        'privacy-content-text1': '“ผู้ขอใช้งานยอมรับในข้อตกลง เงื่อนไข นโยบายความเป็นส่วนตัวที่ระบุในเว็บไซต์ของเชลล์ https://www.shell.co.th/th_th/privacy.html และตกลงยินยอมให้บริษัท เชลล์แห่งประเทศไทย จำกัด ("เชลล์") เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล คือ ชื่อ นามสกุล วันเดือนปีเกิด เบอร์โทรศัพท์ส่วนบุคคล อีเมล์ส่วนบุคคล ที่อยู่ รวมถึงรูปโปรไฟล์ที่แสดงถึงรูปใบหน้าของผู้ขอใช้งาน ที่ผู้ขอใช้งานได้ใช้งาน Shell Line เพื่อวัตถุประสงค์ในการสร้างฐานบัญชีสมาชิก และอัพเดทข้อมูลการใช้งาน สิทธิประโยชน์ และเพื่อการอื่นใด โดยไม่จำกัดแต่เพียงการวิจัยหรือการตลาด รวมถึง เพื่อนำมาใช้ให้ปรากฏเป็นรูปโปรไฟล์ที่แสดงถึงรูปใบหน้าในบัญชี Shell Line Connect ของผู้ขอใช้งานเอง',
        'privacy-content-text2': 'ผู้ขอใช้งานขอรับรองว่า ผู้ขอใช้งานเป็นบุคคลที่มีความสามารถตามกฎหมายในการให้ความยินยอมเกี่ยวกับการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล และไม่ได้เป็นผู้เยาว์ บุคคลเสมือนไร้ความสามารถ หรือบุคคลไร้ความสามารถ',
        'privacy-content-text3': 'ทั้งนี้ ผู้ขอใช้งานสามารถติดต่อเชลล์เมื่อใดก็ได้เพื่อให้เชลล์ดำเนินการยกเลิกการให้ความยินยอมดังกล่าวทันที หากผู้ขอใช้งานประสงค์ให้ความยินยอม กรุณาคลิกที่กล่องยินยอมและยอมรับการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลดังกล่าวต่อไป”',
        'button-text1': 'ยอมรับ',
        'button-text2': 'ยกเลิก',

    },
    'en': {
        'header-text1': 'ยอมรับและยินยอมข้อกำหนดและเงื่อนไขการใช้งาน',
        'content-text1': 'ยอมรับข้อกำหนดและเงื่อนไขการใช้งาน Shell Line Connect เพื่อผลประโยชน์ของผู้ใช้งาน',
        'content-text2': 'ยินยอมและยอมรับการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลเพื่อผลประโยชน์ของผู้ใช้งาน',
        'content-text3': 'กรุณาคลิกอ่านรายละเอียด',
        'header-text2': 'ข้อกำหนดและเงื่อนไขการใช้งาน Shell Line Connect',
        'line-content-text1': 'ข้อกำหนดและเงื่อนไขการใช้งาน Shell Line Connect',
        'line-content-text2': 'ผู้ขอใช้งานตกลงที่จะใช้งาน Shell Line Connect ของบริษัทฯ ตามรายละเอียดการใช้งานที่บริษัทฯ กำหนด ทั้งนี้ ผู้ขอใช้งานตกลงผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้งาน Shell Line Connect ดังต่อไปนี้',
        'line-content-text3': '1. คำนิยาม',
        'line-content-text4': '1.1 “บริษัทฯ” หมายความถึง บริษัท เชลล์แห่งประเทศไทย จำกัด และให้หมายความรวมถึงบุคคลที่ บริษัท เชลล์แห่งประเทศไทย จำกัด มอบหมายด้วย',
        'line-content-text5': '1.2 “ผู้ขอใช้งาน” หมายความถึง ผู้ขอใช้งาน Shell Line Connect ภายใต้ข้อกำหนดและเงื่อนไขการใช้งานนี้',
        'line-content-text6': '1.3 “บัญชี LINE” หมายความถึง บัญชีหรือข้อมูลการเป็นผู้ขอใช้บริการระบบปฏิบัติการ LINE Application ของผู้ขอใช้งาน ซึ่งผู้ขอใช้งานได้สร้างหรือจัดทำขึ้นโดยการลงทะเบียนสมัครใช้บริการบนระบบปฏิบัติการ LINE Application ตามฐานข้อมูลของผู้ให้บริการ LINE Application',
        'line-content-text7': '1.4 “บัญชี Shell Line Connect” หมายความถึง บัญชีหรือข้อมูลสำหรับการใช้งาน Shell Line Connect ของผู้ขอใช้งาน ซึ่งผู้ขอใช้งานได้สร้างหรือจัดทำขึ้นโดยการลงทะเบียนสมัครใช้งาน Shell Line Connect บนอุปกรณ์เพื่อการทำรายการของผู้ขอใช้งานซึ่งประกอบด้วย ชื่อ, นามสกุล, วัน เดือน ปี เกิด, อีเมล, ที่อยู่ และหมายเลขโทรศัพท์',
        'line-content-text8': '1.5 “ผู้ให้บริการ LINE Application” หมายความถึง บริษัท ไลน์ คอมพานี (ประเทศไทย) จำกัด และให้หมายความรวมถึงบุคคลที่บริษัท ไลน์ คอมพานี (ประเทศไทย) จำกัด มอบหมายด้วย',
        'line-content-text9': '1.6 “Shell Line Connect” หมายความถึง ช่องทางเพื่อใช้เชื่อมต่อระหว่างบริษัทฯ กับลูกค้า Shell โดยผ่านทางข้อความในระบบปฏิบัติการ LINE เพื่อการขอและการรับข้อมูล และ/หรือ รับการแจ้งเตือนสิทธิประโยชน์ของผู้ขอใช้งาน และ/หรือ การแจ้งข้อมูลข่าวสาร และ/หรือ บริการอื่นใด ตามที่บริษัทฯกำหนดและทยอยเปิดใช้งาน โดยผู้ขอใช้งานจะสามารถใช้งาน Shell Line Connect ได้เฉพาะการใช้ผ่านอุปกรณ์เพื่อการทำรายการในเครือข่ายระบบที่บริษัทฯ กำหนด ผ่านระบบเครือข่ายอินเตอร์เน็ต ทั้งนี้ บริษัทฯ มีสิทธิกำหนด และ/หรือเพิ่มเติม และ/หรือ แก้ไขเปลี่ยนแปลงช่องทาง และ/หรือ เครือข่ายที่ผู้ขอใช้งานจะสามารถใช้งานได้ตามที่บริษัทฯ เห็นสมควร',
        'line-content-text10': '1.7 “อุปกรณ์เพื่อการทำรายการ” หมายความถึง โทรศัพท์มือถือ และ/หรือ อุปกรณ์อิเล็กทรอนิกส์ใด ๆ ของผู้ขอใช้งาน ที่มีการดาวน์โหลดและติดตั้งระบบปฏิบัติการ LINE Application สำหรับใช้บัญชี LINE ที่ผู้ขอใช้งานสมัครใช้งาน Shell Line Connect และ/หรือ ใช้ในการทำรายการการใช้งาน Shell Line Connect',
        'line-content-text11': '1.8 “หมายเลขโทรศัพท์” หมายความถึง หมายเลขโทรศัพท์มือถือที่ ผู้ขอใช้งานแจ้งไว้ในการสมัครเป็นสมาชิก Shell Line Connect ตามข้อกำหนดของบริษัทฯ',
        'privacy-content-text1': '“ผู้ขอใช้งานยอมรับในข้อตกลง เงื่อนไข นโยบายความเป็นส่วนตัวที่ระบุในเว็บไซต์ของเชลล์ https://www.shell.co.th/th_th/privacy.html และตกลงยินยอมให้บริษัท เชลล์แห่งประเทศไทย จำกัด ("เชลล์") เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล คือ ชื่อ นามสกุล วันเดือนปีเกิด เบอร์โทรศัพท์ส่วนบุคคล อีเมล์ส่วนบุคคล ที่อยู่ รวมถึงรูปโปรไฟล์ที่แสดงถึงรูปใบหน้าของผู้ขอใช้งาน ที่ผู้ขอใช้งานได้ใช้งาน Shell Line เพื่อวัตถุประสงค์ในการสร้างฐานบัญชีสมาชิก และอัพเดทข้อมูลการใช้งาน สิทธิประโยชน์ และเพื่อการอื่นใด โดยไม่จำกัดแต่เพียงการวิจัยหรือการตลาด รวมถึง เพื่อนำมาใช้ให้ปรากฏเป็นรูปโปรไฟล์ที่แสดงถึงรูปใบหน้าในบัญชี Shell Line Connect ของผู้ขอใช้งานเอง',
        'privacy-content-text2': 'ผู้ขอใช้งานขอรับรองว่า ผู้ขอใช้งานเป็นบุคคลที่มีความสามารถตามกฎหมายในการให้ความยินยอมเกี่ยวกับการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล และไม่ได้เป็นผู้เยาว์ บุคคลเสมือนไร้ความสามารถ หรือบุคคลไร้ความสามารถ',
        'privacy-content-text3': 'ทั้งนี้ ผู้ขอใช้งานสามารถติดต่อเชลล์เมื่อใดก็ได้เพื่อให้เชลล์ดำเนินการยกเลิกการให้ความยินยอมดังกล่าวทันที หากผู้ขอใช้งานประสงค์ให้ความยินยอม กรุณาคลิกที่กล่องยินยอมและยอมรับการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลดังกล่าวต่อไป”',
        'button-text1': 'ยอมรับ',
        'button-text2': 'ยกเลิก',
    },
};