export default {
  'th': {
    'content-text1': 'รถที่อยู่ระหว่างการดำเนินการ',
    'content-text2': 'รถที่รับบริการเสร็จสิ้นแล้ว',
    'content-text3': 'ไม่มีรถที่อยู่ระหว่างการดำเนินการ',
    'content-text4': 'สาขา',
    'content-text5': 'บริการถ่ายน้ำมันเครื่อง',
    'content-text6': 'บริการล้างรถ',
    'link-text1': '+ เพิ่มคิว'
  },
  'en': {
    'content-text1': 'รถที่อยู่ระหว่างการดำเนินการ',
    'content-text2': 'รถที่รับบริการเสร็จสิ้นแล้ว',
    'content-text3': 'ไม่มีรถที่อยู่ระหว่างการดำเนินการ',
    'content-text4': 'สาขา',
    'content-text5': 'บริการถ่ายน้ำมันเครื่อง',
    'content-text6': 'บริการล้างรถ',
    'link-text1': '+ เพิ่มคิว'
  },
};
