import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';

export const setProfile = getProfile => (
  async dispatch => {
    try {
      return dispatch({
        'type': commonConstant.SET_PROFILE_SUCCESS,
        'data': getProfile,
        'status': 200,
        'dateTime': new Date(),
      });
    } catch (err) {
      return dispatch({
        'type': commonConstant.SET_PROFILE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);