import React, { Component } from 'react';
// import lottie from 'lottie-web';

import './Loading.scss';
// import animationData from './LoadingBigC.json';

export default class Loading extends Component {

  render() {
    const { smallSize } = this.props;
    return (
      <div className={smallSize ? "container-loading-small": "container-loading"}>
        <div className="loader" />
      </div>
    );
  }
}