import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';
import './WalkInCheck.scss';
import content from './Language';

import {
    setLanguage,
    fetchTransaction,
} from '../../actions';

import { Loading } from '../../components';
import { history } from '../../helpers';

const liff = window.liff;

export class WalkInCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'orderId': null,
        };
    }

    UNSAFE_componentWillMount() {
        if (!this.props.language) {
            return this.props.dispatch(setLanguage('th'));
        }
    }

    async componentDidMount() {
        const { orderId } = this.props.match.params;
        await this.props.dispatch(fetchTransaction(orderId));
        const { status } = this.props.transaction;
        if (status === 'InService') {
            return history.replace(commonConstant.pathShellStaffLubeProcessing);
        }
        this.setState({ orderId });
    }

    componentDidUpdate() {

    }

    render() {
        const { language, line } = this.props;
        const { orderId } = this.state;

        if (!language || !line || !orderId) {
            return <Loading />;
        }

        return (
            <div className="WalkInCheck">
                <div className="modal">
                    <div className="modal-box">
                        <img src={process.env.REACT_APP_HOMEPAGE + `img/danger.svg`} alt="danger" />
                        <p className="modal-header">{content[language]['content-text1']}</p>
                        <p className="content">{content[language]['content-text2']}</p>
                        <p className="content">{content[language]['content-text3']}</p>
                        <button className="modal-button" onClick={() => liff.closeWindow()}>{content[language]['content-text4']}</button>
                    </div>
                </div>
            </div>
        )
    }
}

WalkInCheck.propTypes = {
    'dispatch': PropTypes.func.isRequired,
    'language': PropTypes.string,
};

const mapStateToProps = ({ language, transaction, line }) => ({
    'language': language.data,
    'transaction': transaction.data,
    'line': line.data
});

export default connect(mapStateToProps)(WalkInCheck);
