import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';

export const setOrder = (req, staff) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders?staff=${staff}&access_token=${commonConstant.cookieToken}`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify(req),
      });
      const data = await res.json();
      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.SET_ORDER_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.SET_ORDER_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.SET_ORDER_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchOrder = (orderId) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/${orderId}?access_token=${commonConstant.cookieToken}`, {
        'method': 'GET',
      });
      const data = await res.json();
      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.FETCH_ORDER_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.FETCH_ORDER_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.FETCH_ORDER_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchOrderWeekly = (stationId, service) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/weekly/${stationId}?service=${service}&access_token=${commonConstant.cookieToken}`, {
        'method': 'GET',
      });
      const data = await res.json();
      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.FETCH_ORDER_WEEKLY_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.FETCH_ORDER_WEEKLY_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.FETCH_ORDER_WEEKLY_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

// export const fetchCancelOrderWeekly = (stationId, service) => (
//   async dispatch => {
//     try {
//       const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/weekly/cancel/${stationId}?service=${service}&access_token=${commonConstant.cookieToken}`, {
//         'method': 'GET',
//       });
//       const data = await res.json();
//       if (res.status === 200 || res.status === 401) {
//         return dispatch({
//           'type': commonConstant.FETCH_CANCEL_ORDER_WEEKLY_SUCCESS,
//           data,
//           'status': res.status,
//           'dateTime': new Date(),
//         });
//       }
//       return dispatch({
//         'type': commonConstant.FETCH_CANCEL_ORDER_WEEKLY_FAILURE,
//         'data': null,
//         'status': res.status ? res.status : res,
//         'dateTime': new Date(),
//       });
//     } catch (err) {
//       return dispatch({
//         'type': commonConstant.FETCH_CANCEL_ORDER_WEEKLY_FAILURE,
//         'data': null,
//         'status': err.status ? err.status : err,
//         'dateTime': new Date(),
//       });
//     }
//   }
// );

export const fetchOrderToday = (stationId, service) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/today/${stationId}?service=${service}&access_token=${commonConstant.cookieToken}`, {
        'method': 'GET',
      });
      const data = await res.json();
      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.FETCH_ORDER_TODAY_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.FETCH_ORDER_TODAY_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.FETCH_ORDER_TODAY_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchOrderYesterday = (stationId, service) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/yesterday/${stationId}?service=${service}&access_token=${commonConstant.cookieToken}`, {
        'method': 'GET',
      });
      const data = await res.json();
      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.FETCH_ORDER_YESTERDAY_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.FETCH_ORDER_YESTERDAY_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.FETCH_ORDER_YESTERDAY_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const setOrderStaff = (orderId, req) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/staff/${orderId}?access_token=${commonConstant.cookieToken}`, {
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        'method': 'PATCH',
        'body': JSON.stringify(req)
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.SET_ORDER_STAFF_SUCCESS,
          'data': data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      return dispatch({
        'type': commonConstant.SET_ORDER_STAFF_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'message': data.error.message,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.SET_ORDER_STAFF_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const createWalkinQrCode = (orderId, stationId) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/staff/${orderId}?stationId=${stationId}&access_token=${commonConstant.cookieToken}`, {
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        'method': 'POST',
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.CREATE_WALKIN_QRCODE_SUCCESS,
          'data': data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      return dispatch({
        'type': commonConstant.CREATE_WALKIN_QRCODE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'message': data.error.message,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.CREATE_WALKIN_QRCODE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const confirmWalkInOrder = (orderId, req) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/walk-in/${orderId}?access_token=${commonConstant.cookieToken}`, {
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        'method': 'POST',
        'body': JSON.stringify(req)
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.CONFIRM_WALKIN_ORDER_SUCCESS,
          'data': data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      return dispatch({
        'type': commonConstant.CONFIRM_WALKIN_ORDER_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'message': data.error.message,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.CONFIRM_WALKIN_ORDER_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const setViewedOrder = (orderId) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/view/${orderId}?access_token=${commonConstant.cookieToken}`, {
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        'method': 'POST',
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.SET_VIEWED_ORDER_SUCCESS,
          'data': data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      return dispatch({
        'type': commonConstant.SET_VIEWED_ORDER_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'message': data.error.message,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.SET_VIEWED_ORDER_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const updateEditNum = (orderId) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/orders/edit-num?access_token=${commonConstant.cookieToken}`, {
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        'method': 'POST',
        'body': JSON.stringify(orderId),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.UPDATE_EDIT_NUM_SUCCESS,
          'data': data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      return dispatch({
        'type': commonConstant.UPDATE_EDIT_NUM_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'message': data.error.message,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.UPDATE_EDIT_NUM_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);