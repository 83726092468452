export default {
  'th': {
    'header-text1': 'คุณได้จองเข้ารับบริการเรียบร้อยแล้ว',
    'header-text3': 'เปลี่ยนแปลงรายระเอียดการจอง เรียบร้อยแล้ว',
    'content-text1': 'ชื่อ',
    'content-text2': 'เบอร์ติดต่อ',
    'content-text3': 'ยี่ห้อรถ',
    'content-text4': 'รุ่นรถ',
    'content-text5': 'ทะเบียนรถ',
    'content-text6': 'วันที่ ',
    'content-text7': 'เวลา ',
    'content-text8': 'สถานีบริการ ',
    'content-text9': 'นามสกุล',
    'content-text10': 'แพ็กเกจ',
    'link-text1': 'ยกเลิกการจอง',
    'button-text1': 'กลับสู่หน้าแชท',
    'button-text2': 'เริ่มเข้ารับบริการ',
    'footer-text1': 'QR Code สำหรับสแกนเพื่อเข้ารับบริการ',
  },
  'en': {
    'header-text1': 'คุณได้จองเข้ารับบริการเรียบร้อยแล้ว',
    'header-text3': 'เปลี่ยนแปลงรายระเอียดการจอง เรียบร้อยแล้ว',
    'content-text1': 'ชื่อ',
    'content-text2': 'เบอร์ติดต่อ',
    'content-text3': 'ยี่ห้อรถ',
    'content-text4': 'รุ่นรถ',
    'content-text5': 'ทะเบียนรถ',
    'content-text6': 'วันที่ ',
    'content-text7': 'เวลา ',
    'content-text8': 'สถานีบริการ ',
    'content-text9': 'นามสกุล',
    'content-text10': 'แพ็กเกจ',
    'link-text1': 'ยกเลิกการจอง',
    'button-text1': 'กลับสู่หน้าแชท',
    'button-text2': 'เริ่มเข้ารับบริการ',
    'footer-text1': 'QR Code สำหรับสแกนเพื่อเข้ารับบริการ',
  },
};
