import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import content from './Language';
import '../LubeBooking.scss';

class FormCarDetails extends Component {
    watchBtnSubmit = () => {
      const { brand, brandETC, model, licensePlate, service } = this.props.formValues;
      const plateRegex = /(^[0-9]?[ก-ฮ][ก-ฮ]?[0-9]{1,4}$)|(^[0-9]{4,6}$)/g;
      if (brand.value.trim() !== '' && model.value.trim() !== '' && licensePlate.value.trim() !== '' && licensePlate.value.match(plateRegex)) {
        if (brand.value === 'อื่นๆ' && brandETC.value === '') {
          return { 'disabled': true, 'btnSubmit': 'disable' };
        } else {
          return {
            'disabled': false,
            'btnSubmit': service === 'Lube' ? 'yellow' : 'blue',
          };
        }
      } else {
        return { 'disabled': true, 'btnSubmit': 'disable' };
      }
    }
    render() {
      const { language, nextStep, functions } = this.props;
      const { licensePlate, brand, brandETC, model, edit } = this.props.formValues;
      const { handleEvent, renderBrands, handleCancel } = functions;
      const btnSubmit = this.watchBtnSubmit();

      return (
        <form className="forms">
          <div className="middle">
            <p className="content-1">{content[language]['car-header-1']}</p>
            <div className="form-container">
              <div className="box-input">
                {!licensePlate.value ? '' : <label>{content[language]['car-content-text1']}</label>}
                <input
                  type="text"
                  value={licensePlate.value}
                  onChange={event => handleEvent(event, 'licensePlate')}
                  maxLength="50"
                  placeholder={content[language]['car-content-text2']}
                />
              </div>
              <div className="box-input">
                {!brand.value ? '' : <label>{content[language]['car-content-text3']}</label>}
                <div className="select-style">
                  <i className="fas fa-chevron-down"></i>
                  <select
                    value={brand.value}
                    onChange={event => handleEvent(event, 'brand')}
                  >
                    <option value="" hidden>{content[language]['car-content-text4']}</option>
                    {renderBrands()}
                  </select>
                </div>

              </div>
              {
                brand.value === 'อื่นๆ' ?
                  <div className="box-input">
                    {!brandETC.value ? '' : <label>{content[language]['car-content-text3']}</label>}
                    <input
                      type="text"
                      value={brandETC.value}
                      onChange={event => handleEvent(event, 'brandETC')}
                      maxLength="20"
                      placeholder={content[language]['car-content-text5']}
                    />
                  </div>
                  : null
              }
              <div className="box-input">
                {!model.value ? '' : <label>{content[language]['car-content-text6']}</label>}
                <input
                  type="text"
                  value={model.value}
                  onChange={event => handleEvent(event, 'model')}
                  maxLength="30"
                  placeholder={content[language]['car-content-text7']}
                />
              </div>
            </div>

          </div>
          {edit ? '' :
            <div className="btn">
              <input
                type="button"
                className={btnSubmit.btnSubmit}
                onClick={() => nextStep()}
                value={content[language]['button-text1']}
                disabled={btnSubmit.disabled}
              />
              <input
                type="button"
                className="cancel"
                onClick={() => handleCancel()}
                value={content[language]['button-text3']}
              />
            </div>}
        </form>
      );
    }
}

FormCarDetails.propTypes = {
  'language': PropTypes.string,
};

const mapStateToProps = ({ language }) => ({
  'language': language.data,
});
export default connect(mapStateToProps)(FormCarDetails);
