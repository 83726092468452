export default {
  'th': {
    'header-text1': 'รายละเอียด',
    'header-text2': 'ยกเลิกการจอง',
    'step3-text1': 'ยืนยัน',
    'step3-text2': 'ยกเลิกการจอง',
    'step3-text3': 'เชลล์ขอขอบคุณ',
    'step3-text4': 'สำหรับความคิดเห็นของท่าน',
    'step2-text1': 'เพื่อการพัฒนาการบริการของเชลล์ให้ดียิ่งขึ้น',
    'step2-text2': 'ระบุสาเหตุที่ทำให้ท่านต้องการยกเลิกการจอง',
    'step2-text3': 'เราจะนำความคิดเห็นของท่านไปปรับปรุงการบริการของเชลล์',
    'radio-text1': 'มีการเปลี่ยนแผนหรือกำหนดการ การเข้ารับบริการ',
    'radio-text2': 'มีความกังวลในเรื่องค่าใช้จ่ายสำหรับการเข้ารับบริการ',
    'radio-text3': 'มีการเปลี่ยนแผน โดยการเข้ารับบริการที่อื่นแทน',
    'radio-text4': 'สาเหตุอื่นๆ',
    'content-text1': 'กรุณาระบุชื่อสถานที่และเหตุผล',
    'content-text2': 'กรุณาระบุสาเหตุ',
    'content-text3': 'สาขา',
    'button-text1': 'ตกลง',
    'step1-text1': 'คุณต้องการ',
    'step1-text2': '“ยกเลิกการจอง”',
    'step1-text3': 'ใช่หรือไม่',
    'button-text2': 'ไม่ต้องการยกเลิก',
    'button-text3': 'ยืนยันการยกเลิก',
  },
  'en': {
    'header-text1': 'รายละเอียด',
    'header-text2': 'ยกเลิกการจอง',
    'step3-text1': 'ยืนยัน',
    'step3-text2': 'ยกเลิกการจอง',
    'step3-text3': 'เชลล์ขอขอบคุณ',
    'step3-text4': 'สำหรับความคิดเห็นของท่าน',
    'step2-text1': 'เพื่อการพัฒนาการบริการของเชลล์ให้ดียิ่งขึ้น',
    'step2-text2': 'ระบุสาเหตุที่ทำให้ท่านต้องการยกเลิกการจอง',
    'step2-text3': 'เราจะนำความคิดเห็นของท่านไปปรับปรุงการบริการของเชลล์',
    'radio-text1': 'มีการเปลี่ยนแผนหรือกำหนดการ การเข้ารับบริการ',
    'radio-text2': 'มีความกังวลในเรื่องค่าใช้จ่ายสำหรับการเข้ารับบริการ',
    'radio-text3': 'มีการเปลี่ยนแผน โดยการเข้ารับบริการที่อื่นแทน',
    'radio-text4': 'สาเหตุอื่นๆ',
    'content-text1': 'กรุณาระบุชื่อสถานที่และเหตุผล',
    'content-text2': 'กรุณาระบุสาเหตุ',
    'content-text3': 'สาขา',
    'button-text1': 'ตกลง',
    'step1-text1': 'คุณต้องการ',
    'step1-text2': '“ยกเลิกการจอง”',
    'step1-text3': 'ใช่หรือไม่',
    'button-text2': 'ไม่ต้องการยกเลิก',
    'button-text3': 'ยืนยันการยกเลิก',
  },
};
