import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';

export const fetchPromotion = (stationId, lineId, service) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.envDomainShellApi}/api/promotions/is-ready/${stationId}?lineId=${lineId}&access_token=${commonConstant.cookieToken}`, {
        'method': 'GET',
      });
      const data = await res.json();
      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.FETCH_PROMOTION_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      return dispatch({
        'type': commonConstant.FETCH_PROMOTION_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathServerError);
      return dispatch({
        'type': commonConstant.FETCH_PROMOTION_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
