import commonConstant from '../../common/commonConstant';

export const statusOrder = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.SET_STATUS_ORDER_SUCCESS:
      return { ...state, ...action };
    case commonConstant.SET_STATUS_ORDER_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const statusOrderCallCustomer = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.FETCH_STATUS_ORDERS_CALL_CUSTOMER_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FETCH_STATUS_ORDERS_CALL_CUSTOMER_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
