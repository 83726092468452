import React,{ useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLanguage } from '../../actions';
import { Loading } from '../../components';
import content from './Language';
import { history } from '../../helpers';

import './ServerError.scss';

export default function ServerError() {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.data);
  useEffect(() => {
    if (!language) {
      return dispatch(setLanguage('th'));
    }
  },[]);
  if (!language){
    return <Loading />;
  }
  return (
    <div className="serverError-container">
      <div className="serverError">
        <div className="header">{content[language].header}</div>
        <img className="plug-img" src={`${process.env.PUBLIC_URL}/img/plug.png`} alt="plug"/>
        <p className="desc">{content[language].desc}</p>
        <button className="btn yellow" onClick={() => window.location.replace(document.referrer)}>{content[language].buttonReorder}</button>
      </div>
    </div>
 
  );
}
