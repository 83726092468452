export default {
    'th': {
        'content-text1': 'ลูกค้ายังไม่เข้าสู่กระบวนการ',
        'content-text2': 'กรุณาให้ลูกค้าสแกน QRcode',
        'content-text3': 'เพื่อให้ลูกค้าเข้าสู่ระบบ',
        'content-text4': 'ปิด',
    },
    'en': {
        'content-text1': 'ลูกค้ายังไม่เข้าสู่กระบวนการ',
        'content-text2': 'กรุณาให้ลูกค้าสแกน QRcode',
        'content-text3': 'เพื่อให้ลูกค้าเข้าสู่ระบบ',
        'content-text4': 'ปิด',
    },
};