import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loading } from '../../components';
import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';
import './CarwashNot.scss';
import content from './Language';

import {
  setLanguage,
  sendBookingMsg,
  fetchUser,
  fetchStation
} from '../../actions';

const liff = window.liff;

class CarwashNot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'status': null,
      'service': null,
      'type': null,
      'orderId': null
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    if (this.props.location.state) {
      const { service, status, type, orderId } = this.props.location.state;
      const data = {};
      if (service) data.service = service;
      if (status) data.status = status;
      if (type) data.type = type;
      if (orderId) data.orderId = orderId;
      this.setState(data);
    }

    const { line } = this.props;
    await this.props.dispatch(fetchUser(line.userId));
    const { stationId } = this.props.user;
    await this.props.dispatch(fetchStation(stationId));
  }

  renderMsg = () => {
    const { language } = this.props;
    const { status, type } = this.state;
    let warning = '';
    switch (type) {
      case 'detail':
        warning = content[language]['content-text1']
        break;
      case 'Walk-in':
        warning = content[language]['content-text1']
        break;
      case 'cancel':
        warning = content[language]['content-text2']
        break;
      case 'cancel-over':
        warning = content[language]['content-text7']
        break;
      case 'edit-over':
        warning = content[language]['content-text9']
        break;
      default:
        warning = content[language]['content-text3']
        break;
    }
    switch (status) {
      case 'Cancel':
        return (
          <div className="over-error-cancel">
            <img src={`/lineoa/img/error-service.svg`} alt="danger" />
            <p>
            {warning}<br />
            {content[language]['content-text5']}<span>{content[language]['error-text1']}</span>{content[language]['content-text6']}
          </p>
          </div>
        )
      case 'cancel-over':
        return (
          <div className="over-error">
            <img src={process.env.REACT_APP_HOMEPAGE +`img/danger.svg`} alt="danger" />
            <h1>{warning}</h1>
            <p>{content[language]['content-text8']}</p>
          </div>
        )
      case 'edit-over':
        return (
          <div className="over-error">
            <img src={process.env.REACT_APP_HOMEPAGE +`img/danger.svg`} alt="danger" />
            <h1>{warning}</h1>
            <p>{content[language]['content-text10']}</p>
          </div>
        )
      default:
        return (
          <div className="over-error-cancel">
          <img src={`/lineoa/img/error-service.svg`} alt="danger" />
          <p>
            {warning}<br />
            {content[language]['content-text4']}<span>{content[language]['error-text2']}</span>{content[language]['content-text6']}
          </p>
        </div>
          
        )
    }
  }

  renderButton = () => {
    const { language } = this.props;
    const { type } = this.state;
    switch (type) {
      case 'Walk-in':
        return (
          <div className="btn">
            <input
              type="button"
              className="white"
              onClick={() => this.handleBooking()}
              value={content[language]['button-text2']}
            />
            <input
              type="button"
              className="yellow"
              onClick={() => this.handleClose()}
              value={content[language]['button-text3']}
            />
          </div>)
      case 'cancel-over':
        return (
          <div className="btn">
            <input
              type="button"
              className="yellow"
              onClick={() => this.handleSubmit()}
              value={content[language]['button-text3']}
            />
          </div>)
      case 'edit-over':
        return (
          <div className="btn">
            <input
              type="button"
              className="yellow"
              onClick={() => this.handleClose()}
              value={content[language]['button-text3']}
            />
            <input
              type="button"
              className="white"
              onClick={() => this.handleCancleBooking()}
              value={content[language]['button-text4']}
            />
          </div>)
      default:
        return (
          <div className="btn">
            <input
              type="button"
              className="yellow"
              onClick={() => this.handleBooking()}
              value={content[language]['button-text1']}
            />
          </div>)
    }
  }

  handleBooking = () => history.replace(commonConstant.pathShellCarwashBooking, { service: 'Carwash', type: 'Walk-in' });

  handleCancleBooking = () => history.push(`${commonConstant.pathShellCarwashCancel}/${this.state.orderId}`);

  handleSubmit = async () => {
    const { orderId } = this.state;
    await this.props.dispatch(sendBookingMsg(orderId));
    liff.closeWindow();
  }

  handleClose = () => {
    liff.closeWindow();
  }

  render() {
    const { status, service } = this.state;
    const { station, language } = this.props;

    if (!status || !service || !station) {
      return <Loading />;
    }

    return (
      <main className="carwash-not">
        <div className={service === 'Lube' ? 'header' : 'header blue'}>
          <div className="logo">
            <img src={`/lineoa/img/carwash-logo.svg`} alt="logo" />
          </div>
          <p>{content[language]['content-text11']} {station.branchName}</p>
        </div>
        <div className="content">
          {this.renderMsg()}
        </div>
        {this.renderButton()}
      </main>
    );
  }
}

CarwashNot.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, user, station }) => (
  {
    'language': language.data,
    'line': line.data,
    'user': user.data,
    'station': station.data,
  }
);

export default connect(mapStateToProps)(CarwashNot);
