import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import commonConstant from '../../common/commonConstant';
import content from './Language';

import {
  setLanguage,
  fetchStaff,
  fetchOrderWeekly,
  setViewedOrder,
  fetchPrevCancelOrder,
  fetchAheadCancelOrder,
  fetchStation
} from '../../actions';
import { Loading } from '../../components';

import './StaffLubeQueues.scss';
import { history } from '../../helpers';

class StaffLubeQueues extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const offset = new Date().getTimezoneOffset();
    today.setHours(today.getHours() - offset / 60);

    let days = [];
    for (let i = 0; i <= 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      if (date.toJSON() === today.toJSON()) {
        days.push({
          name: "วันนี้",
          method: i,
          icon: true,
          content: true,
          data: []
        });
      } else
        days.push({
          name: moment(date).format("DD MMMM YYYY"),
          method: i,
          icon: false,
          content: false,
          data: []
        });
    }

    let aheadCancelDays = [];
    for (let i = 0; i < 4; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      if (date.toJSON() === today.toJSON()) {
        aheadCancelDays.push({
          name: "วันนี้",
          method: i,
          icon: true,
          content: true,
          data: []
        });
      } else
        aheadCancelDays.push({
          name: moment(date).format("DD MMMM YYYY"),
          method: i,
          icon: false,
          content: false,
          data: []
        });
    }

    let prevCancelDays = [];
    for (let i = 0; i < 3; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i - 1);
      prevCancelDays.push({
        name: moment(date).format("DD MMMM YYYY"),
        method: i,
        icon: false,
        content: false,
        data: []
      });
    }
    // const yesterdayDate = new Date(today);
    // yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    // let yesterday = [];
    // yesterday.push({
    //   name: moment(yesterdayDate).format("DD MMMM YYYY"),
    //   method: 0,
    //   icon: false,
    //   content: false,
    //   data: []
    // })

    this.state = {
      'service': 'Lube',
      'days': days,
      'aheadCancelDays': aheadCancelDays,
      'prevCancelDays': prevCancelDays,
      // 'yesterday': yesterday
      'shownCancel': 'ahead',
      'position': null ,
      'stationId' : null,
      'loading': false
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    if (this.props.line) {
      const { userId } = this.props.line;
      await this.props.dispatch(fetchStaff(userId));
      const { status, data } = this.props.staff;
      if (status === 200) {
        const { stationId, name } = data.position;
        const serviceName = name === 'Lube,Carwash' ? 'Lube' : name
        this.fetchDataByService(stationId, serviceName)
        this.setState({ 'position': name , 'stationId': stationId })
      }
    }
  }

  fetchDataByService = async (stationId,serviceName) => {
    await this.props.dispatch(fetchOrderWeekly(stationId, serviceName));
        await this.props.dispatch(fetchStation(stationId));
        await this.props.dispatch(fetchAheadCancelOrder(stationId, serviceName));
        await this.props.dispatch(fetchPrevCancelOrder(stationId, serviceName));
        // await this.props.dispatch(fetchCancelOrderWeekly(stationId, name));
        // await this.props.dispatch(fetchOrderYesterday(stationId, name));
        const { orderWeekly, aheadCancelOrder, prevCancelOrder } = this.props;
        if (!(Object.entries(orderWeekly).length === 0 && orderWeekly.constructor === Object)) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const offset = new Date().getTimezoneOffset();
          today.setHours(today.getHours() - offset / 60);
          const days = JSON.parse(JSON.stringify(this.state.days));
          for (let i = 0; i <= 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            if (orderWeekly[date.toJSON()]) {
              days[i].data = orderWeekly[date.toJSON()];
            }
          }
          this.setState({ 'service': serviceName, 'days': days, 'loading': false  });
        }
        if (!(Object.entries(aheadCancelOrder).length === 0 && aheadCancelOrder.constructor === Object)) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const offset = new Date().getTimezoneOffset();
          today.setHours(today.getHours() - offset / 60);
          const aheadCancelDays = JSON.parse(JSON.stringify(this.state.aheadCancelDays));
          for (let i = 0; i < 4; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            if (aheadCancelOrder[date.toJSON()]) {
              aheadCancelDays[i].data = aheadCancelOrder[date.toJSON()];
            }
          }
          this.setState({ 'service': serviceName, 'aheadCancelDays': aheadCancelDays, 'loading': false  });
        }
        if (!(Object.entries(prevCancelOrder).length === 0 && prevCancelOrder.constructor === Object)) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const offset = new Date().getTimezoneOffset();
          today.setHours(today.getHours() - offset / 60);
          const prevCancelDays = JSON.parse(JSON.stringify(this.state.prevCancelDays));
          for (let i = 0; i < 3; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() - i - 1);
            if (prevCancelOrder[date.toJSON()]) {
              prevCancelDays[i].data = prevCancelOrder[date.toJSON()];
            }
          }
          this.setState({ 'service': serviceName, 'prevCancelDays': prevCancelDays, 'loading': false });
        }
        this.setState({ 'loading': false  });
  }

  nextPage = (orderId, name) => {
    this.props.dispatch(setViewedOrder(orderId));
    history.push({
      pathname: `${commonConstant.pathShellStaffLubeQueueDetail}/${orderId}`,
      state: { type: name}
    });
  }

  handleClick = (id, type) => {
    let days = '';
    switch (type) {
      case 'ahead-cancel':
        days = JSON.parse(JSON.stringify(this.state.aheadCancelDays));
        break;
      case 'prev-cancel':
        days = JSON.parse(JSON.stringify(this.state.prevCancelDays));
        break;
      // case 'yesterday':
      //   days = JSON.parse(JSON.stringify(this.state.yesterday));
      //   break;
      default:
        days = JSON.parse(JSON.stringify(this.state.days));
        break;
    }
    const i = parseInt(id);
    days[i].content = !days[i].content;
    days[i].icon = !days[i].icon;
    switch (type) {
      case 'ahead-cancel':
        this.setState({ 'aheadCancelDays': days });
        break;
      case 'prev-cancel':
        this.setState({ 'prevCancelDays': days });
        break;
      // case 'yesterday':
      //   this.setState({ 'yesterday': days });
      //   break;
      default:
        this.setState({ 'days': days });
        break;
    }
  }

  renderQueueItems = (day) => {
    if (day.data.length === 0) {
      return <div className="text-no-queue">ไม่มีรายการ</div>
    } else {
      return day.data.map((el, i) => (
        <tbody key={i} className={!el.isViewed ? "new-order" : null}>
          {!el.isViewed ? <label>รายการใหม่</label> : null}
          <tr
            key={i}
            onClick={() => this.nextPage(el.orderId, day.name)}
          >
            <td style={{ width: "30%" }}>{el.name}</td>
            <td style={{ width: "20%" }}>{el.licensePlate}</td>
            <td style={{ width: "18%" }}>{el.serviceTime}</td>
          </tr>
        </tbody>
      ));
    }
    
  }

  renderQueueContent = (day) => {
    if (day.content)
      return (
        <div className="content">
          <table>
            {this.renderQueueItems(day)}
          </table>
        </div>
      )
  }

  renderQueues = () => {
    const { days } = this.state;
    return days.map((day, i) => {
      const newQueue = day.data.some(e => e.isViewed === false);
      return (
        <div className="collapsible-box" key={i}>
          <button
            type="button"
            className="collapsible"
            onClick={() => this.handleClick(day.method)}
          >
            <div className="day">
              {day.name}
              <div id={i} className={newQueue && !day.icon ? "red-circle" : null}></div>
              <i className={day.icon ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}></i>
            </div>
          </button>
          {this.renderQueueContent(day)}
        </div>
      );
    });
  }

  renderAheadCancel = () => {
    const { aheadCancelDays } = this.state;
    return aheadCancelDays.map((day, i) => {
      const newQueue = day.data.some(e => e.isViewed === false);
      return (
        <div className="collapsible-box" key={i} >
          <button
            type="button"
            className="collapsible"
            onClick={() => this.handleClick(day.method, 'ahead-cancel')}
          >
            <div className="day">
              {day.name}
              <div id={i} className={newQueue && !day.icon ? "red-circle" : null}></div>
              <i className={day.icon ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}></i>
            </div>
          </button>
          {this.renderQueueContent(day)}
        </div>
      );
    });
  }

  renderPrevCancel = () => {
    const { prevCancelDays } = this.state;
    return prevCancelDays.map((day, i) => {
      const newQueue = day.data.some(e => e.isViewed === false);
      return (
        <div className="collapsible-box" key={i}>
          <button
            type="button"
            className="collapsible"
            onClick={() => this.handleClick(day.method, 'prev-cancel')}
          >
            <div className="day">
              {day.name}
              <div id={i} className={newQueue && !day.icon ? "red-circle" : null}></div>
              <i className={day.icon ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}></i>
            </div>
          </button>
          {this.renderQueueContent(day)}
        </div>
      )
    }
    )
  }

  // renderYesterdayQueues = () => {
  //   const { yesterday } = this.state;
  //   if (yesterday[0].data[0]) {
  //     return (
  //       <React.Fragment>
  //         <div className="yesterday-queue">
  //           <img src={process.env.REACT_APP_HOMEPAGE + `img/danger.svg`} alt="" />
  //           <p>คิวที่ค้างอยู่</p>
  //         </div>
  //         {yesterday.map((day, i) => (
  //           <div className="collapsible-box-yesterday" key={i}>
  //             <button
  //               type="button"
  //               className="collapsible-yesterday"
  //               onClick={() => this.handleClick(day.method, 'yesterday')}
  //             >
  //               {day.name}
  //               <i className={day.icon ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}></i>
  //             </button>
  //             {this.renderQueueContent(day, 'yesterday')}
  //           </div>
  //         ))}
  //       </React.Fragment>
  //     )
  //   }
  // }

  renderNotification = () => {
    if (this.props.location.state) {
      const { notification } = this.props.location.state;
      return (
        <div className="notification">
          <img src={process.env.REACT_APP_HOMEPAGE + `img/check.svg`} alt="check" />
          <p>{notification}</p>
        </div>
      );
    }
  }

  onClickTabService = (value) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const offset = new Date().getTimezoneOffset();
    today.setHours(today.getHours() - offset / 60);

    const days = [];
    for (let i = 0; i <= 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      if (date.toJSON() === today.toJSON()) {
        days.push({
          name: "วันนี้",
          method: i,
          icon: true,
          content: true,
          data: []
        });
      } else
        days.push({
          name: moment(date).format("DD MMMM YYYY"),
          method: i,
          icon: false,
          content: false,
          data: []
        });
    }

    const aheadCancelDays = [];
    for (let i = 0; i < 4; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      if (date.toJSON() === today.toJSON()) {
        aheadCancelDays.push({
          name: "วันนี้",
          method: i,
          icon: true,
          content: true,
          data: []
        });
      } else
        aheadCancelDays.push({
          name: moment(date).format("DD MMMM YYYY"),
          method: i,
          icon: false,
          content: false,
          data: []
        });
    }

    const prevCancelDays = [];
    for (let i = 0; i < 3; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i - 1);
      prevCancelDays.push({
        name: moment(date).format("DD MMMM YYYY"),
        method: i,
        icon: false,
        content: false,
        data: []
      });
    }
    this.setState({ 'service': value, 'loading': true ,'days': days,
    'aheadCancelDays': aheadCancelDays,
    'prevCancelDays': prevCancelDays, })
    this.fetchDataByService(this.state.stationId,value)
  }

  render() {
    const { language, line, staff, orderWeekly, station } = this.props;
    const { service, shownCancel, prevCancelDays, position, loading } = this.state;

    if (!language || !line || !staff || !orderWeekly || !service || !station || !position ) {
      return <Loading />;
    }

    return (
      <main className="staff-lube-queues">
        <div className={'header'}>
          <div className="logo">
            <img src={process.env.REACT_APP_HOMEPAGE + `img/staff-logo.svg`} alt="logo" />
          </div>
          <p>{content[language]['content-text1']}  {station.branchName}</p>
        </div>
        {this.renderNotification()}
        <div className="service-tab" >
          {position === "Lube,Carwash" || position === "Lube" ? <div onClick={() => service === "Carwash" && this.onClickTabService('Lube')} className={service === "Lube" ? "wrap-service-active" : "wrap-service"}>
          <img className="service-icons" style={{ 'width' : '25px' }} src={service === "Lube" ?  `/lineoa/img/lube-active.svg`: `/lineoa/img/lube.svg`} alt="service-icons-lube" />
          <p>{content[language]['content-text6']}</p>
          </div> : null}
         { position === "Lube,Carwash" || position === "Carwash" ? <div onClick={() => service === "Lube" && this.onClickTabService('Carwash')} className={service === "Carwash" ? "wrap-service-active" : "wrap-service"}>
          <img className="service-icons" style={{ 'width' : '25px'}} src={service === "Carwash" ? `/lineoa/img/carwash-active.svg` : `/lineoa/img/carwash.svg`} alt="service-icons-lube" />
          <p >{content[language]['content-text7']}</p>
          </div> : null}
        </div>
        <h1>{content[language]['content-text2']}</h1>
        <section className="contect-collapsible-yesterday">
          {/* {this.renderYesterdayQueues()} */}
        </section>
       { !loading ? 
       <div>
       <section className="contect-collapsible">
          {this.renderQueues()}
        </section>
        <section className="contect-collapsible-cancel">
          <p>{content[language]['content-text3']}</p>
          <div className="select-days-type">
            <button
              onClick={() => this.setState({ 'shownCancel': 'ahead' })}
              className={shownCancel === 'ahead' ? "days-button-active" : "days-button"}
            >
              {content[language]['content-text4']}
            </button>
            <button
              onClick={() => this.setState({ 'shownCancel': 'prev' })}
              className={shownCancel === 'prev' ? "days-button-active" : "days-button"}
            >
              {content[language]['content-text5']}
            </button>
          </div>
          {shownCancel === 'ahead' ? this.renderAheadCancel() : null}
          {shownCancel === 'prev' ? this.renderPrevCancel() : null}
        </section>
        </div> : 
        <Loading smallSize={true} />
        }
      </main>
    );
  }
}

StaffLubeQueues.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, staff, orderWeekly, aheadCancelOrder, prevCancelOrder, station }) => ({
  'language': language.data,
  'line': line.data,
  'staff': staff,
  'orderWeekly': orderWeekly.data,
  'aheadCancelOrder': aheadCancelOrder.data,
  'prevCancelOrder': prevCancelOrder.data,
  'station': station.data,
});

export default connect(mapStateToProps)(StaffLubeQueues);
