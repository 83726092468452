import commonConstant from '../../common/commonConstant';

export const staff = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.FETCH_STAFF_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FETCH_STAFF_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
