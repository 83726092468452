import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import commonConstant from '../../common/commonConstant';
import { changeTimeZone, getDateWithoutTime } from '../../common/commonFunction';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


import {
  setLanguage,
  fetchUser,
  fetchQueues,
  setOrder,
  fetchTransaction,
  fetchRecentTransaction,
  setQueueEdit,
  createWalkinQrCode,
  updateEditNum,
  fetchStaff,
  fetchStation,
} from '../../actions';
import { Loading } from '../../components';
import { FormDate, FormCarDetails, FormUserDetails, FormSelectPackage } from './Forms';

import './CarwashBooking.scss';
import content from './Language';
import { history } from '../../helpers';
import Forms from './Forms/Forms';

const liff = window.liff;

class CarwashBooking extends Component {
  constructor(props) {
    super(props);
    const today = changeTimeZone(new Date());
    const sevenDays = new Date(today);
    sevenDays.setDate(today.getDate() + 7);
    this.state = {
      'showModalInfo': false,
      'edit': false,
      'loading': false,
      'today': today.toJSON().slice(0, 10),
      'sevenDays': sevenDays.toJSON().slice(0, 10),
      'selectServiceTime': [{ 'text': 'เลือกเวลา', 'value': '', 'hidden': true }],
      'firstName': this.defaultData(),
      'lastName': this.defaultData(),
      'phone': this.defaultData(),
      'serviceDate': this.defaultData(),
      'serviceTime': this.defaultData(),
      'servicePackage': this.defaultData(),
      'licensePlate': this.defaultData(),
      'brand': this.defaultData(),
      'brandETC': this.defaultData(),
      'model': this.defaultData(),
      'code': this.defaultData(),
      'service': 'Carwash',
      'staff': 0,
      'walkIn': false,
      'brands': [
        'บีเอ็มดับเบิลยู',
        'เชฟโรเลต',
        'ฟอร์ด',
        'ฮอนด้า',
        'อีซูซุ',
        'แลนด์โรเวอร์',
        'มาสด้า',
        'เอ็มจี',
        'มินิ',
        'มิตซูบิชิ',
        'นิสสัน',
        'โปรตอน',
        'ซูบารุ',
        'ซูซูกิ',
        'ทาทา',
        'โตโยต้า',
        'โฟล์คสวาเกน',
        'วอลโว่',
        'อื่นๆ',
      ],
      'queueError': false,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    const { line, user, queues } = this.props;
    await this.props.dispatch(fetchStaff(line.userId));
    const { staff } = this.props;
    if (this.props.location.state) {
      const { service, orderId, walkIn } = this.props.location.state;
      if (service) { this.setState({ 'service': service }); }
      if (staff) { this.setState({ 'staff': 1 }) }
      if (orderId) {
        await this.props.dispatch(fetchTransaction(orderId));
        this.setState({ 'edit': orderId });
      }
      if (walkIn) { 
        this.setState({ 'walkIn': true }); 
        if (queues){
          const today = new Date();
          this.setState({ 'serviceDate': this.defaultData(new Date(getDateWithoutTime(today))) });
          this.setState(this.setWalkInServiceTime(today));
        }
      }
    }

    if (line && !user) {
      const { userId } = line;
      await this.props.dispatch(fetchUser(userId));
    }

    const { transaction } = this.props;
    const stateOrderId = this.props.location.state.orderId;
    if (transaction && queues && transaction.status === 'Booking') {this.setDefaultTransaction();}
    else if (!this.state.staff && !stateOrderId) {
      await this.props.dispatch(fetchRecentTransaction(line.userId));
      const { recentTransaction } = this.props;
      if (recentTransaction) {
        const { licensePlate, brand, model, firstName, phone, lastName } = recentTransaction;
        this.setState({
          'licensePlate': this.defaultData(licensePlate),
          'brand': this.defaultData(brand),
          'model': this.defaultData(model),
          'firstName': this.defaultData(firstName),
          'lastName': this.defaultData(lastName),
          'phone': this.defaultData(`0${phone.substring(3)}`),
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.staff && (this.props.user !== nextProps.user) && nextProps.user) {
      const { firstName, lastName, phone } = nextProps.user;
      if (firstName, phone) {
        this.setState({
          'firstName': this.defaultData(firstName),
          'lastName': this.defaultData(lastName),
          'phone': this.defaultData(`0${phone.substring(3)}`),
        });
      }
    }
  }

  async componentDidUpdate(nextProps) {
    const { edit, walkIn } = this.state;
    const { line, user, queues, transaction } = this.props;
    const { orderId } = this.props.location.state;
    if (line && user && !queues) {
      const { stationId } = user;
      await this.props.dispatch(fetchQueues(stationId, 'Carwash'));
      await this.props.dispatch(fetchStation(stationId));
      if (edit && transaction && transaction.status === 'Booking') {this.setDefaultTransaction();}
      else if (edit && transaction && transaction.status !== 'Booking') {
        history.replace(commonConstant.pathShellCarwashNot, { 'service': transaction.service, 'status': transaction.status, 'type': 'edit', orderId });
      }
    }
    if (line && user && queues && !transaction && edit) {
      await this.props.dispatch(fetchTransaction(edit));
      this.setDefaultTransaction();
    }

    if ((queues !== nextProps.queues) && !edit && walkIn) {
      const today = new Date();
      this.setState({ 'serviceDate': this.defaultData(new Date(getDateWithoutTime(today))) });
      this.setState(this.setWalkInServiceTime(today));
    }
  }

  setDefaultTransaction = async () => {
    const { staff } = this.props;
    const stateOrderId = this.props.location.state.orderId;
    const { licensePlate, brand, model, firstName, lastName, phone, serviceTime, serviceDate, status, service, orderId, package : carwashPackage  } = this.props.transaction;
    if (!staff && (status !== 'Booking' && status !== 'Walk-in' && stateOrderId) || getDateWithoutTime(new Date()) > getDateWithoutTime(serviceDate)) { return history.replace(commonConstant.pathShellCarwashNot, { service, status, 'type': 'edit', orderId }); }
    const checkBrand = this.state.brands.some(ele => {
     return ele === brand
    })
    this.setState({
      'edit': !staff || stateOrderId ? { serviceTime, 'serviceDate': getDateWithoutTime(serviceDate) } : null,
      'firstName': this.defaultData(firstName),
      'lastName': this.defaultData(lastName),
      'phone': this.defaultData(`0${phone.substring(3)}`),
      'serviceTime': this.defaultData('Default'),
      'serviceDate': this.defaultData(new Date(getDateWithoutTime(serviceDate))),
      'licensePlate': this.defaultData(licensePlate),
      'brand': !checkBrand ? this.defaultData('อื่นๆ') : this.defaultData(brand),
      'brandETC': !checkBrand ? this.defaultData(brand) : '',
      'model': this.defaultData(model),
      'servicePackage': this.defaultData(carwashPackage),
      'service': service,
    });
    if (!staff || stateOrderId) {
      this.setState(this.setServiceTimeByDate(getDateWithoutTime(serviceDate)));
    } else {
      const today = new Date();
      this.setState(this.setWalkInServiceTime(today));
    }
  }

  defaultData = (val = '', error = null) => ({ 'value': val ? val : '', 'error': error });

  setWalkInServiceTime = (today) => {
    const hours = today.getHours();
    const tmp = [];
    if (hours <= 16) {
      tmp.push({
        'text': `${hours}:00 - ${hours + 1}:00`,
        'value': `${hours}:00 - ${hours + 1}:00`,
      });
      this.setState({ 'selectServiceTime': tmp, 'serviceTime': this.defaultData(`${hours}:00 - ${hours + 1}:00`) });
    } else { return; }
  }

  setServiceTimeByDate = (val) => {
    const { queues, language } = this.props;
    const { servicePackage } = this.state;
    const newDate = new Date();
    const currentHours = newDate.getHours();
    const currentMinutes = newDate.getMinutes();
    const { today, sevenDays, edit } = this.state;
    const filterQueue = queues.filter(element => {
      const d1 = changeTimeZone(val).toJSON().split('T');
      const d2 = changeTimeZone(element.serviceDate)
        .toJSON()
        .split('T');
      return d1[0] === d2[0];
    });
    this.setState({ 'selectServiceTime': [] });
    if (!(val >= today && val <= sevenDays)) {
      return {
        'serviceTime': this.defaultData(''),
        'serviceDate': this.defaultData(val, content[language]['error-text1']),
      };
    }
    const newQueue = filterQueue.filter((ele) => {
      if (servicePackage.value === 'Crystal-Coating') {
        return ele.package === 'Crystal-Coating'
      } else {
        return ele.package !== 'Crystal-Coating'
      }
    })
    if (newQueue.length > 0) {
      const tmp = [];
      let added = false;
      newQueue.forEach((element, i) => {
        const elementTimeArr = element.serviceTime.split(' - ');
        const elementHours = elementTimeArr[0].split(':');
        if (edit && !added) {
          added = true;
          tmp.push({
            'text': `${edit.serviceTime}`,
            'value': 'Default',
          });
        } 
        if (i === 0) {
          tmp.push({
            'text': 'กรุณาเลือก',
            'value': '',
            'hidden': true,
          });
        }
        // if (edit.serviceTime !== element.serviceTime || getDateWithoutTime(edit.serviceDate) !== getDateWithoutTime(val)) {
          if (getDateWithoutTime(val) === getDateWithoutTime(today)) {
            if ((parseInt(elementHours[0]) === currentHours) && (servicePackage.value === 'Crystal-Coating' ? currentMinutes < 30 :currentMinutes < 1)) {
              tmp.push({
                'value': element.serviceTime,
                'text': element.serviceTime,
                'isActive': element.isActive,
                'queueNumber': element.queueNumber,
              });
            } else if (parseInt(elementHours[0]) > currentHours) {
              tmp.push({
                'value': element.serviceTime,
                'text': element.serviceTime,
                'isActive': element.isActive,
                'queueNumber': element.queueNumber,
              });
            }
            else {
              tmp.push({
                'value': element.serviceTime,
                'text': element.serviceTime,
                'isActive': element.isActive,
                'late': true,
                'queueNumber': element.queueNumber,
              });
            }
          } else {
            tmp.push({
              'value': element.serviceTime,
              'text': element.serviceTime,
              'isActive': element.isActive,
              'queueNumber': element.queueNumber,
            });
          }
        // }
      });
      return {
        'serviceTime': this.defaultData(edit ? 'Default' : '', null),
        'serviceDate': this.defaultData(new Date(val), null),
        'selectServiceTime': tmp,
      };
    } else {
      return {
        'serviceTime': this.defaultData(''),
        'serviceDate': this.defaultData(new Date(val), content[language]['error-text2']),
        'selectServiceTime': [{
          'value': '',
          'text': 'เลือกเวลา',
          'hidden': true,
        }],
      };
    }
  }

  handleEvent = (event, key) => {
    let newValue = this.state[key];
    let val = null, regExpr = null;
    switch (key) {
      case 'firstName':
        val = event.target.value;
        regExpr = new RegExp('^[\u0E00-\u0E3A\u0E40-\u0E4Da-zA-Z ]*$');
        if (regExpr.test(val)) { newValue = { 'value': val, 'error': null }; }
        break;
      case 'lastName':
        val = event.target.value;
        regExpr = new RegExp("^[\u0E00-\u0E7Fa-zA-Z' ]*$");
        if (regExpr.test(val)) { newValue = { 'value': val, 'error': null }; }
        break;
      case 'phone':
        val = event.target.value;
        regExpr = new RegExp('^[0-9]*$');
        if (regExpr.test(val) && (val[0] === '0' || val[0] === undefined)) { newValue = { 'value': val, 'error': null }; }
        break;
      case 'serviceDate':
        val = event;
        newValue = this.setServiceTimeByDate(moment(val).format('YYYY-MM-DD'));
        this.setState({ ...newValue, 'serviceTime':  this.defaultData() });
        break;
      case 'licensePlate':
        val = event.target.value;
        regExpr = new RegExp('^[\u0E00-\u0E2F0-9 ]*$');
        if (regExpr.test(val)) { newValue = { 'value': val, 'error': null }; }
        break;
      default:
        val = event.target.value;
        newValue = { 'value': val, 'error': null };
    }
    if (key !== 'serviceDate') { this.setState({ [key]: newValue }); }
  };

  handleSubmitNew = async (walkIn) => {
    this.setState({ 'loading': true });
    const { user, language } = this.props;
    const { staff, service } = this.state;
    if (!staff && user.cancelNum === 0) { return history.replace(commonConstant.pathShellCarwashNot, { service, 'status': 'cancel-over', 'type': 'cancel-over' }); }
    const request = {
      'firstName': this.state.firstName.value,
      'lastName': this.state.lastName.value,
      'name': `${this.state.firstName.value} ${this.state.lastName.value}`,
      'lineId': user.lineId,
      'phone': `+66${this.state.phone.value.substring(1)}`,
      'licensePlate': this.state.licensePlate.value,
      'status': walkIn ? 'Walk-in' : 'Booking',
      'service': this.state.service,
      'serviceDate': this.state.serviceDate.value,
      'serviceTime': this.state.serviceTime.value,
      'stationId': user.stationId,
      'brand': this.state.brand.value === 'อื่นๆ'
        ? this.state.brandETC.value
        : this.state.brand.value,
      'model': this.state.model.value,
      'code': this.state.code.value,
      'package': this.state.servicePackage.value
    };
    await this.props.dispatch(setOrder(request, staff));

    if (walkIn) {
      const { data } = this.props.order;
      request.oldQueueId = data.queueId;
      request.orderId = data.orderId;
      request.staff = this.state.staff;
      request.userId = user.userId;
      this.props.dispatch(setQueueEdit(request));
    }

    const { status, data } = this.props.order;

    switch (status) {
      case 200:
        if (walkIn) {
          await this.props.dispatch(createWalkinQrCode(data.orderId, user.stationId));
          return history.replace(`${commonConstant.pathShellWalkInDetail}/${data.orderId}`, { service });
        }
        else {
          return history.replace(`${commonConstant.pathShellCarwashDetail}/${data.orderId}`, { 'notification': content[language]['content-text13'] });
        }
      default:
        this.props.dispatch(fetchQueues(user.stationId, 'Carwash'));
        this.setState({
          'loading': false,
          'serviceDate': this.defaultData('', 'คิวเต็มแล้ว'),
          'serviceTime': this.defaultData(''),
          'selectServiceTime': [
            {
              'value': '',
              'text': 'เลือกเวลา',
              'hidden': true,
            },
          ],
          'queueError': true,
        });
    }
  }

  handleSubmitEdit = async (walkIn) => {
    this.setState({ 'loading': true });
    const { user, transaction, language } = this.props;
    const { serviceTime, orderId, userId, queueId, service, editNum } = transaction;
    const { staff } = this.state;
    if (!staff && editNum === 0) { return history.replace(commonConstant.pathShellCarwashNot, { service, 'status': 'edit-over', 'type': 'edit-over', orderId }); }
    const request = {
      'orderId': orderId,
      'firstName': this.state.firstName.value,
      'lastName': this.state.lastName.value,
      'name': `${this.state.firstName.value} ${this.state.lastName.value}`,
      'lineId': user.lineId,
      'phone': `+66${this.state.phone.value.substring(1)}`,
      'licensePlate': this.state.licensePlate.value,
      'status': walkIn ? 'Walk-in' : 'Booking',
      service,
      'serviceDate': this.state.serviceDate.value,
      'serviceTime': this.state.serviceTime.value === 'Default'
        ? serviceTime
        : this.state.serviceTime.value,
      'stationId': user.stationId,
      'brand': this.state.brand.value === 'อื่นๆ'
        ? this.state.brandETC.value
        : this.state.brand.value,
      'model': this.state.model.value,
      'staff': this.state.staff,
      'oldQueueId': queueId,
      'userId': userId,
      'code': this.state.code.value,
      'package': this.state.servicePackage.value
    };
    const notification = staff ? content[language]['content-text14'] : content[language]['content-text15'];
    await this.props.dispatch(setQueueEdit(request));
    if (!staff) { await this.props.dispatch(updateEditNum({ orderId })); }
    const { status } = this.props.queueEdit;
    this.setState({ 'loading': false });
    switch (status) {
      case 200:
        if (staff) {
          if (walkIn) {
            return history.replace(`${commonConstant.pathShellWalkInDetail}/${orderId}`, { service });
          }
          else {
            return history.replace(`${commonConstant.pathShellCarwashDetail}/${orderId}`, { notification });
          }
        }
        else {
          return history.replace(`${commonConstant.pathShellCarwashDetail}/${orderId}`, { notification });
        }
      default:
        this.props.dispatch(fetchQueues(user.stationId, 'Carwash'));
        this.setState({
          'loading': false,
          'serviceDate': this.defaultData('', 'คิวเต็มแล้ว'),
          'serviceTime': this.defaultData(''),
          'selectServiceTime': [
            {
              'value': '',
              'text': '-',
              'hidden': true,
            },
          ],
        });
    }
  }

  handleSubmit = () => {
    const { edit, walkIn } = this.state;
    edit ? this.handleSubmitEdit(walkIn) : this.handleSubmitNew(walkIn);
  }

  handleCancel = () => liff.closeWindow();

  watchBtnSubmit = () => {
    const { brand, brandETC, model, licensePlate, service, serviceDate,
      serviceTime, firstName, phone, lastName,
    } = this.state;
    const plateRegex = /(^[0-9]?[ก-ฮ][ก-ฮ]?[0-9]{1,4}$)|(^[0-9]{4,6}$)/g;
    if (brand.value.trim() !== '' && model.value.trim() !== '' && licensePlate.value.trim() !== '' && licensePlate.value.match(plateRegex) && serviceDate.value !== '' && serviceTime.value !== '' && firstName.value.trim() !== '' && lastName.value.trim() !== '' && phone.value.trim() !== '' && phone.value.length === 10) {
      if (brand.value === 'อื่นๆ' && brandETC.value === '') {
        return { 'disabled': true, 'btnSubmit': 'disable' };
      } else {
        return {
          'disabled': false,
          'btnSubmit': service === 'Carwash' ? 'yellow' : 'blue',
        };
      }
    } else {
      return { 'disabled': true, 'btnSubmit': 'disable' };
    }
  }

  renderBrands = () => {
    const { brands } = this.state;
    return brands.map((el, i) => <option key={i} value={el}>{el}</option>);
  }

  renderServiceTimes = () => {
    const { selectServiceTime, staff, servicePackage } = this.state;
    return selectServiceTime.map((el, i) => <option key={i} value={el.value} hidden={el.hidden} disabled={!el.isActive || el.late || el.queueNumber <= 0 }>
      <div className="serviceTime-option">
        <div>
          {el.text}
        </div>
        {
          (el.queueNumber || el.queueNumber === 0 ) && servicePackage.value !== 'Crystal-Coating'?
            <div className={el.queueNumber > 0 ? 'queue-available' : 'queue-full'}>{el.queueNumber > 0 ? `เหลือ ${el.queueNumber} คิว` : 'คิวเต็ม'}</div> 
            : null
        }
      </div>
    </option>);
  }

  onSelectPackage = (val) => {
    if (this.state.edit) {
      this.setState({ 'servicePackage':  this.defaultData(val), 'serviceDate': this.defaultData(),'serviceTime': this.defaultData()});
    } else {
      this.setState({ 'servicePackage':  this.defaultData(val) });
    }
  }

  onSwitchModalInfo = () => {
    this.setState({ 'showModalInfo':  !this.state.showModalInfo })
  }

  isDisabledSelectPackage = (val) => {
    const afterHalfDay = moment().isBefore(moment().hour(13));
   
    const { queues} = this.props;
    const { servicePackage, serviceDate, walkIn } = this.state;

    if (queues) {
    if (walkIn) {
      if (val === 'Crystal-Coating') {
        if (!afterHalfDay) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }

  }
   
  }

  getExcludeDates = () => {
    const { queues} = this.props;
    const { servicePackage, serviceDate } = this.state;
    if (queues) {
      const beforeHalfDay = moment().isBefore(moment().hour(13));
      const filterQueue = queues.filter((ele) => {
        if (!beforeHalfDay) {
          return ele.package === 'Crystal-Coating' && ele.queueNumber === 0 || moment(ele.serviceDate).format('DD') === moment().format('DD')
        } else {
          return ele.package === 'Crystal-Coating' && ele.queueNumber === 0
        }
      })
      const mapFilterQueue = filterQueue.map((ele) => {
        return new Date(ele.serviceDate)
      })
      if (servicePackage.value === 'Crystal-Coating') {
        return mapFilterQueue
      } else {
        return [new Date()]
      }
    }
    
  }


  render() {
    const { language, user, queues, station, transaction } = this.props;
    const btnSubmit = this.watchBtnSubmit();
    const {
      edit,
      firstName,
      lastName,
      phone,
      licensePlate,
      brand,
      brands,
      brandETC,
      model,
      code,
      service,
      serviceDate,
      serviceTime,
      walkIn,
      staff,
      queueError,
      loading,
      servicePackage,
      showModalInfo
    } = this.state;

    const formValues = {
      edit,
      firstName,
      lastName,
      phone,
      licensePlate,
      brand,
      brandETC,
      model,
      code,
      service,
      serviceDate,
      serviceTime,
      walkIn,
      staff,
      servicePackage
    };
    const items = [
      <img src={`/lineoa/img/carwash-promotion-1.png`} className="image-info" alt="image-promotion-1" />,
  <img src={`/lineoa/img/carwash-promotion-2.png`} className="image-info" alt="image-promotion-2" />,
  <img src={`/lineoa/img/carwash-promotion-3.png`} className="image-info" alt="image-promotion-3" />,
    ];

    if (!language || !user || !queues || (edit && !transaction) || !service || !station || loading) {
      return <Loading />;
    }

    const functions = {
      'setServiceTimeByDate': this.setServiceTimeByDate,
      'handleEvent': this.handleEvent,
      'handleSubmitNew': this.handleSubmitNew,
      'handleSubmitEdit': this.handleSubmitEdit,
      'handleSubmit': this.handleSubmit,
      'handleCancel': this.handleCancel,
      'renderBrands': this.renderBrands,
      'renderServiceTimes': this.renderServiceTimes,
      'onSelectPackage': this.onSelectPackage,
      'isDisabledSelectPackage': this.isDisabledSelectPackage,
      'excludeDates': this.getExcludeDates,
      'onSwitchModalInfo': this.onSwitchModalInfo
    };

    if (edit) {
    
      return (
        <section className="carwash-booking">
           {showModalInfo && <div className="modal-info">
           <div className="wrap-image-info">
  <p onClick={() => this.onSwitchModalInfo()}>X ปิด</p>
  <AliceCarousel mouseTracking items={items} disableButtonsControls />
  <img className="image-info" src={`/lineoa/img/shell-menu-info2.jpeg`} alt="image-info-5" />
  <img className="image-info" src={`/lineoa/img/shell-menu-info1.jpeg`} alt="image-info-1" />
<img className="image-info" src={`/lineoa/img/carwash-menu-s.png`} alt="image-info-2" />
<img className="image-info" src={`/lineoa/img/carwash-menu-m.png`} alt="image-info-3" />
<img className="image-info" src={`/lineoa/img/carwash-menu-l.png`} alt="image-info-4" />
</div>
</div>
}
          <div className={staff === 1 ? 'header' : 'header blue'}>
            <div className="logo">
              <img src={staff === 1 ? `/lineoa/img/staff-logo.svg` : `/lineoa/img/carwash-logo.svg`} alt="logo" />
            </div>
            <p className>{content[language]['content-text16']} {station.branchName}</p>
          </div>
          <FormSelectPackage formValues={formValues} nextStep={this.nextStep} functions={functions} />
          <div className="line-divider" />
          <div className="middle">
            <p className="content-edit">
            เปลี่ยนแปลงการจอง การเข้ารับบริการ
            </p>
          </div>
          <FormDate formValues={formValues} nextStep={this.nextStep} functions={functions} />
          <div className="line-divider" />
          <FormCarDetails formValues={formValues} nextStep={this.nextStep} functions={functions} />
          <div className="line-divider" />
          <FormUserDetails formValues={formValues} functions={functions} />
          <div className="btn">
            <input
              type="button"
              className={btnSubmit.btnSubmit}
              onClick={() => this.handleSubmit()}
              value={content[language]['button-text2']}
              disabled={btnSubmit.disabled}
            />
            <input
              type="button"
              className="cancel"
              onClick={() => this.handleCancel()}
              value={content[language]['button-text3']}
            />
          </div>
        </section>
      );
    }
    else {
      return (
        <section className="carwash-booking">
           {showModalInfo && <div className="modal-info">
           <div className="wrap-image-info">
  <p onClick={() => this.onSwitchModalInfo()}>X ปิด</p>
  <AliceCarousel mouseTracking items={items} disableButtonsControls />
  <img className="image-info" src={`/lineoa/img/shell-menu-info2.jpeg`} alt="image-info-5" />
  <img className="image-info" src={`/lineoa/img/shell-menu-info1.jpeg`} alt="image-info-1" />
<img className="image-info" src={`/lineoa/img/carwash-menu-s.png`} alt="image-info-2" />
<img className="image-info" src={`/lineoa/img/carwash-menu-m.png`} alt="image-info-3" />
<img className="image-info" src={`/lineoa/img/carwash-menu-l.png`} alt="image-info-4" />
</div>
</div>
}
          <div className={walkIn || staff === 1 ? 'header' : 'header blue'}>
            <div className="logo">
            <img src={walkIn || staff === 1 ? `/lineoa/img/staff-logo.svg` :`/lineoa/img/carwash-logo.svg`} alt="logo" />
            </div>
            <p>{content[language]['content-text16']} {station.branchName}</p>
          </div>
          <div className="middle">
            <p className="content-1">
              {content[language]['header-carwash']}
            </p>
          </div>
          <Forms formValues={formValues} functions={functions} errorStep={queueError ? 1 : false} />
        </section>
      );
    }
  }
}

CarwashBooking.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, user, queues, order, transaction, recentTransaction, queueEdit, staff, station }) => ({
  'language': language.data,
  'line': line.data,
  'user': user.data,
  'queues': queues.data,
  'order': order,
  'transaction': transaction.data,
  'recentTransaction': recentTransaction.data,
  'queueEdit': queueEdit,
  'staff': staff.data,
  'station': station.data,
});

export default connect(mapStateToProps)(CarwashBooking);
