import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';
import { changeFormatDate3 } from '../../common/commonFunction';

import {
  setLanguage,
  fetchTransaction,
  fetchCallCustomer,
  setOrderStaff,
} from '../../actions';
import { Loading } from '../../components';

import './StaffLubeProcessingDetail.scss';
import content from './Language';
import { history } from '../../helpers';

const sameDay = (d1, d2) => d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

class StaffLubeProcessingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'service': null,
      'inService': null,
      'modal': false,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    const { orderId } = this.props.match.params;
    await this.props.dispatch(fetchTransaction(orderId));
    if (this.props.transaction) {
      const { service, status } = this.props.transaction;
      this.setState({ 'service': service, 'inService': status === 'InService' ? true : false });
    }
  }

  submitSuccess = async () => {
    const { orderId } = this.props.match.params;
    await this.props.dispatch(setOrderStaff(orderId));
    const { status } = this.props.orderStaff;
    switch (status) {
      case 200:
        history.replace(commonConstant.pathShellStaffLubeProcessing, { 'notification': 'ลูกค้ารับบริการสร็จสิ้นแล้ว' });
        break;
      default:
        this.setState({ 'loading': false });
    }
    // return history.replace(`${commonConstant.pathShellStaffLubeProcessingCompleted}/${orderId}`);
  }

  submitCallCustomer = async () => {
    const { orderId } = this.props.match.params;
    await this.props.dispatch(fetchCallCustomer(orderId));
    const { status } = this.props.statusOrderCallCustomer;
    switch (status) {
      case 200:
        return history.replace(`${commonConstant.pathShellStaffLubeCallCustomer}/${orderId}`);
      default:
        alert('ระบบขัดข้อง');
    }
  }

  renderModal = () => {
    const { modal } = this.state;
    if (modal)
    {return (
      <div className="shell-modal">
        <div className="modal-content">
          <p>ยืนยัน การแจ้งเตือนลูกค้าเข้ารับรถ</p>
          <div className="btn">
            <button className="cancel" onClick={() => this.setState({ 'modal': false })}>ยกเลิก</button>
            <button className="confirm" onClick={() => this.submitCallCustomer()} >ยืนยัน</button>
          </div>
        </div>
      </div>
    );}
  }

  render() {
    const { language, line, transaction, station } = this.props;
    const { service } = this.state;

    if (!language || !line || !transaction || !service) {
      return <Loading />;
    }

    const { name, 'phone': oldPhone, 'serviceDate': oldServiceDate, 'serviceTime': BookedTime, branchName, licensePlate, brand, model, callCustomer,transactionDate, 'package' : servicePackage, firstName, lastName } = transaction;
    const date = new Date(transactionDate);
    const serviceDateObj = new Date(oldServiceDate);
    const isSameDay = sameDay(date,serviceDateObj);
    const phone = [`0${oldPhone.substring(3)}`.slice(0, 3), '-', `0${oldPhone.substring(3)}`.slice(3)].join('');
    const serviceDate = changeFormatDate3(isSameDay ? oldServiceDate : transactionDate);
    const hours = date.getHours();
    const serviceTime = `${hours}:00 - ${hours + 1}:00`;

    return (
      <main className="staff-lube-processing-detail">
          <div className={'header'}>
          <div className="logo">
            <img src={`/lineoa/img/staff-logo.svg`} alt="logo" />
          </div>
          <p>{content[language]['content-text11']}  {station.branchName}</p>
        </div>
        {this.renderModal()}
        <div className="wrap-content">
          <div>
          <p className="header-text">{content[language]['content-text12']}</p>
        <div className="box-1">
          <div className="detail">
            <div className="name">
            <p>{content[language]['content-text1']}</p>
             <p>{content[language]['content-text2']}</p>
             <p>{content[language]['content-text3']}</p>
             <p>{content[language]['content-text4']}</p>
             <p>{content[language]['content-text5']}</p>
            {servicePackage && <p>{content[language]['content-text10']}</p>}
            </div>
            <div className="value">
              <p>{`${firstName} ${lastName}`}</p>
             <a className="phone-link" href={`tel:${phone}`}>{phone}</a>
             <p>{brand}</p>
             <p>{model}</p>
             <p>{licensePlate}</p>
             {servicePackage && <p>{servicePackage.toUpperCase() || '-'}</p>}
            </div>
          </div>
        </div>
        <div className="box-2">
          <div className="detail">
            <div className="name">
              <p>{content[language]['content-text6']}</p>
              <p>{content[language]['content-text7']}</p>
              <p>{content[language]['content-text8']}</p>
            </div>
            <div className="value">
              <p>{serviceDate}</p>
              <p>{serviceTime}</p>
              <p>{branchName}</p>
            </div>
          </div>
        </div>
        </div>
        
        <footer className="footer">
          <button
            className={'yellow'}
            onClick={() => this.setState({ 'modal': true })}
            disabled={!callCustomer}>
            {content[language]['button-text1']}
          </button>

          <button
            className={'yellow'}
            onClick={() => this.submitSuccess()}
          >
            {content[language]['button-text2']}
          </button>
        </footer>
        </div>
      </main>
    );
  }
}

StaffLubeProcessingDetail.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, transaction, statusOrderCallCustomer, orderStaff, station }) => ({
  'language': language.data,
  'line': line.data,
  'transaction': transaction.data,
  'statusOrderCallCustomer': statusOrderCallCustomer,
  'orderStaff': orderStaff,
  'station': station.data,
});

export default connect(mapStateToProps)(StaffLubeProcessingDetail);
