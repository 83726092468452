import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';
import { changeFormatDate3 } from '../../common/commonFunction';

import {
  setLanguage,
  fetchTransaction,
  setStatusOrder,
  fetchStaff,
} from '../../actions';
import { Loading } from '../../components';

import './StaffLubeQueueDetail.scss';
import content from './Language';
import { history } from '../../helpers';

const liff = window.liff;

const sameDay = (d1, d2) => d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

class StaffLubeQueueDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'service': null,
      'cancelModal': false,
      'confirmModal': false,
      'radios': '',
      'etcNote': '',
      'loading': false,
      'notification' : ''
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
    const { language } = this.props;
    this.setState({ 'radios': content[language]['modal-text2'] });
  }

  async componentDidMount() {
    const { orderId } = this.props.match.params;
    await this.props.dispatch(fetchTransaction(orderId));
    const { transaction } = this.props;
    const { userId } = this.props.line;
    await this.props.dispatch(fetchStaff(userId));

    if (transaction)
    {this.setState({ 'service': this.props.transaction.service });}
    const { status } = transaction;
    if (status === 'InService') {history.replace(`${commonConstant.pathShellStaffLubeProcessingDetail}/${orderId}`);}
  }

  setOrder = async (orderId) => {
    await this.props.dispatch(fetchTransaction(orderId));
    const { transaction } = this.props;
    const { userId } = this.props.line;
    await this.props.dispatch(fetchStaff(userId));

    if (transaction)
    {this.setState({ 'service': this.props.transaction.service });}
    const { status } = transaction;
    if (status === 'InService') {history.replace(`${commonConstant.pathShellStaffLubeProcessingDetail}/${orderId}`);}

    this.setState({ 'loading': false });
  }

  checkIn = async () => {
    const { staffId, lineId } = this.props.staff;
    const { orderId, stationId, queueId } = this.props.transaction;
    await this.props.dispatch(setStatusOrder({
      'name': 'InService',
      orderId,
      stationId,
      staffId,
      queueId,
      'staffLineId': lineId,
    }));
    const { status } = this.props.statusOrder;
    switch (status) {
      case 200:
        history.replace(commonConstant.pathShellStaffLubeProcessing);
        break;
      default:
        this.setState({ 'loading': false });
    }
  }

  cancelAndGoToProcessing = async () => {
    const { staffId, lineId } = this.props.staff;
    const { orderId, stationId, queueId } = this.props.transaction;
    const { radios, etcNote } = this.state;
    const { language } = this.props;
    const note =
      radios === content[language]['modal-text4'] ? `${radios} (${etcNote})` : radios;
    await this.props.dispatch(setStatusOrder({
      'name': 'Cancel',
      orderId,
      stationId,
      staffId,
      queueId,
      'staffLineId': lineId,
      note,
    }));
    const { status } = this.props.statusOrder;
    switch (status) {
      case 200:
        history.replace(commonConstant.pathShellStaffLubeProcessing, { 'notification': content[language]['noti-text1'] });
        break;
      default:
        this.setState({ 'loading': false });
    }
  }

  cancelAndGoToDetail = async () => {
    const { staffId, lineId } = this.props.staff;
    const { orderId, stationId, queueId } = this.props.transaction;
    const { radios, etcNote } = this.state;
    const { language } = this.props;
    const note =
      radios === content[language]['modal-text4'] ? `${radios} (${etcNote})` : radios;
    await this.props.dispatch(setStatusOrder({
      'name': 'Cancel',
      orderId,
      stationId,
      staffId,
      queueId,
      'staffLineId': lineId,
      note,
    }));
    const { status } = this.props.statusOrder;
    switch (status) {
      case 200:
        this.setState({ 'loading': true, 'cancelModal': false , 'notification': content[language]['noti-text1'] });
        this.setOrder(orderId)
        break;
      default:
        this.setState({ 'loading': false });
    }
  }

  renderNotification = () => {
      const { notification } = this.state;
      if (notification) {
      return (
        <div className="notification">
          <img src={process.env.REACT_APP_HOMEPAGE + `img/check.svg`} alt="check" />
          <p>{notification}</p>
        </div>
      );
    }
  }

  cancelAndGoToQueue = async () => {
    const { staffId, lineId } = this.props.staff;
    const { orderId, stationId, queueId } = this.props.transaction;
    const { radios, etcNote } = this.state;
    const { language } = this.props;
    const note =
      radios === content[language]['modal-text4'] ? `${radios} (${etcNote})` : radios;

    await this.props.dispatch(setStatusOrder({
      'name': 'Cancel',
      orderId,
      stationId,
      staffId,
      queueId,
      'staffLineId': lineId,
      note,
    }));
    const { status } = this.props.statusOrder;
    switch (status) {
      case 200:
        history.replace(commonConstant.pathShellStaffLubeQueues, { 'notification': content[language]['noti-text2'] });
        break;
      default:
        this.setState({ 'loading': false });
    }
  }

  editPage = () => {
    if (this.state.service === 'Carwash') {
      history.push(commonConstant.pathShellCarwashBooking, { 'orderId': this.props.transaction.orderId, 'staff': 1 })
    } else {
      history.push(commonConstant.pathShellLubeBooking, { 'orderId': this.props.transaction.orderId, 'staff': 1 })
    }
  };

  watchBtnSubmit = () => {
    const { service } = this.state;
    return { 'disabled': false, 'btnSubmit': 'yellow' };
  }

  handleRadios = (event) => this.setState({ 'radios': event.target.value })

  renderCancelModal = () => {
    const { language } = this.props;
    const { cancelModal, radios, service, etcNote } = this.state;
    const { status } = this.props.transaction;

    let type = '';
    if (this.props.location.state) {
      type = this.props.location.state.type;
    }

    const btnSubmit = this.watchBtnSubmit();
    if (cancelModal)
    {return (
      <div className="modal-page">
        <div className="modal-content">
          <p>{content[language]['modal-text1']}</p>
          <div className="body">
            <form>
              <label className="container-checkbox">
                <input
                  type="checkbox"
                  onChange={(event) => this.handleRadios(event)}
                  checked={radios === content[language]['modal-text2']}
                  value={content[language]['modal-text2']}
                />
                <span className={'checkmark yellow'}></span>
                {content[language]['modal-text2']}
              </label>
              <label className="container-checkbox">
                <input
                  type="checkbox"
                  onChange={(event) => this.handleRadios(event)}
                  checked={radios === content[language]['modal-text3']}
                  value={content[language]['modal-text3']}
                />
                <span className={'checkmark yellow'}></span>
                {content[language]['modal-text3']}
              </label>
              <label className="container-checkbox">
                <input
                  type="checkbox"
                  onChange={(event) => this.handleRadios(event)}
                  checked={radios === content[language]['modal-text4']}
                  value={content[language]['modal-text4']}
                />
                <span className={'checkmark yellow'}></span>
                {content[language]['modal-text4']}
                {radios === content[language]['modal-text4'] ?
                  <div className="box-input">
                    <input
                      type="text"
                      placeholder="กรุณาระบุเหตุผล"
                      onChange={(event) => this.setState({ 'etcNote': event.target.value })}
                    />
                  </div>
                  : null}
              </label>
              <br />
            </form>
          </div>
          <div className="footer">
            <div className="row">
              <button className="cancel" onClick={() => this.setState({ 'cancelModal': false })}>
                {content[language]['button-text4']}
              </button>
              <button
                onClick={() => this.cancelAndGoToDetail()}
                // onClick={() => { (status === 'Booking' && type !== 'yesterday') ? this.cancelAndGoToProcessing() : this.cancelAndGoToQueue(); }}
                className={btnSubmit.btnSubmit}
                disabled={btnSubmit.disabled}
              >
                {content[language]['button-text5']}
              </button>
            </div>
          </div>
        </div>
      </div>
    );}
  }

  renderConfirmModal = () => {
    const { confirmModal } = this.state;
    const { language } = this.props;
    if (confirmModal)
    {return (
      <div className="confirm-modal">
        <div className="modal-content">
          <p>{content[language]['content-text14']}</p>
          <div className="btn">
            <button className="cancel" onClick={() => this.setState({ 'confirmModal': false })}>{content[language]['button-text4']}</button>
            <button className="confirm" onClick={() => this.checkIn()}>{content[language]['button-text5']}</button>
          </div>
        </div>
      </div>
    );}
  }

  renderButton = () => {
    const { language } = this.props;
    const { status, note, staffRole, serviceDate } = this.props.transaction;
    const { service } = this.state;
    let actor = '';
    if (staffRole) {actor = content[language]['content-text10'];}
    else if (note === 'Auto Clear') {actor = content[language]['content-text12'];}
    else {actor = content[language]['content-text11'];}
    let type = '';
    if (this.props.location.state) {
      type = this.props.location.state.type;
    }
    const isToday = serviceDate.split('T')[0] === new Date().toISOString().split('T')[0];
    if (status === 'Booking' && isToday)
    {return (
      <React.Fragment>
        <div className="edit" onClick={() => this.editPage()}>
          {content[language]['button-text2']}
        </div>
        <div className="btn">
          <button className={'yellow'} onClick={() => this.setState({ 'confirmModal': true })}>{content[language]['button-text1']}</button>
          <button className="cancel" onClick={() => this.setState({ 'cancelModal': true })}>{content[language]['button-text3']}</button>
        </div>
      </React.Fragment>
    );}
    else if (status === 'Cancel')
    {return (
      <div className="content-cancel">
        <p className="cancel-case">{actor}</p>
        <p className="note">{content[language]['content-text13']} {note}</p>
        <div className="btn">
          <button className={'cancel'} onClick={() => this.goBack()}>{content[language]['button-text7']}</button>
        </div>
      </div>
    );}
    else if (status === 'Completed')
    {return (
      <div className="btn">
        <button className="cancel" onClick={() => this.goBack()}>{content[language]['button-text6']}</button>
      </div>
    );}
    else
    {return (
      <div className="content-cancel">
        <div className="edit" onClick={() => this.editPage()}>
          {content[language]['button-text2']}
        </div>
        <p className="error-text">รายการจองนี้ไม่ตรงกับวันและเวลา ที่เข้ารับบริการ</p>
        <div className="btn">
          <button className={'yellow'} onClick={() => this.setState({ 'confirmModal': true })}>{content[language]['button-text1']}</button>
          <button className="cancel" onClick={() => this.setState({ 'cancelModal': true })}>{content[language]['button-text3']}</button>
        </div>
      </div >
    );}
  }

  goBack = () => {
    if (this.props.location.state) {
      if (this.props.location.state.pushMsg) {liff.closeWindow();}
      else {history.goBack();}
    }
    else {history.goBack();}
  }

  // renderSummary = () => {
  //   const { language } = this.props;
  //   const { vehicleType, productType } = this.props.transaction;

  //   return (
  //     <div className="summary">
  //       <div className="content">
  //         <h1>{content[language]['content-text15']}</h1>
  //         <div className="vehicleType">
  //           <p className="title">
  //             {content[language]['content-text14']}
  //           </p>
  //           <p className="detail">
  //             {vehicleType}
  //           </p>
  //         </div>
  //         <div className="productType">
  //           <p className="title">
  //             {content[language]['content-text15']}
  //           </p>
  //           <ul>
  //             {productType.map(arr => (
  //               <div className="text">
  //                 <li className="name">
  //                   {arr.name}
  //                 </li>
  //                 <p className="note">
  //                   {arr.note}
  //                 </p>
  //               </div>
  //             ))}
  //           </ul>

  //         </div>
  //       </div>
  //     </div>

  //   )

  // }

  render() {
    const { language, transaction, staff } = this.props;
    const { service , loading} = this.state;

    if (!language || !transaction || !staff || !service || loading) {
      return <Loading />;
    }

    const { name, 'phone': oldPhone, 'serviceDate': oldServiceDate, 'serviceTime': BookedTime, branchName, licensePlate, brand, model, status, transactionDate, firstName, lastName, package : carwashPackage } = transaction;
    const date = new Date(transactionDate);
    const pickUpTime = `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
    const phone = [`0${oldPhone.substring(3)} `.slice(0, 3), '-', `0${oldPhone.substring(3)} `.slice(3)].join('');
    const isBookingOrCancel = status === 'Booking' || status === 'Cancel';
    const serviceDate = changeFormatDate3(isBookingOrCancel ? oldServiceDate : transactionDate);
    const hours = date.getHours();
    const serviceTime = isBookingOrCancel ? BookedTime : `${hours}:00 - ${hours + 1}:00`;

    return (
      <main className="staff-lube-queue-detail">
        {this.renderCancelModal()}
        {this.renderConfirmModal()}
        <div className={'header'}>
          <div className="logo">
            <img src={`/lineoa/img/staff-logo.svg`} alt="logo" />
          </div>
          <p className>{content[language]['content-text18']} {branchName}</p>
        </div>
        {this.renderNotification()}
        <div className="middle">
          <h1>{content[language]['header-text1']}</h1>
          <div className="content">
            <div className="details">
              <label>{content[language]['content-text19']}</label><span>{`${firstName} ${lastName}`}</span>
              <label>{content[language]['content-text2']}</label><a className="phone" href={`tel:${phone}`}>{phone}</a>
              <label>{content[language]['content-text3']}</label><span>{serviceDate}</span>
              <label>{content[language]['content-text4']}</label><span>{serviceTime}</span>
              <label>{content[language]['content-text5']}</label> <span>{branchName}</span>
            </div>
            <div className="details grey-background">
              <label>{content[language]['content-text6']}</label> <span>{licensePlate}</span>
              <label>{content[language]['content-text7']}</label> <span>{brand}</span>
              <label>{content[language]['content-text8']}</label> <span>{model}</span>
              {service === "Carwash" ? <label>{content[language]['content-text21']}</label> : null} {service === "Carwash" ? <span>{carwashPackage.toUpperCase()}</span> : null}
            </div>
            {status === 'Completed'
              ? <div className="details pickup-time">
                <label>{content[language]['content-text9']}</label><span>{pickUpTime}</span>
              </div>
              : null}
          </div>
        </div>
        {/* {status === 'Completed' ? this.renderSummary() : ""} */}
        {this.renderButton()}
      </main>
    );
  }
}

StaffLubeQueueDetail.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, transaction, staff, statusOrder, line }) => ({
  'language': language.data,
  'transaction': transaction.data,
  'staff': staff.data,
  'statusOrder': statusOrder,
  'line': line.data,
});

export default connect(mapStateToProps)(StaffLubeQueueDetail);
