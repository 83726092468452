export default {
  'th': {
    'date-header-1': 'วันที่ต้องการเข้ารับบริการ',
    'date-header-2': 'เวลาต้องการเข้ารับบริการ',
    'date-content-text1': 'สามารถทำการจองล่วงหน้าได้ในวันถัดไป แต่ไม่เกิน 7 วัน',
    'date-content-text2': 'เลือกวันที่',
    'car-header-1': 'กรุณากรอกข้อมูลรถที่จะเข้ารับบริการ',
    'car-content-text1': 'ทะเบียนรถ',
    'car-content-text2': 'ระบุทะเบียนรถ',
    'car-content-text3': 'ยี่ห้อรถ',
    'car-content-text4': 'เลือกยี่ห้อรถ',
    'car-content-text5': 'โปรดระบุยี่ห้อรถ',
    'car-content-text6': 'รุ่นรถ',
    'car-content-text7': 'ระบุรุ่นรถ',
    'user-header-1': 'กรุณากรอกข้อมูลผู้ใช้งาน',
    'user-content-text1': 'ชื่อ',
    'user-content-text2': 'ระบุชื่อ',
    'user-content-text3': 'เบอร์มือถือสำหรับการติดต่อ',
    'user-content-text4': 'โค้ดส่วนลด',
    'user-content-text5': 'โค้ดส่วนลด(ถ้ามี)',
    'user-content-text6': 'นามสกุล',
    'user-content-text7': 'ระบุนามสกุล',
    'button-text1': 'ถัดไป',
    'button-text2': 'ยืนยัน',
    'button-text3': 'ยกเลิก',
  },
  'en': {
    'date-header-1': 'วันที่ต้องการเข้ารับบริการ',
    'date-header-2': 'เวลาต้องการเข้ารับบริการ',
    'date-content-text1': 'สามารถทำการจองล่วงหน้าได้ในวันถัดไป แต่ไม่เกิน 7 วัน',
    'date-content-text2': 'เลือกวันที่',
    'car-header-1': 'กรุณากรอกข้อมูลรถที่จะเข้ารับบริการ',
    'car-content-text1': 'ทะเบียนรถ',
    'car-content-text2': 'ระบุทะเบียนรถ',
    'car-content-text3': 'ยี่ห้อรถ',
    'car-content-text4': 'เลือกยี่ห้อรถ',
    'car-content-text5': 'โปรดระบุยี่ห้อรถ',
    'car-content-text6': 'รุ่นรถ',
    'car-content-text7': 'ระบุรุ่นรถ',
    'user-header-1': 'กรุณากรอกข้อมูลผู้ใช้งาน',
    'user-content-text1': 'ชื่อ - นามสกุล',
    'user-content-text2': 'ระบุชื่อ - นามสกุล',
    'user-content-text3': 'เบอร์มือถือสำหรับการติดต่อ',
    'user-content-text4': 'โค้ดส่วนลด',
    'user-content-text5': 'โค้ดส่วนลด(ถ้ามี)',
    'button-text1': 'ถัดไป',
    'button-text2': 'ยืนยัน',
    'button-text3': 'ยกเลิก',
  },
};
