import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setLanguage } from '../../actions';
import { Loading } from '../../components';

import './StaffLubeSuccess.scss';

import content from './Language';

class StaffLubeSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'service': null,
      'CONTENT': '',
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { service, content } = this.props.location.state;
      if (service) this.setState({ 'service': service });
      if (content) this.setState({ 'CONTENT': content });
    }
  }

  render() {
    const { language } = this.props;
    const { service, CONTENT } = this.state;

    if (!language || !service) {
      return <Loading />;
    }

    return (
      <main className="staff-lube-success">
        <div className={service === 'Lube' ? 'header': 'header blue'}>
          <img src={process.env.REACT_APP_HOMEPAGE + `img/Logo.png`} alt="" />
          <p>{content[language]['header-text1']}</p>
        </div>
        <div className="content">
          <div className="success-center">
            <div className="success">
              <img src={process.env.REACT_APP_HOMEPAGE + `img/success.png`} alt="success"/>
              <p>{CONTENT}</p>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

StaffLubeSuccess.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language}) => ({
  'language': language.data
});

export default connect(mapStateToProps)(StaffLubeSuccess);
