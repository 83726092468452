import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import commonConstant from '../../common/commonConstant';

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { 'hasError': true };
  }

  constructor(props) {
    super(props);
    this.state = { 'hasError': false };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.log('error ===>',error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Redirect to={commonConstant.pathServerError} />;
    }

    return this.props.children; 
  }
}

ErrorBoundary.propTypes = {
  'children': PropTypes.any,
};

export default ErrorBoundary;

