import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import commonConstant from '../../common/commonConstant';
import { changeFormatDate2 } from '../../common/commonFunction';

import {
  setLanguage,
  fetchStaff,
  fetchOrderToday,
  fetchStation,
} from '../../actions';
import { Loading } from '../../components';

import './StaffLubeProcessing.scss';
import content from './Language';
import { history } from '../../helpers';

import moment from 'moment';
import 'moment/locale/th'

class StaffLubeProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'service': 'Lube',
      'PROCESSING': [],
      'COMPLETED': [],
      'TODAY': changeFormatDate2(new Date()),
      'position': null ,
      'loading' : false,
      'stationId': null
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.language) {
      return this.props.dispatch(setLanguage('th'));
    }
  }

  async componentDidMount() {
    if (this.props.line) {
      const { userId } = this.props.line;
      await this.props.dispatch(fetchStaff(userId));
      const { status, data } = this.props.staff;
      if (status === 200) {
        const { stationId, name } = data.position;
        const serviceName = name === 'Lube,Carwash' ? 'Lube' : name
        this.fetchDataByService(stationId, serviceName)
        this.setState({ 'position': name , 'stationId': stationId })
      }
    }
  }

  fetchDataByService = async (stationId,serviceName) => {
    await this.props.dispatch(fetchOrderToday(stationId, serviceName));
    await this.props.dispatch(fetchStation(stationId));
    const { processing, completed } = this.props.orderToday;
    this.setState({
      'service': serviceName,
      'PROCESSING': processing,
      'COMPLETED': completed,
      'loading': false
    });
  }

  nextPage = (orderId) => history.push(`${commonConstant.pathShellStaffLubeProcessingDetail}/${orderId}`);

  completedDetailPage = (orderId) => history.push(`${commonConstant.pathShellStaffLubeQueueDetail}/${orderId}`);

  renderProcessing = () => {
    const { language } = this.props;
    const { PROCESSING } = this.state;
    if (PROCESSING.length > 0)
    {return PROCESSING.map((el, i) => (
      <div className="processing" key={el.name}>
        <div className="no">{i + 1}</div>
        <div
          className="content"
          onClick={() => this.nextPage(el.orderId)}
          key={i}
        >
          <label className="left">{el.name}</label>
          <label className="right">{el.licensePlate}</label>
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>

    ));}
    else {return (
      <div className="content-none">
        <label>{content[language]['content-text3']}</label>
      </div>
    );}
  }

  renderCompleted = () => {
    const { language } = this.props;
    const { COMPLETED } = this.state;
    if (COMPLETED.length > 0)
    {return COMPLETED.map((el) => (
      <div className="done" key={el.name}>
        <div
          className="content"
          onClick={() => this.completedDetailPage(el.orderId)}
        >
          <label className="left">{el.name}</label>
          <label className="right">{el.licensePlate}</label>
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>

    ));}
    else {return (
      <div className="content-none">
        <label>{content[language]['content-text3']}</label>
      </div>
    );}
  }

  renderNotification = () => {
    try {
      const { notification } = this.props.location.state;
      return (
        <div className="notification">
          <img src={`${process.env.REACT_APP_HOMEPAGE}img/check.svg`} alt="check" />
          <p>{notification}</p>
        </div>
      );
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  onClickTabService = (value) => {
    this.setState({ 'service': value, 'loading': true })
    this.fetchDataByService(this.state.stationId,value)
  }


  render() {
    const { language, line, staff, orderToday, station } = this.props;
    const { service, loading, position } = this.state;

    if (!language || !line || !staff || !orderToday || !service || !station || !position ) {
      return <Loading />;
    }

    const canAddQueue = moment().isBefore(moment().hour(17)) && moment().isAfter(moment().hour(8))
    
    return (
      <main className="staff-lube-processing">
        <div className={'header'}>
          <div className="logo">
            <img src={`/lineoa/img/staff-logo.svg`} alt="" />
          </div>
          <p>{content[language]['content-text4']} {station.branchName}</p>
        </div>
        {this.renderNotification()}
        <div className="tab-container">
        <div className="service-tab" >
          {position === "Lube,Carwash" || position === "Lube" ? <div onClick={() => service === "Carwash" && this.onClickTabService('Lube')} className={service === "Lube" ? "wrap-service-active" : "wrap-service"}>
          <img className="service-icons" style={{ 'width' : '25px', 'color' : 'gray' }} src={service === "Lube" ?  `/lineoa/img/lube-active.svg`: `/lineoa/img/lube.svg`}  alt="service-icons-lube" />
          <p>{content[language]['content-text5']}</p>
          </div> : null}
         { position === "Lube,Carwash" || position === "Carwash" ? <div onClick={() => service === "Lube" && this.onClickTabService('Carwash')} className={service === "Carwash" ? "wrap-service-active" : "wrap-service"}>
          <img className="service-icons" style={{ 'width' : '25px', 'color' : 'gray' }} src={service === "Carwash" ?  `/lineoa/img/carwash-active.svg`: `/lineoa/img/carwash.svg`}  alt="service-icons-lube" />
          <p>{content[language]['content-text6']}</p>
          </div> : null}
        </div>
        </div>

       
       <div className="processing-container">
       

          { !loading ?
             <div>
            <div className="secondary-header">
            <p>{content[language]['content-text1']}</p>
          </div>
          <div>{this.renderProcessing()}</div>
          <div className={!canAddQueue ? "btn disabled" : "btn yellow"} onClick={() => canAddQueue && (service === 'Lube' ? history.push(`${commonConstant.pathShellLubeBooking}`, { 'staff': staff, 'walkIn': true, 'service': service }) : history.push(`${commonConstant.pathShellCarwashBooking}`, { 'staff': staff, 'walkIn': true, 'service': service }))}>
            <a>{content[language]['link-text1']}</a>
          </div>
          </div>
          : <Loading smallSize={true} />}
        </div>
      { !loading && <div className="completed-container">
          <div className="secondary-header">
            <p>{content[language]['content-text2']}</p>
          </div>
          <div>{this.renderCompleted()}</div>
        </div>}
      
        
      </main>
    );
  }
}

StaffLubeProcessing.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'language': PropTypes.string,
};

const mapStateToProps = ({ language, line, staff, orderToday, station }) => ({
  'language': language.data,
  'line': line.data,
  'staff': staff,
  'orderToday': orderToday.data,
  'station': station.data,
});

export default connect(mapStateToProps)(StaffLubeProcessing);
